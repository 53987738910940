import { createContext, useContext, useEffect, useState } from 'react'
import { supabase } from '../lib/supabase'
import { useAuth } from './AuthContext'
import { useToast } from '@/hooks/use-toast'

interface UserMetadata {
  selected_class?: string;
  active_skills?: string[];
}

interface UserMetadataContextType {
  metadata: UserMetadata | null;
  loading: boolean;
  user: any | null;
  updateSelectedClass: (className: string) => Promise<void>;
  updateUserMetadata: (metadata: Partial<UserMetadata>) => Promise<void>;
}

export const UserMetadataContext = createContext<UserMetadataContextType>({
  metadata: null,
  loading: true,
  user: null,
  updateSelectedClass: async () => {},
  updateUserMetadata: async () => {},
});

export const UserMetadataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [metadata, setMetadata] = useState<UserMetadata | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    if (!user) {
      setMetadata(null)
      setLoading(false)
      return
    }

    fetchMetadata()
  }, [user])

  const fetchMetadata = async () => {
    try {
      // Ensure we're using standard JSON format for response
      const { data, error } = await supabase
        .from('user_metadata')
        .select('*')
        .eq('user_id', user!.id)
        .maybeSingle() // Use maybeSingle instead of single to avoid errors when no data exists

      if (error) {
        throw error
      }

      // Eğer data varsa state'e set et, yoksa null bırak
      setMetadata(data || null)
    } catch (error) {
      console.error('Error fetching user metadata:', error)
      toast({
        title: 'Error',
        description: 'Failed to load user data',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  const updateSelectedClass = async (selectedClass: string) => {
    if (!user) {
      console.error('No user found');
      return;
    }

    if (!selectedClass) {
      console.error('No class selected');
      return;
    }

    try {
      console.log('Updating class for user:', user.id, 'to:', selectedClass);
      
      // Önce mevcut kaydı kontrol et
      const { data: existingData, error: fetchError } = await supabase
        .from('user_metadata')
        .select('*')
        .eq('user_id', user.id)
        .maybeSingle(); // Use maybeSingle instead of single

      console.log('Existing data:', existingData); // Debug log

      let result;
      
      if (!existingData) {
        // Kayıt yoksa yeni kayıt oluştur
        console.log('Creating new record with class:', selectedClass);
        const { data: insertData, error: insertError } = await supabase
          .from('user_metadata')
          .insert({
            user_id: user.id,
            selected_class: selectedClass
          })
          .select()
          .single();

        if (insertError) {
          console.error('Insert error:', insertError);
          throw insertError;
        }
        result = insertData;
        console.log('Created new metadata:', insertData);
      } else {
        // Mevcut kaydı güncelle
        console.log('Updating existing record with class:', selectedClass);
        const { data: updateData, error: updateError } = await supabase
          .from('user_metadata')
          .update({
            selected_class: selectedClass
          })
          .eq('user_id', user.id)
          .select()
          .single();

        if (updateError) {
          console.error('Update error:', updateError);
          throw updateError;
        }
        result = updateData;
        console.log('Updated metadata:', updateData);
      }

      if (!result) {
        throw new Error('No data returned from database');
      }

      setMetadata(result);
      console.log('Final metadata state:', result);

      toast({
        description: `Class changed to ${selectedClass}`,
      });
    } catch (error) {
      console.error('Error updating selected class:', error);
      toast({
        title: 'Error',
        description: 'Failed to save selected class. Database error occurred.',
        variant: 'destructive',
      });
      throw error;
    }
  };

  const updateUserMetadata = async (newMetadata: Partial<UserMetadata>) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('user_metadata')
        .update({
          ...newMetadata,
          updated_at: new Date().toISOString(),
        })
        .eq('user_id', user.id);

      if (error) throw error;

      // Refresh metadata after update
      await fetchMetadata();
    } catch (error) {
      console.error('Error updating user metadata:', error);
      throw error;
    }
  };

  const value = {
    metadata,
    loading,
    user,
    updateSelectedClass,
    updateUserMetadata,
  };

  return (
    <UserMetadataContext.Provider value={value}>
      {children}
    </UserMetadataContext.Provider>
  );
};

export function useUserMetadata() {
  const context = useContext(UserMetadataContext)
  if (context === undefined) {
    throw new Error('useUserMetadata must be used within a UserMetadataProvider')
  }
  return context
} 