import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { supabase } from '@/lib/supabase';

interface PingContextType {
  pingTime: number | null;
  previousPing: number;
  isRefreshing: boolean;
  checkPing: () => Promise<void>;
  lastUpdate: Date;
  metricsData: Array<{ timestamp: string; responseTime: number }>;
}

const PingContext = createContext<PingContextType | undefined>(undefined);

export const PingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [pingTime, setPingTime] = useState<number | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [metricsData, setMetricsData] = useState<Array<{ timestamp: string; responseTime: number }>>([]);
  const previousPingRef = useRef<number>(0);

  const checkPing = async () => {
    setIsRefreshing(true);
    try {
      const startTime = performance.now();
      await supabase.from('system_stats').select('count').limit(1);
      const endTime = performance.now();
      const newPing = Math.round(endTime - startTime);
      
      if (pingTime !== null) {
        previousPingRef.current = pingTime;
      }
      setPingTime(newPing);
      
      const now = new Date();
      setLastUpdate(now);
      
      setMetricsData(prevData => {
        const newData = [...prevData, {
          timestamp: now.toLocaleTimeString(),
          responseTime: newPing
        }];
        return newData.slice(-30);
      });
    } catch (error) {
      console.error('Failed to check ping:', error);
      setPingTime(null);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    checkPing();
    const interval = setInterval(checkPing, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <PingContext.Provider value={{
      pingTime,
      previousPing: previousPingRef.current,
      isRefreshing,
      checkPing,
      lastUpdate,
      metricsData
    }}>
      {children}
    </PingContext.Provider>
  );
};

export const usePing = () => {
  const context = useContext(PingContext);
  if (context === undefined) {
    throw new Error('usePing must be used within a PingProvider');
  }
  return context;
}; 