import { supabase } from '@/lib/supabase';

interface SystemStats {
  last_start_time: string;
  total_uptime: number;
  is_online: boolean;
  last_heartbeat: string;
}

export const systemStatsService = {
  async getStats(): Promise<SystemStats | null> {
    try {
      const { data, error } = await supabase
        .from('system_stats')
        .select('*')
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Failed to fetch system stats:', error);
      return null;
    }
  },

  calculateUptime(lastHeartbeat: string): number {
    const lastBeat = new Date(lastHeartbeat);
    const now = new Date();
    const diffMinutes = Math.abs(now.getTime() - lastBeat.getTime()) / 1000 / 60;
    
    // If last heartbeat is more than 5 minutes ago, system is considered offline
    return diffMinutes <= 5 ? 100 : 0;
  },

  formatRuntime(startTime: string, totalUptime: number): string {
    const start = new Date(startTime);
    const now = new Date();
    const diffSeconds = Math.floor((now.getTime() - start.getTime()) / 1000) + (totalUptime || 0);
    
    const hours = Math.floor(diffSeconds / 3600);
    const minutes = Math.floor((diffSeconds % 3600) / 60);
    const seconds = diffSeconds % 60;
    
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
}; 