"use client"

import type React from "react"
import { useState, useEffect, useCallback } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Slider } from "@/components/ui/slider"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Switch } from "@/components/ui/switch"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Heart, HelpCircle, RotateCcw, Zap, Settings2 } from "lucide-react"
import { toast } from "sonner"
import {
  getPotionSettings,
  savePotionSettings,
  resetPotionSettings,
  type PotionSettingsData,
  defaultPotionSettings,
} from "@/lib/services/potion-settings"
import { useAuth } from "@/contexts/AuthContext"
import { Skeleton } from "@/components/ui/skeleton"

export default function PotionSettings() {
  const { user } = useAuth()
  const [activeTab, setActiveTab] = useState("hp")
  const [settings, setSettings] = useState<PotionSettingsData>(defaultPotionSettings)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  // Memoize tab change to reduce rerenders
  const handleTabChange = useCallback((value: string) => {
    setActiveTab(value)
  }, [])

  // Load existing settings from the database
  useEffect(() => {
    let isMounted = true
    const loadSettings = async () => {
      try {
        setSettings(defaultPotionSettings)

        if (user) {
          const data = await getPotionSettings()
          if (isMounted) {
            setSettings(data)
            setIsLoading(false)
          }
        } else {
          if (isMounted) {
            setIsLoading(false)
          }
        }
      } catch (error) {
        console.error("Error loading potion settings:", error)
        toast.error("Failed to load potion settings. Using default values.")
        setIsLoading(false)
      }
    }

    loadSettings()

    const timeout = setTimeout(() => {
      if (isMounted) {
        setIsLoading(false)
      }
    }, 1000)

    return () => {
      isMounted = false
      clearTimeout(timeout)
    }
  }, [user])

  // Handle saving settings
  const handleSave = async () => {
    if (!user) {
      toast.error("You must be logged in to save settings.")
      return
    }

    setIsSaving(true)
    console.log("Attempting to save settings:", settings)
    try {
      const result = await savePotionSettings(settings)
      console.log("Save result:", result)
      
      // Verify the save by immediately retrieving the settings
      try {
        const verifyData = await getPotionSettings()
        console.log("Verification data:", verifyData)
        
        // Better comparison that only checks relevant settings fields
        const settingsMatch = Object.keys(defaultPotionSettings).every(key => {
          const k = key as keyof PotionSettingsData;
          return verifyData[k] === settings[k];
        });
        
        if (!settingsMatch) {
          console.warn("Settings verification failed - some values don't match what was sent");
          // Log the differences for debugging
          Object.keys(defaultPotionSettings).forEach(key => {
            const k = key as keyof PotionSettingsData;
            if (verifyData[k] !== settings[k]) {
              console.log(`Field ${key} mismatch: sent=${settings[k]}, received=${verifyData[k]}`);
            }
          });
        } else {
          console.log("Settings verified successfully!");
        }
      } catch (verifyError) {
        console.error("Error verifying saved settings:", verifyError)
      }
      
      toast.success("Potion settings saved successfully.")
    } catch (error) {
      console.error("Error saving potion settings:", error)
      // Show more detailed error information
      toast.error(error instanceof Error ? `Error Saving Settings: ${error.message}` : "Unknown error occurred saving settings")
    } finally {
      setIsSaving(false)
    }
  }

  // Handle resetting settings
  const handleReset = async () => {
    try {
      const defaultValues = await resetPotionSettings()
      setSettings(defaultValues)
      toast.success("Potion settings reset to defaults.")
    } catch (error) {
      console.error("Error resetting potion settings:", error)
      toast.error("Failed to reset potion settings.")
    }
  }

  // Update a single setting
  const updateSetting = (key: keyof PotionSettingsData, value: number | boolean) => {
    setSettings((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  // Render a slider input with the same styling as KeyboardShortcuts
  const renderSlider = (name: keyof PotionSettingsData, label: string, icon: React.ReactNode) => {
    const value = typeof settings[name] === "number" ? (settings[name] as number) : 0

    return (
      <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
        <Label htmlFor={name} className="flex items-center gap-1.5 text-sm font-medium mb-3">
          {icon}
          {label}
        </Label>
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <Slider
              id={name}
              min={0}
              max={100}
              step={1}
              value={[value]}
              onValueChange={([newValue]) => updateSetting(name, newValue)}
              disabled={isLoading || isSaving}
              className="flex-1"
            />
          </div>
          <span className="w-12 text-right font-mono text-sm">{value}%</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  onClick={() => updateSetting(name, defaultPotionSettings[name])}
                  disabled={isLoading || isSaving}
                  className="hover:bg-gaming/5 hover:text-gaming"
                >
                  <RotateCcw className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>Reset to default</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    )
  }

  // Render a switch input with the same styling as KeyboardShortcuts
  const renderSwitch = (name: keyof PotionSettingsData, label: string, icon: React.ReactNode) => {
    const value = typeof settings[name] === "boolean" ? (settings[name] as boolean) : false

    return (
      <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
        <Label htmlFor={name} className="flex items-center gap-1.5 text-sm font-medium mb-3">
          {icon}
          {label}
        </Label>
        <div className="flex items-center gap-2">
          <Switch
            id={name}
            checked={value}
            onCheckedChange={(checked) => updateSetting(name, checked)}
            disabled={isLoading || isSaving}
            className="data-[state=checked]:bg-gaming data-[state=unchecked]:bg-input"
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  onClick={() => updateSetting(name, defaultPotionSettings[name])}
                  disabled={isLoading || isSaving}
                  className="hover:bg-gaming/5 hover:text-gaming"
                >
                  <RotateCcw className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>Reset to default</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <Card className="glass-card border-gaming/20 overflow-hidden">
        <div className="bg-gaming/10 border-b border-gaming/20 px-6 py-3.5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="h-12 w-12 rounded-xl flex items-center justify-center bg-gaming/20 text-gaming">
                <Settings2 className="h-6 w-6" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gaming">Potion Settings & Thresholds</h2>
                <p className="text-sm mt-0.5 text-muted-foreground">
                  Configure potion usage thresholds and automation preferences
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                onClick={handleReset}
                disabled={isLoading || isSaving}
                className="gap-1 border-gaming/30 hover:bg-gaming/10 hover:text-gaming"
              >
                <RotateCcw className="h-4 w-4" />
                Reset to Defaults
              </Button>
              <Button onClick={handleSave} className="bg-gaming hover:bg-gaming/90" disabled={isLoading || isSaving}>
                {isSaving ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Saving...
                  </>
                ) : (
                  <>Save Settings</>
                )}
              </Button>
            </div>
          </div>
        </div>
        <CardContent className="p-6">
          {isLoading ? (
            <div className="flex gap-6 h-[950px]">
              {/* Sidebar Skeleton */}
              <div className="w-60 shrink-0 border-r border-border/50 pr-4">
                <Skeleton className="h-5 w-40 mb-5" />
                <div className="space-y-3">
                  <Skeleton className="h-14 w-full rounded-lg" />
                  <Skeleton className="h-14 w-full rounded-lg" />
                  <Skeleton className="h-14 w-full rounded-lg" />
                </div>
              </div>

              {/* Content Skeleton */}
              <div className="flex-1">
                <div className="space-y-6">
                  <div className="flex items-center gap-3 mb-6">
                    <Skeleton className="h-10 w-10 rounded-full" />
                    <div className="space-y-2">
                      <Skeleton className="h-6 w-48" />
                      <Skeleton className="h-4 w-60" />
                    </div>
                  </div>

                  <Skeleton className="h-16 w-full rounded-lg mb-8" />

                  <div className="grid grid-cols-1 gap-8">
                    <Skeleton className="h-28 w-full rounded-xl" />
                    <Skeleton className="h-28 w-full rounded-xl" />
                    <Skeleton className="h-28 w-full rounded-xl" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-6 h-[950px]">
              {/* Sidebar */}
              <div className="w-60 shrink-0 border-r border-border/50 pr-4">
                <div className="text-sm font-medium text-muted-foreground mb-4">Settings Categories</div>
                <div className="flex flex-col gap-2">
                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === "hp"
                        ? "bg-gaming text-white shadow-md"
                        : "hover:bg-gaming/10 text-foreground hover:text-gaming"
                    }`}
                    onClick={() => handleTabChange("hp")}
                  >
                    <div
                      className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                        activeTab === "hp" ? "bg-white/20" : "bg-gaming/10 group-hover:bg-gaming/20"
                      }`}
                    >
                      <Heart className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">HP Settings</div>
                      <div className={`text-xs ${activeTab === "hp" ? "text-white/70" : "text-muted-foreground"}`}>
                        HP potion and minor settings
                      </div>
                    </div>
                  </button>

                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === "mp"
                        ? "bg-gaming text-white shadow-md"
                        : "hover:bg-gaming/10 text-foreground hover:text-gaming"
                    }`}
                    onClick={() => handleTabChange("mp")}
                  >
                    <div
                      className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                        activeTab === "mp" ? "bg-white/20" : "bg-gaming/10 group-hover:bg-gaming/20"
                      }`}
                    >
                      <Zap className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">MP Settings</div>
                      <div className={`text-xs ${activeTab === "mp" ? "text-white/70" : "text-muted-foreground"}`}>
                        MP potion thresholds
                      </div>
                    </div>
                  </button>

                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === "automation"
                        ? "bg-gaming text-white shadow-md"
                        : "hover:bg-gaming/10 text-foreground hover:text-gaming"
                    }`}
                    onClick={() => handleTabChange("automation")}
                  >
                    <div
                      className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                        activeTab === "automation" ? "bg-white/20" : "bg-gaming/10 group-hover:bg-gaming/20"
                      }`}
                    >
                      <Settings2 className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">Automation</div>
                      <div
                        className={`text-xs ${activeTab === "automation" ? "text-white/70" : "text-muted-foreground"}`}
                      >
                        Auto-use settings
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              {/* Content Area */}
              <div className="flex-1">
                <ScrollArea className="h-[950px] pr-4">
                  <div className="pb-8">
                    {activeTab === "hp" && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming">
                            <Heart className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">HP Settings</h3>
                            <p className="text-sm text-muted-foreground">Configure HP-related thresholds</p>
                          </div>
                        </div>

                        <Alert className="bg-red-100 border-red-300 dark:bg-red-900/40 dark:border-red-700">
                          <HelpCircle className="h-4 w-4 !text-red-800 dark:!text-red-200" />
                          <AlertDescription className="text-sm font-semibold text-red-800 dark:text-red-200">
                            Configure HP potion thresholds and minor activation settings.
                          </AlertDescription>
                        </Alert>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {renderSlider(
                            "hp_potion_threshold",
                            "HP Potion Threshold",
                            <Heart className="h-4 w-4 text-red-500" />,
                          )}
                          {renderSlider(
                            "critical_hp_threshold",
                            "Critical HP Threshold",
                            <Heart className="h-4 w-4 text-red-500" />,
                          )}
                          {renderSlider(
                            "minor_activate_hp",
                            "Minor Activate HP",
                            <Heart className="h-4 w-4 text-green-500" />,
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === "mp" && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming">
                            <Zap className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">MP Settings</h3>
                            <p className="text-sm text-muted-foreground">Configure MP-related thresholds</p>
                          </div>
                        </div>

                        <Alert className="bg-blue-100 border-blue-300 dark:bg-blue-900/40 dark:border-blue-700">
                          <HelpCircle className="h-4 w-4 !text-blue-800 dark:!text-blue-200" />
                          <AlertDescription className="text-sm font-semibold text-blue-800 dark:text-blue-200">
                            Configure MP potion thresholds and minor deactivation settings.
                          </AlertDescription>
                        </Alert>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {renderSlider(
                            "mp_potion_threshold",
                            "MP Potion Threshold",
                            <Zap className="h-4 w-4 text-blue-500" />,
                          )}
                          {renderSlider(
                            "critical_mp_threshold",
                            "Critical MP Threshold",
                            <Zap className="h-4 w-4 text-blue-500" />,
                          )}
                          {renderSlider(
                            "minor_deactivate_mp",
                            "Minor Deactivate MP",
                            <Zap className="h-4 w-4 text-yellow-500" />,
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === "automation" && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming">
                            <Settings2 className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">Automation Settings</h3>
                            <p className="text-sm text-muted-foreground">Configure automatic potion usage</p>
                          </div>
                        </div>

                        <Alert className="bg-purple-100 border-purple-300 dark:bg-purple-900/40 dark:border-purple-700">
                          <HelpCircle className="h-4 w-4 !text-purple-800 dark:!text-purple-200" />
                          <AlertDescription className="text-sm font-semibold text-purple-800 dark:text-purple-200">
                            Enable or disable automatic potion usage features.
                          </AlertDescription>
                        </Alert>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {renderSwitch(
                            "auto_minor_low_hp",
                            "Auto Minor on Low HP",
                            <Heart className="h-4 w-4 text-green-500" />,
                          )}
                          {renderSwitch("auto_hp_potion", "Auto HP Potion", <Heart className="h-4 w-4 text-red-500" />)}
                          {renderSwitch("auto_mp_potion", "Auto MP Potion", <Zap className="h-4 w-4 text-blue-500" />)}
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollArea>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

