import React from 'react';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Ability } from '@/data/abilities/types';
import { Sword, Search, Star, Gift } from 'lucide-react';

interface AbilityCardProps {
  name: string;
  description: string;
  isActive: boolean;
  onToggle: () => void;
  type?: 'combat' | 'search' | 'master' | 'extra';
}

export function AbilityCard({ name, description, isActive, onToggle, type }: AbilityCardProps) {
  const getTypeIcon = () => {
    switch (type) {
      case 'combat':
        return <Sword className="h-4 w-4" />;
      case 'search':
        return <Search className="h-4 w-4" />;
      case 'master':
        return <Star className="h-4 w-4" />;
      case 'extra':
        return <Gift className="h-4 w-4" />;
      default:
        return null;
    }
  };

  return (
    <button
      onClick={onToggle}
      className={cn(
        "relative w-full text-left p-4 rounded-lg transition-all duration-200",
        "bg-black/20 backdrop-blur-sm border",
        "hover:bg-gaming/5 hover:border-gaming/30",
        isActive
          ? "border-gaming text-white shadow-[0_0_15px_rgba(0,128,255,0.1)]"
          : "border-white/5 text-white/70 hover:text-white"
      )}
    >
      <div className="space-y-1.5">
        <div className="flex items-center gap-2">
          {getTypeIcon()}
          <h3 className={cn(
            "text-sm font-medium transition-colors duration-200",
            isActive ? "text-gaming" : "text-white/90"
          )}>
            {name}
          </h3>
        </div>
        <p className="text-xs leading-relaxed opacity-70">
          {description}
        </p>
      </div>
    </button>
  );
}

export default AbilityCard; 