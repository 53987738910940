import { Ability } from '@/data/abilities/types'

export interface SkillSlot {
  id: string;
  key: string; // "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0"
  ability: Ability | null;
}

export interface SkillBar {
  id: string;
  slots: SkillSlot[];
}

export interface UserSkillBars {
  bars: SkillBar[];
}

// Skill bar configuration
export const SKILL_BAR_COUNT = 6;
export const SKILL_SLOTS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

// Helper to create an empty skill slot
export const createEmptySlot = (key: string): SkillSlot => ({
  id: crypto.randomUUID(),
  key,
  ability: null,
});

// Helper to create an empty skill bar
export const createEmptyBar = (): SkillBar => ({
  id: crypto.randomUUID(),
  slots: SKILL_SLOTS.map(key => createEmptySlot(key)),
});

// Helper to create empty skill bars for a user
export const createEmptySkillBars = (): UserSkillBars => ({
  bars: Array(SKILL_BAR_COUNT).fill(null).map(() => createEmptyBar()),
}); 