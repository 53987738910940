import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Sword, Shield, Wand2, Target, Footprints, Zap, Eye, Save, Swords, Search, Star, Gift } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { useUserMetadata } from '@/contexts/UserMetadataContext';
import { cn } from '@/lib/utils';
import AbilityCard from '@/components/game/AbilityCard';
import { 
  assassinClassAbilities, 
  assassinSearchAbilities, 
  assassinMasterAbilities, 
  assassinExtraAbilities 
} from '@/data/abilities/assassin';
import { supabase } from '@/lib/supabase';

const classes = [
  {
    id: 'assassin',
    name: 'Assassin',
    icon: Footprints,
    color: 'text-gaming-assassin',
    description: 'Masters of stealth and precision who excel in dealing critical damage from the shadows.',
    strengths: [
      'High critical strike chance',
      'Superior mobility',
      'Stealth capabilities',
      'Burst damage potential'
    ],
    weaknesses: [
      'Low health pool',
      'Vulnerable to area attacks',
      'Limited defensive options',
      'Challenging skill ceiling'
    ],
    playstyle: [
      'Strike-and-retreat tactics',
      'Opportunistic damage',
      'Combo-based rotations',
      'Resource management'
    ]
  },
  { 
    id: 'warrior',
    name: 'Warrior',
    icon: Sword,
    color: 'text-red-500',
    description: 'Masters of melee combat who excel in close-quarters battle and can take heavy damage.',
  },
  {
    id: 'mage',
    name: 'Mage',
    icon: Wand2,
    color: 'text-blue-500',
    description: 'Wielders of arcane magic who can devastate enemies from afar with powerful spells.',
  },
  {
    id: 'ranger',
    name: 'Ranger',
    icon: Target,
    color: 'text-green-500',
    description: 'Expert marksmen and trackers who excel in ranged combat and survival skills.',
  },
  {
    id: 'paladin',
    name: 'Paladin',
    icon: Shield,
    color: 'text-yellow-500',
    description: 'Holy warriors who combine martial prowess with divine magic to protect and heal allies.',
  }
];

const ClassesPage: React.FC = () => {
  const { toast } = useToast();
  const { metadata, loading, updateSelectedClass, updateUserMetadata, user } = useUserMetadata();
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [activeSkills, setActiveSkills] = useState<string[]>([]);
  
  // Effect to update selectedClass and activeSkills when metadata changes
  useEffect(() => {
    if (metadata?.selected_class) {
      setSelectedClass(metadata.selected_class);
    } else {
      setSelectedClass(null);
    }
    
    if (metadata?.active_skills) {
      setActiveSkills(metadata.active_skills);
    } else {
      setActiveSkills([]);
    }
  }, [metadata]);

  const handleToggleSkill = async (skillName: string) => {
    try {
      // Sadece state'i güncelle, metadata'yı değil
      setActiveSkills(prev => 
        prev.includes(skillName)
          ? prev.filter(skill => skill !== skillName)
          : [...prev, skillName]
      );
    } catch (error) {
      console.error('Error updating skill:', error);
      toast({
        title: 'Error',
        description: 'Failed to update skill configuration',
        variant: 'destructive',
      });
    }
  };

  // Notification gönderme yardımcı fonksiyonu
  const sendNotification = async (userId: string, notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: userId,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Realtime kanalına manuel olarak bildirim gönder
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Sunucuya gönder
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: userId,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSave = async () => {
    if (!selectedClass) {
      toast({
        title: "No Class Selected",
        description: "Please select a class before saving.",
        variant: "destructive"
      });
      return;
    }

    try {
      if (selectedClass !== 'assassin') {
        toast({
          title: "Invalid Class",
          description: "Only Assassin class is available at this time.",
          variant: "destructive"
        });
        return;
      }

      // Önceki aktif yetenekleri al
      const previousActiveSkills = metadata?.active_skills || [];
      
      // Önce mevcut metadata'yı al
      const { data: currentData, error: fetchError } = await supabase
        .from('skill_metadata')
        .select('metadata')
        .eq('user_id', user!.id)
        .maybeSingle();

      if (fetchError) throw fetchError;

      // Yeni metadata'yı hazırla
      let newMetadata = [];
      if (currentData?.metadata) {
        // Deaktif edilen skill'leri çıkar
        newMetadata = (currentData.metadata as any[])
          .filter(meta => activeSkills.includes(meta.skillName))
          .map((meta, index) => ({
            ...meta,
            orderIndex: index + 1
          }));
      }

      // Yeni aktif edilen skill'leri ekle
      const newSkills = activeSkills.filter(skill => 
        !currentData?.metadata?.some((meta: any) => meta.skillName === skill)
      );

      newSkills.forEach((skill, index) => {
        newMetadata.push({
          skillName: skill,
          slotKey: `Slot${newMetadata.length + 1}`,
          barIndex: Math.floor(newMetadata.length / 4) + 1,
          orderIndex: newMetadata.length + 1
        });
      });

      // Metadata'yı güncelle
      if (!currentData) {
        // Kayıt yoksa yeni oluştur
        await supabase
          .from('skill_metadata')
          .insert({ 
            user_id: user!.id, 
            metadata: newMetadata || [] 
          });
      } else {
        // Kayıt varsa güncelle
        await supabase
          .from('skill_metadata')
          .update({ metadata: newMetadata || [] })
          .eq('user_id', user!.id);
      }

      await updateSelectedClass(selectedClass);
      await updateUserMetadata({
        active_skills: activeSkills
      });

      // Yetenek değişikliklerini kontrol et
      const addedSkills = activeSkills.filter(skill => !previousActiveSkills.includes(skill));
      const removedSkills = previousActiveSkills.filter(skill => !activeSkills.includes(skill));

      // Bildirimler...
      if (addedSkills.length > 0) {
        await sendNotification(user!.id, {
          title: '🎯 New Skills Activated',
          message: `You've activated ${addedSkills.length} new skill${addedSkills.length > 1 ? 's' : ''}: ${addedSkills.join(', ')}`,
          type: 'success'
        });
      }

      if (removedSkills.length > 0) {
        await sendNotification(user!.id, {
          title: '⚔️ Skills Deactivated',
          message: `You've deactivated ${removedSkills.length} skill${removedSkills.length > 1 ? 's' : ''}: ${removedSkills.join(', ')}`,
          type: 'info'
        });
      }

      if (selectedClass !== metadata?.selected_class) {
        await sendNotification(user!.id, {
          title: '🔄 Class Changed',
          message: `Your class has been changed to ${selectedClass}. Your skills have been updated.`,
          type: 'info'
        });
      }
      
      toast({
        title: "Success",
        description: `Your class and active skills have been saved.`,
      });
    } catch (error) {
      console.error('Error in handleSave:', error);
      toast({
        title: "Error",
        description: "Failed to save your settings. Please try again.",
        variant: "destructive"
      });
    }
  };

  if (loading) {
    return (
      <div className="space-y-6 animate-pulse">
        <div className="h-8 w-64 bg-primary/20 rounded"></div>
        <div className="h-96 glass-card rounded-lg"></div>
      </div>
    );
  }

  const currentClass = classes.find(c => c.id === (metadata?.selected_class || selectedClass));
  
  if (!currentClass) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-foreground">Character Classes</h1>
          <div className="flex items-center gap-2">
            <Button 
              variant="outline" 
              className="hover-glow"
              onClick={() => {
                toast({
                  title: "Tips & Tricks",
                  description: "Choose a class based on your playstyle. Assassins excel at burst damage and mobility.",
                });
              }}
            >
              <Eye className="h-4 w-4 mr-2" />
              Tips
            </Button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {classes.map((classItem) => {
            const Icon = classItem.icon;
            const isSelected = (metadata?.selected_class || selectedClass) === classItem.id;
            const isDisabled = classItem.id !== 'assassin';

            return (
              <Card
                key={classItem.id}
                className={cn(
                  'relative p-6 cursor-pointer transition-all duration-300 hover:scale-[1.02] glass-card border-border/40',
                  isSelected && 'ring-2 ring-gaming-assassin ring-offset-2 ring-offset-background',
                  isDisabled && 'opacity-50'
                )}
                onClick={() => !isDisabled && setSelectedClass(classItem.id)}
              >
                <div className="flex items-start gap-4">
                  <div className={cn(
                    'p-3 rounded-lg',
                    isSelected ? 'bg-gaming-assassin/20 text-gaming-assassin' : 'bg-primary/20 text-primary'
                  )}>
                    {Icon && <Icon className="w-6 h-6" />}
                  </div>
                  <div>
                    <h3 className="font-semibold text-foreground">{classItem.name}</h3>
                    <p className="text-sm text-muted-foreground mt-1">{classItem.description}</p>
                  </div>
                </div>

                {isDisabled && (
                  <div className="absolute inset-0 flex items-center justify-center bg-background/80 rounded-lg backdrop-blur-sm">
                    <p className="text-sm font-medium text-muted-foreground">Coming Soon</p>
                  </div>
                )}

                {isSelected && (
                  <div className="absolute top-2 right-2">
                    <div className="h-2 w-2 rounded-full bg-gaming-assassin animate-pulse"></div>
                  </div>
                )}
              </Card>
            );
          })}
        </div>
        
        <div className="text-center mt-8">
          <p className="text-muted-foreground">Please select the Assassin class to begin your journey.</p>
          {selectedClass && (
            <Button
              className="bg-gaming-assassin hover:bg-gaming-assassin/90 mt-4"
              onClick={handleSave}
            >
              <Save className="h-4 w-4 mr-2" />
              Save Class
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-foreground">Character Classes</h1>
        <div className="flex items-center gap-2">
          <Button 
            variant="outline" 
            className="hover-glow"
            onClick={() => {
              toast({
                title: "Tips & Tricks",
                description: "Choose a class based on your playstyle. Assassins excel at burst damage and mobility.",
              });
            }}
          >
            <Eye className="h-4 w-4 mr-2" />
            Tips
          </Button>
          <Button
            className="bg-gaming-assassin hover:bg-gaming-assassin/90"
            onClick={handleSave}
          >
            <Save className="h-4 w-4 mr-2" />
            Save Class
          </Button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {classes.map((classItem) => {
          const Icon = classItem.icon;
          const isSelected = (metadata?.selected_class || selectedClass) === classItem.id;
          const isDisabled = classItem.id !== 'assassin';

          return (
            <Card
              key={classItem.id}
              className={cn(
                'relative p-6 cursor-pointer transition-all duration-300 hover:scale-[1.02] glass-card border-border/40',
                isSelected && 'ring-2 ring-gaming-assassin ring-offset-2 ring-offset-background',
                isDisabled && 'opacity-50'
              )}
              onClick={() => !isDisabled && setSelectedClass(classItem.id)}
            >
              <div className="flex items-start gap-4">
                <div className={cn(
                  'p-3 rounded-lg',
                  isSelected ? 'bg-gaming-assassin/20 text-gaming-assassin' : 'bg-primary/20 text-primary'
                )}>
                  {Icon && <Icon className="w-6 h-6" />}
                </div>
                <div>
                  <h3 className="font-semibold text-foreground">{classItem.name}</h3>
                  <p className="text-sm text-muted-foreground mt-1">{classItem.description}</p>
                </div>
              </div>

              {isDisabled && (
                <div className="absolute inset-0 flex items-center justify-center bg-background/80 rounded-lg backdrop-blur-sm">
                  <p className="text-sm font-medium text-muted-foreground">Coming Soon</p>
                </div>
              )}

              {isSelected && (
                <div className="absolute top-2 right-2">
                  <div className="h-2 w-2 rounded-full bg-gaming-assassin animate-pulse"></div>
                </div>
              )}
            </Card>
          );
        })}
      </div>
      
      <Tabs defaultValue="overview" className="w-full mt-8">
        <TabsList className="glass-card mb-4">
          <TabsTrigger value="overview" className="data-[state=active]:bg-gaming-assassin/20">
            <Eye className="h-4 w-4 mr-2" />
            Overview
          </TabsTrigger>
          <TabsTrigger value="abilities" className="data-[state=active]:bg-gaming-assassin/20">
            <Zap className="h-4 w-4 mr-2" />
            Abilities
          </TabsTrigger>
          <TabsTrigger value="stats" className="data-[state=active]:bg-gaming-assassin/20">
            <Shield className="h-4 w-4 mr-2" />
            Stats
          </TabsTrigger>
          <TabsTrigger value="equipment" className="data-[state=active]:bg-gaming-assassin/20">
            <Sword className="h-4 w-4 mr-2" />
            Equipment
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="overview" className="slide-up">
          <Card className="glass-card">
            <CardHeader>
              <div className="flex items-center justify-between">
                <CardTitle className="text-lg flex items-center">
                  {currentClass.name}
                  {metadata?.selected_class === currentClass.id && (
                    <Badge className="ml-2 bg-gaming-assassin">Active</Badge>
                  )}
                </CardTitle>
                {currentClass.icon && <currentClass.icon className={cn("h-5 w-5", currentClass.color)} />}
              </div>
              <CardDescription>
                {currentClass.description}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="glass p-4 rounded-lg">
                  <h3 className="text-sm font-medium mb-2">Class Description</h3>
                  <p className="text-sm text-muted-foreground">
                    {currentClass.description} {currentClass.id === 'assassin' ? 
                    'This class represents the perfect balance of speed and lethality, making them excellent damage dealers.' : 
                    'This class is not yet available.'}
                  </p>
                </div>
                
                {currentClass.id === 'assassin' && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="glass p-4 rounded-lg">
                      <h3 className="text-sm font-medium mb-2">Strengths</h3>
                      <ul className="text-sm text-muted-foreground space-y-1">
                        {currentClass.strengths.map((strength, i) => (
                          <li key={i}>• {strength}</li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className="glass p-4 rounded-lg">
                      <h3 className="text-sm font-medium mb-2">Weaknesses</h3>
                      <ul className="text-sm text-muted-foreground space-y-1">
                        {currentClass.weaknesses.map((weakness, i) => (
                          <li key={i}>• {weakness}</li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className="glass p-4 rounded-lg">
                      <h3 className="text-sm font-medium mb-2">Playstyle</h3>
                      <ul className="text-sm text-muted-foreground space-y-1">
                        {currentClass.playstyle.map((style, i) => (
                          <li key={i}>• {style}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="abilities">
          <Card className="glass-card p-6">
            <h3 className="text-lg font-semibold mb-4">{currentClass.name} Abilities</h3>
            {currentClass.id === 'assassin' ? (
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                {/* Class Abilities */}
                <div className="space-y-3">
                  <h4 className="text-base font-medium mb-2 text-white flex items-center gap-2">
                    <Swords className="h-4 w-4 text-gaming-assassin" />
                    Class Abilities
                  </h4>
                  <div className="grid grid-cols-1 gap-4 h-[600px] overflow-y-auto content-start pr-4 pl-2 pt-2 scrollbar-thin scrollbar-thumb-gaming-assassin scrollbar-track-gaming-assassin/10">
                    {assassinClassAbilities.map((ability) => (
                      <AbilityCard
                        key={ability.name}
                        name={ability.name}
                        description={ability.description}
                        isActive={activeSkills.includes(ability.name)}
                        onToggle={() => handleToggleSkill(ability.name)}
                        type="combat"
                      />
                    ))}
                  </div>
                </div>

                {/* Search Abilities */}
                <div className="space-y-3">
                  <h4 className="text-base font-medium mb-2 text-white flex items-center gap-2">
                    <Search className="h-4 w-4 text-gaming-assassin" />
                    Search Abilities
                  </h4>
                  <div className="grid grid-cols-1 gap-4 h-[600px] overflow-y-auto content-start pr-4 pl-2 pt-2 scrollbar-thin scrollbar-thumb-gaming-assassin scrollbar-track-gaming-assassin/10">
                    {assassinSearchAbilities.map((ability) => (
                      <AbilityCard
                        key={ability.name}
                        name={ability.name}
                        description={ability.description}
                        isActive={activeSkills.includes(ability.name)}
                        onToggle={() => handleToggleSkill(ability.name)}
                        type="search"
                      />
                    ))}
                  </div>
                </div>

                {/* Master Abilities */}
                <div className="space-y-3">
                  <h4 className="text-base font-medium mb-2 text-white flex items-center gap-2">
                    <Star className="h-4 w-4 text-gaming-assassin" />
                    Master Abilities
                  </h4>
                  <div className="grid grid-cols-1 gap-4 h-[600px] overflow-y-auto content-start pr-4 pl-2 pt-2 scrollbar-thin scrollbar-thumb-gaming-assassin scrollbar-track-gaming-assassin/10">
                    {assassinMasterAbilities.map((ability) => (
                      <AbilityCard
                        key={ability.name}
                        name={ability.name}
                        description={ability.description}
                        isActive={activeSkills.includes(ability.name)}
                        onToggle={() => handleToggleSkill(ability.name)}
                        type="master"
                      />
                    ))}
                  </div>
                </div>

                {/* Extra Abilities */}
                <div className="space-y-3">
                  <h4 className="text-base font-medium mb-2 text-white flex items-center gap-2">
                    <Gift className="h-4 w-4 text-gaming-assassin" />
                    Extra Abilities
                  </h4>
                  <div className="grid grid-cols-1 gap-2 h-[600px] overflow-y-auto content-start pr-4 pl-2 pt-2 scrollbar-thin scrollbar-thumb-gaming-assassin scrollbar-track-gaming-assassin/10">
                    {assassinExtraAbilities.map((ability) => (
                      <AbilityCard
                        key={ability.name}
                        name={ability.name}
                        description={ability.description}
                        isActive={activeSkills.includes(ability.name)}
                        onToggle={() => handleToggleSkill(ability.name)}
                        type="extra"
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-muted-foreground">
                Detailed ability information will be available when this class is released.
              </p>
            )}
          </Card>
        </TabsContent>
        
        <TabsContent value="stats">
          <Card className="glass-card p-6">
            <h3 className="text-lg font-semibold mb-4">{currentClass.name} Stats</h3>
            <p className="text-muted-foreground">
              {currentClass.id === 'assassin' ? 
                'Assassins excel in critical strike chance, attack speed, and mobility, with lower health and defense stats.' :
                'Character statistics will be available when this class is released.'}
            </p>
          </Card>
        </TabsContent>
        
        <TabsContent value="equipment">
          <Card className="glass-card p-6">
            <h3 className="text-lg font-semibold mb-4">{currentClass.name} Equipment</h3>
            <p className="text-muted-foreground">
              {currentClass.id === 'assassin' ? 
                'Assassins can equip light armor and agility-focused weapons, specializing in daggers, short swords, and throwing weapons.' :
                'Equipment loadout information will be available when this class is released.'}
            </p>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ClassesPage; 