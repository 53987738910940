import { supabase } from '../supabase';

export interface PotionSettingsData {
  hp_potion_threshold: number;
  mp_potion_threshold: number;
  critical_hp_threshold: number;
  critical_mp_threshold: number;
  minor_activate_hp: number;
  minor_deactivate_mp: number;
  auto_minor_low_hp: boolean;
  auto_hp_potion: boolean;
  auto_mp_potion: boolean;
}

// Default values
export const defaultPotionSettings: PotionSettingsData = {
  hp_potion_threshold: 70,
  mp_potion_threshold: 50,
  critical_hp_threshold: 30,
  critical_mp_threshold: 20,
  minor_activate_hp: 80,
  minor_deactivate_mp: 30,
  auto_minor_low_hp: false,
  auto_hp_potion: true,
  auto_mp_potion: true,
};

export async function savePotionSettings(settingsData: PotionSettingsData) {
  console.log("Starting savePotionSettings with data:", settingsData);
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    console.error("Authentication error:", userError);
    throw new Error('User not authenticated');
  }

  console.log("User authenticated:", userData.user.id);
  
  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Save operation timed out')), 3000);
    });

    const checkExistingPromise = supabase
      .from('potion_settings')
      .select('id')
      .eq('user_id', userData.user.id)
      .single();

    console.log("Checking for existing settings...");
    const { data: existingConfig, error: fetchError } = await Promise.race([
      checkExistingPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (fetchError && fetchError.code !== 'PGRST116') {
      console.error('Error checking existing potion settings:', fetchError);
      throw new Error(`Failed to check existing potion settings: ${fetchError.message}`);
    }

    console.log("Existing config check result:", existingConfig);
    let result;

    if (existingConfig) {
      console.log("Updating existing settings with ID:", existingConfig.id);
      const updatePromise = supabase
        .from('potion_settings')
        .update({
          ...settingsData,
          updated_at: new Date().toISOString(),
        })
        .eq('id', existingConfig.id)
        .select('*')
        .single();

      const { data, error } = await Promise.race([
        updatePromise,
        timeoutPromise as Promise<any>
      ]) as any;

      if (error) {
        console.error("Update error:", error);
        // Check for RLS policy violation
        if (error.code === '42501' || error.message?.includes('permission') || error.message?.includes('policy')) {
          throw new Error(`Database permission error: You don't have permission to update potion settings. Error: ${error.message}`);
        }
        throw new Error(`Failed to update potion settings: ${error.message}`);
      }
      
      result = data;
      console.log("Update successful:", result);
    } else {
      console.log("Creating new settings for user:", userData.user.id);
      const insertPromise = supabase
        .from('potion_settings')
        .insert({
          user_id: userData.user.id,
          ...settingsData,
        })
        .select('*')
        .single();

      const { data, error } = await Promise.race([
        insertPromise,
        timeoutPromise as Promise<any>
      ]) as any;

      if (error) {
        console.error("Insert error:", error);
        // Check for RLS policy violation
        if (error.code === '42501' || error.message?.includes('permission') || error.message?.includes('policy')) {
          throw new Error(`Database permission error: You don't have permission to insert potion settings. Error: ${error.message}`);
        }
        throw new Error(`Failed to save potion settings: ${error.message}`);
      }
      
      result = data;
      console.log("Insert successful:", result);
    }

    return result;
  } catch (error) {
    console.error('Save operation failed:', error);
    throw error;
  }
}

export async function getPotionSettings() {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), 2000);
    });
    
    const fetchPromise = supabase
      .from('potion_settings')
      .select('*')
      .eq('user_id', userData.user.id)
      .single();
    
    const { data, error } = await Promise.race([
      fetchPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (error) {
      if (error.code === 'PGRST116') {
        return defaultPotionSettings;
      }
      throw new Error(`Failed to fetch potion settings: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('Error or timeout fetching potion settings:', error);
    return defaultPotionSettings;
  }
}

export async function resetPotionSettings() {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Reset operation timed out')), 3000);
    });

    const checkExistingPromise = supabase
      .from('potion_settings')
      .select('id')
      .eq('user_id', userData.user.id)
      .single();
    
    const { data: existingConfig, error: fetchError } = await Promise.race([
      checkExistingPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (fetchError && fetchError.code === 'PGRST116') {
      return defaultPotionSettings;
    }

    if (fetchError) {
      throw new Error(`Failed to check existing potion settings: ${fetchError.message}`);
    }

    const updatePromise = supabase
      .from('potion_settings')
      .update({
        ...defaultPotionSettings,
        updated_at: new Date().toISOString(),
      })
      .eq('id', existingConfig.id)
      .select('*')
      .single();
    
    const { data, error } = await Promise.race([
      updatePromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (error) {
      throw new Error(`Failed to reset potion settings: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('Reset operation failed:', error);
    return defaultPotionSettings;
  }
} 