import { Ability } from './types';

// Assassin'e özel yetenekler
export const assassinClassAbilities: Ability[] = [
  {
    name: "Stroke",
    description: "Inflict 70% damage",
    requiredLevel: 1,
    mpCost: 2,
    cooldown: 0.5,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Stab",
    description: "Use a dagger to inflict 150% damage",
    requiredLevel: 5,
    mpCost: 5,
    cooldown: 5.2,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Stab2",
    description: "Use a dagger to inflict 150% damage and 50 additional damage with no chance of failure",
    requiredLevel: 7,
    mpCost: 5,
    cooldown: 5.2,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Jab",
    description: "A stabbing technique that disregards opponent's defense",
    requiredLevel: 0,
    mpCost: 10,
    cooldown: 5.2,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Blood Drain",
    description: "Drains enemy's blood to restore health",
    requiredLevel: 10,
    mpCost: 25,
    cooldown: 8,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Pierce",
    description: "Pierces through enemy defenses with deadly precision",
    requiredLevel: 15,
    mpCost: 30,
    cooldown: 10,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Shock",
    description: "Stuns the enemy with a quick strike",
    requiredLevel: 20,
    mpCost: 35,
    cooldown: 12,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Illusion",
    description: "Creates a decoy to confuse enemies",
    requiredLevel: 30,
    mpCost: 45,
    cooldown: 15,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Thrust",
    description: "A powerful forward thrust attack",
    requiredLevel: 35,
    mpCost: 40,
    cooldown: 8,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Cut",
    description: "Inflicts bleeding damage over time",
    requiredLevel: 40,
    mpCost: 50,
    cooldown: 15,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Stealth",
    description: "Become invisible to enemies",
    requiredLevel: 45,
    mpCost: 60,
    cooldown: 20,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Vampiric Touch",
    description: "Steals life force from enemies",
    requiredLevel: 50,
    mpCost: 70,
    cooldown: 25,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Spike",
    description: "Launches deadly spikes at enemies",
    requiredLevel: 55,
    mpCost: 65,
    cooldown: 18,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Throwing Knife",
    description: "Throws a volley of deadly knives",
    requiredLevel: 63,
    mpCost: 75,
    cooldown: 16,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Bloody Beast",
    description: "Transform into a beast of pure carnage",
    requiredLevel: 70,
    mpCost: 100,
    cooldown: 45,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Blinding",
    description: "Temporarily blinds enemies",
    requiredLevel: 72,
    mpCost: 80,
    cooldown: 30,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Beast Hiding",
    description: "Hide in plain sight like a predator",
    requiredLevel: 75,
    mpCost: 90,
    cooldown: 35,
    requiredWeapon: "Dagger",
    skillType: 'active'
  },
  {
    name: "Critical Point",
    description: "Strike enemy's vital points for massive damage",
    requiredLevel: 80,
    mpCost: 120,
    cooldown: 40,
    requiredWeapon: "Dagger",
    skillType: 'active'
  }
];

// Assassin'in kullanabildiği arama yetenekleri
export const assassinSearchAbilities: Ability[] = [
  {
    name: "Hide",
    description: "Become completely invisible to enemies",
    requiredLevel: 0,
    mpCost: 15,
    cooldown: 8,
    skillType: 'active'
  },
  {
    name: "Minor Healing",
    description: "Recover a small amount of HP",
    requiredLevel: 5,
    mpCost: 20,
    cooldown: 12,
    skillType: 'active'
  },
  {
    name: "Evade",
    description: "Quickly dodge incoming attacks",
    requiredLevel: 10,
    mpCost: 25,
    cooldown: 15,
    skillType: 'active'
  },
  {
    name: "Cats Eyes",
    description: "See clearly in darkness",
    requiredLevel: 15,
    mpCost: 30,
    cooldown: 20,
    skillType: 'active'
  },
  {
    name: "Light Feet",
    description: "Move silently without leaving tracks",
    requiredLevel: 25,
    mpCost: 35,
    cooldown: 25,
    skillType: 'active'
  },
  {
    name: "Safety",
    description: "Create a safe zone that prevents detection",
    requiredLevel: 30,
    mpCost: 40,
    cooldown: 30,
    skillType: 'active'
  },
  {
    name: "Lupine Eyes",
    description: "Enhanced vision to detect enemies",
    requiredLevel: 35,
    mpCost: 45,
    cooldown: 35,
    skillType: 'active'
  },
  {
    name: "Cure Curse",
    description: "Remove curse effects",
    requiredLevel: 36,
    mpCost: 50,
    cooldown: 40,
    skillType: 'active'
  },
  {
    name: "Cure Disease",
    description: "Cure disease status effects",
    requiredLevel: 48,
    mpCost: 55,
    cooldown: 45,
    skillType: 'active'
  },
  {
    name: "Scaled Skin",
    description: "Temporarily enhance defense",
    requiredLevel: 60,
    mpCost: 60,
    cooldown: 50,
    skillType: 'active'
  }
];

// Assassin'in kullanabildiği master yetenekleri
export const assassinMasterAbilities: Ability[] = [
  {
    name: "Valor",
    description: "Increase attack power temporarily",
    requiredLevel: 0,
    mpCost: 20,
    cooldown: 10,
    skillType: 'passive'
  },
  {
    name: "Magic Shield",
    description: "Create a protective magical barrier",
    requiredLevel: 2,
    mpCost: 25,
    cooldown: 15,
    skillType: 'active'
  },
  {
    name: "Absoluteness",
    description: "Guaranteed hit on next attack",
    requiredLevel: 5,
    mpCost: 30,
    cooldown: 20,
    skillType: 'passive'
  },
  {
    name: "Matchless",
    description: "Significantly increase evasion",
    requiredLevel: 10,
    mpCost: 35,
    cooldown: 25,
    skillType: 'passive'
  },
  {
    name: "Source Marking",
    description: "Mark target for enhanced damage",
    requiredLevel: 15,
    mpCost: 40,
    cooldown: 30,
    skillType: 'active'
  },
  {
    name: "Weapon Cancelation",
    description: "Disable target's weapon temporarily",
    requiredLevel: 20,
    mpCost: 45,
    cooldown: 35,
    skillType: 'active'
  },
  {
    name: "Eskrima",
    description: "Advanced weapon mastery technique",
    requiredLevel: 23,
    mpCost: 50,
    cooldown: 40,
    skillType: 'active'
  }
];

// Assassin'in kullanabildiği ekstra yetenekler
export const assassinExtraAbilities: Ability[] = [
  {
    name: "Health Potion",
    description: "Restores a significant amount of HP",
    requiredLevel: 0,
    mpCost: 0,
    cooldown: 30,
    skillType: 'active'
  },
  {
    name: "Mana Potion",
    description: "Restores a significant amount of MP",
    requiredLevel: 0,
    mpCost: 0,
    cooldown: 30,
    skillType: 'active'
  },
  {
    name: "Styx",
    description: "Grants temporary invulnerability",
    requiredLevel: 20,
    mpCost: 0,
    cooldown: 180,
    skillType: 'active'
  },
  {
    name: "Duration",
    description: "Extends the duration of active buffs",
    requiredLevel: 25,
    mpCost: 0,
    cooldown: 120,
    skillType: 'active'
  },
  {
    name: "Rice Cake",
    description: "Increases all stats temporarily",
    requiredLevel: 15,
    mpCost: 0,
    cooldown: 300,
    skillType: 'active'
  },
  {
    name: "Bezoar",
    description: "Removes all negative status effects",
    requiredLevel: 30,
    mpCost: 0,
    cooldown: 90,
    skillType: 'active'
  }
]; 