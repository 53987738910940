"use client"

import { useState, useRef, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from "@/components/ui/card"
import { Save, Copy, Image, Maximize2, RotateCcw, CheckCircle2, Layers, Sword, AlertTriangle, Shield, Heart } from "lucide-react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Badge } from "@/components/ui/badge"
import { Textarea } from "@/components/ui/textarea"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { supabase } from "@/lib/supabase"
import { useAuth } from "@/contexts/AuthContext"
import { toast } from "sonner"
import Draggable from 'react-draggable'

type Region = {
  id: string
  name: string
  x: number
  y: number
  width: number
  height: number
  color: string
  visible: boolean
  icon?: React.ReactNode
}

export default function RegionSettings() {
  const [image, setImage] = useState<string | null>(null)
  const [regions, setRegions] = useState<Region[]>([
    { 
      id: "hp", 
      name: "HP Bar", 
      x: 0, 
      y: 0, 
      width: 150, 
      height: 15,
      color: "rgba(255, 0, 0, 0.35)",
      visible: true,
      icon: <Heart className="w-4 h-4 text-red-500" />
    },
    { 
      id: "mp", 
      name: "MP Bar", 
      x: 0, 
      y: 25,
      width: 150, 
      height: 15,
      color: "rgba(0, 0, 255, 0.35)",
      visible: true,
      icon: <Shield className="w-4 h-4 text-blue-500" />
    },
    {
      id: "enemy_hp",
      name: "Enemy HP Bar",
      x: 0,
      y: 50,
      width: 200,
      height: 15,
      color: "rgba(255, 100, 0, 0.35)",
      visible: true,
      icon: <Sword className="w-4 h-4 text-orange-500" />
    },
    {
      id: "enemy_hp_notice",
      name: "Enemy HP Notice",
      x: 0,
      y: 75,
      width: 200,
      height: 15,
      color: "rgba(255, 0, 128, 0.35)",
      visible: true,
      icon: <AlertTriangle className="w-4 h-4 text-pink-500" />
    },
    {
      id: "skillbar",
      name: "Skill Bar",
      x: 0,
      y: 100,
      width: 300,
      height: 50,
      color: "rgba(0, 255, 0, 0.35)",
      visible: true,
      icon: <Sword className="w-4 h-4 text-green-500" />
    },
    {
      id: "buffs",
      name: "Buffs",
      x: 0,
      y: 160,
      width: 150,
      height: 150,
      color: "rgba(255, 255, 0, 0.35)",
      visible: true,
      icon: <Shield className="w-4 h-4 text-yellow-500" />
    },
  ])
  const [activeRegion, setActiveRegion] = useState<string | null>(null)
  const [resolution, setResolution] = useState({ width: 0, height: 0 })
  const [savedData, setSavedData] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState("editor")
  const [zoom, setZoom] = useState(1)
  const [showGrid, setShowGrid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [pageVisibility, setPageVisibility] = useState(true)

  const { user } = useAuth()
  const containerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Yeni yapıştırma işleminde koordinatları ve zoom seviyesini sıfırlama fonksiyonu
  const resetPositionsAfterPaste = () => {
    console.log("Hızlıca bölgeleri yeniden konumlandırıyorum");
    setRegions((prev) => prev.map((region, index) => {
      // Daha düzenli aralıklarla bölgeleri yerleştir
      let yPos = 0;
      
      if (index === 0) {
        yPos = 0;  // İlk bölge için y=0
      } else if (index <= 3) { 
        // HP, MP, Enemy HP, Enemy HP Notice barları için
        yPos = index * 25; // 15px yükseklik + 10px boşluk
      } else if (index === 4) {
        // Skill bar için
        yPos = 100; // Önceki barlardan sonra
      } else {
        // Buffs için
        yPos = 160; // Skill bardan sonra
      }
      
      return {
        ...region,
        x: 0,
        y: yPos,
        visible: true,
        // Barlar için güncellenmiş yükseklikler
        height: ["hp", "mp", "enemy_hp", "enemy_hp_notice"].includes(region.id) ? 15 : region.height
      };
    }));
    setZoom(1); // Zoom'u sıfırla
  };

  // Handle paste event
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const items = e.clipboardData?.items
      if (!items) return

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          const blob = items[i].getAsFile()
          if (blob) {
            const url = URL.createObjectURL(blob)
            setImage(url)

            // Get image dimensions after it loads
            const img = document.createElement('img')
            img.onload = () => {
              setResolution({ width: img.width, height: img.height })
              // Yeni bir resim yapıştırıldığında bölgeleri sıfırlamak yerine
              // kaydedilmiş bölgeleri koruyacağız, loadRegions useEffect tarafından çağrılacak
            }
            img.src = url
          }
        }
      }
    }

    document.addEventListener("paste", handlePaste)
    return () => document.removeEventListener("paste", handlePaste)
  }, [])

  // Handle drag and drop
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0]
      if (file.type.match(/image.*/)) {
        const reader = new FileReader()
        reader.onload = (readerEvent) => {
          if (readerEvent.target?.result) {
            const url = readerEvent.target.result as string
            setImage(url)

            // Get image dimensions after it loads
            const img = document.createElement('img')
            img.onload = () => {
              setResolution({ width: img.width, height: img.height })
              // Yeni bir resim sürükleyip bırakıldığında bölgeleri sıfırlamak yerine
              // kaydedilmiş bölgeleri koruyacağız, loadRegions useEffect tarafından çağrılacak
            }
            img.src = url
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }

  // Notification helper function
  const sendNotification = async (notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    if (!user) return;

    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: user.id,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Send to realtime channel
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Send to server
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: user.id,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Load saved regions from database
  const loadRegions = async (force = false) => {
    if (!user) return;

    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('ui_regions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) {
        if (error.code !== 'PGRST116') { // Code for "no rows returned" - not a real error in our case
          console.error('Error loading regions:', error);
          toast.error("Failed to load saved regions");
        }
        // Kayıt yoksa varsayılan konumları HEMEN yükle
        resetPositionsAfterPaste();
        setIsLoading(false);
        return;
      }

      if (data) {
        // Set resolution (only if there is no image loaded)
        if (data.resolution && (!resolution.width || !resolution.height)) {
          setResolution(data.resolution);
        }

        // Set regions with visibility all set to true - hızlı yükleme
        if (data.regions && Array.isArray(data.regions)) {
          const loadedRegions = data.regions.map(r => ({
            ...r,
            visible: true,
            color: regions.find(defaultRegion => defaultRegion.id === r.id)?.color || "rgba(255, 0, 0, 0.35)",
            icon: regions.find(defaultRegion => defaultRegion.id === r.id)?.icon
          }));
          
          // Set regions, and force a re-render
          setRegions(prev => {
            // Eğer bölgeler aynıysa (pozisyonlar) ve force değilse, mevcut state'i koru
            if (!force && JSON.stringify(prev.map(r => ({id: r.id, x: r.x, y: r.y}))) === 
                         JSON.stringify(loadedRegions.map(r => ({id: r.id, x: r.x, y: r.y})))) {
              return prev;
            }
            return loadedRegions;
          });
          
          console.log("Kaydedilmiş bölgeler başarıyla yüklendi", loadedRegions);
        }

        // Format the saved data for display
        const formattedData = `Screen Resolution: ${data.resolution.width}x${data.resolution.height}\n\nRegions:\n${data.regions
          .map((r: any) => `${r.name}:\n  X: ${r.x}\n  Y: ${r.y}\n  Width: ${r.width}\n  Height: ${r.height}\n`)
          .join("\n")}`;

        setSavedData(formattedData);

        // Sadece force varsa veya ilk kez yükleniyorsa bildirim göster
        if (force) {
          toast.success("Your saved UI regions have been loaded successfully");
        }
      }
    } catch (error) {
      console.error('Error loading regions:', error);
      toast.error("Failed to load saved regions");
      // Hata durumunda varsayılan konumları HEMEN yükle
      resetPositionsAfterPaste();
    } finally {
      setIsLoading(false);
    }
  };

  // Delete all saved regions from database
  const deleteAllRegions = async () => {
    if (!user) return;

    try {
      setIsLoading(true);
      
      // Check if we have an existing record
      const { data: existingData, error: checkError } = await supabase
        .from('ui_regions')
        .select('id')
        .eq('user_id', user.id);

      if (checkError) {
        throw checkError;
      }
      
      if (existingData && existingData.length > 0) {
        // Delete the record
        const { error: deleteError } = await supabase
          .from('ui_regions')
          .delete()
          .eq('id', existingData[0].id);

        if (deleteError) throw deleteError;
        
        // Reset to default values
        resetRegions();
        setSavedData(null);
        
        // Send notification
        await sendNotification({
          title: '🗑️ UI Regions Reset',
          message: 'All your saved UI region settings have been deleted.',
          type: 'info'
        });

        toast.success("All saved regions have been deleted from database");
      } else {
        toast.info("There were no saved regions to delete");
      }
    } catch (error) {
      console.error('Error deleting regions:', error);
      toast.error("Failed to delete saved regions");
    } finally {
      setIsLoading(false);
    }
  };

  // Save regions to database
  const saveRegions = async () => {
    if (!user || !resolution.width || !resolution.height) {
      toast.error("Please upload a screenshot first");
      return;
    }

    setIsSaving(true);
    
    try {
      // Gerçek koordinatları kaydet (zoom faktörü olmadan)
      const scaledRegions = regions
        .filter((r) => r.visible)
        .map((region) => ({
          id: region.id,
          name: region.name,
          x: Math.round(region.x),
          y: Math.round(region.y),
          width: Math.round(region.width),
          height: Math.round(region.height),
        }));

      // Save the regions and resolution
      const data = {
        user_id: user.id,
        resolution,
        regions: scaledRegions,
        updated_at: new Date().toISOString()
      };

      // Check if we have an existing record
      const { data: existingData, error: checkError } = await supabase
        .from('ui_regions')
        .select('id')
        .eq('user_id', user.id);

      if (checkError) {
        throw checkError;
      }

      let result;
      
      if (existingData && existingData.length > 0) {
        // Update existing record
        const { data: updateData, error: updateError } = await supabase
          .from('ui_regions')
          .update(data)
          .eq('id', existingData[0].id)
          .select();

        if (updateError) throw updateError;
        result = updateData;
      } else {
        // Insert new record
        const { data: insertData, error: insertError } = await supabase
          .from('ui_regions')
          .insert(data)
          .select();

        if (insertError) throw insertError;
        result = insertData;
      }

      // Format the data as a readable string
      const formattedData = `Screen Resolution: ${resolution.width}x${resolution.height}\n\nRegions:\n${scaledRegions
        .map((r) => `${r.name}:\n  X: ${r.x}\n  Y: ${r.y}\n  Width: ${r.width}\n  Height: ${r.height}\n`)
        .join("\n")}`;

      // Set the saved data
      setSavedData(formattedData);

      // Send notification
      await sendNotification({
        title: '✅ UI Regions Saved',
        message: 'Your game UI regions have been saved successfully.',
        type: 'success'
      });

      toast.success("UI regions saved successfully");

      // Switch to the notes tab
      setActiveTab("notes");
    } catch (error) {
      console.error('Error saving regions:', error);
      
      // Send error notification
      await sendNotification({
        title: '❌ UI Regions Error',
        message: 'There was an error saving your game UI regions.',
        type: 'error'
      });
      
      toast.error("Failed to save regions");
    } finally {
      setIsSaving(false);
    }
  };

  const resetRegions = () => {
    setRegions([
      { 
        id: "hp", 
        name: "HP Bar", 
        x: 0, 
        y: 0, 
        width: 150, 
        height: 15,
        color: "rgba(255, 0, 0, 0.35)",
        visible: true,
        icon: <Heart className="w-4 h-4 text-red-500" />
      },
      { 
        id: "mp", 
        name: "MP Bar", 
        x: 0, 
        y: 25,
        width: 150, 
        height: 15,
        color: "rgba(0, 0, 255, 0.35)",
        visible: true,
        icon: <Shield className="w-4 h-4 text-blue-500" />
      },
      {
        id: "enemy_hp",
        name: "Enemy HP Bar",
        x: 0,
        y: 50,
        width: 200,
        height: 15,
        color: "rgba(255, 100, 0, 0.35)",
        visible: true,
        icon: <Sword className="w-4 h-4 text-orange-500" />
      },
      {
        id: "enemy_hp_notice",
        name: "Enemy HP Notice",
        x: 0,
        y: 75,
        width: 200,
        height: 15,
        color: "rgba(255, 0, 128, 0.35)",
        visible: true,
        icon: <AlertTriangle className="w-4 h-4 text-pink-500" />
      },
      {
        id: "skillbar",
        name: "Skill Bar",
        x: 0,
        y: 100,
        width: 300,
        height: 50,
        color: "rgba(0, 255, 0, 0.35)",
        visible: true,
        icon: <Sword className="w-4 h-4 text-green-500" />
      },
      {
        id: "buffs",
        name: "Buffs",
        x: 0,
        y: 160,
        width: 150,
        height: 150,
        color: "rgba(255, 255, 0, 0.35)",
        visible: true,
        icon: <Shield className="w-4 h-4 text-yellow-500" />
      },
    ]);
    setZoom(1);
  };

  const copyToClipboard = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand("copy");
      toast.success("Region data copied to clipboard");
    }
  };

  const toggleRegionVisibility = (id: string) => {
    setRegions((prev) => prev.map((region) => (region.id === id ? { ...region, visible: !region.visible } : region)));
  };

  const zoomIn = () => {
    setZoom((prev) => Math.min(prev + 0.1, 2));
  };

  const zoomOut = () => {
    setZoom((prev) => Math.max(prev - 0.1, 0.5));
  };

  const resetZoom = () => {
    setZoom(1);
  };

  // Klavye kısayolları ile seçili bölgeyi hareket ettirme ve boyutlandırma
  useEffect(() => {
    if (!activeRegion) return;
    
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!activeRegion) return;
      
      const activeRegionObj = regions.find(r => r.id === activeRegion);
      if (!activeRegionObj) return;
      
      // Ctrl+Shift tuşu kombinasyonu: Boyut değiştirme
      // Shift tuşu: Daha hızlı hareket (5 birim)
      // Normal: Yavaş hareket (1 birim)
      const step = e.shiftKey ? 5 : 1;
      
      // Esc tuşu: Seçimi temizleme
      if (e.key === "Escape") {
        setActiveRegion(null);
        return;
      }
      
      if (e.ctrlKey && e.shiftKey) {
        // Boyutlandırma
        switch(e.key) {
          case "ArrowRight":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, width: r.width + step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowLeft":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion && r.width > step
                ? { ...r, width: r.width - step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowDown":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, height: r.height + step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowUp":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion && r.height > step
                ? { ...r, height: r.height - step } 
                : r
            ));
            e.preventDefault();
            break;
        }
      } else {
        // Konum değiştirme
        switch(e.key) {
          case "ArrowRight":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, x: r.x + step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowLeft":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, x: r.x - step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowDown":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, y: r.y + step } 
                : r
            ));
            e.preventDefault();
            break;
          case "ArrowUp":
            setRegions(prev => prev.map(r => 
              r.id === activeRegion 
                ? { ...r, y: r.y - step } 
                : r
            ));
            e.preventDefault();
            break;
        }
      }
    };
    
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [activeRegion, regions]);

  // Load regions on initial render
  useEffect(() => {
    if (user) {
      loadRegions();
    }
  }, [user]);

  // Yeni resim yüklendiğinde kaydedilmiş bölgeleri yükle
  useEffect(() => {
    if (image && user) {
      loadRegions(true); // Force=true ile çağır, bölgeleri zorla yeniden yüklemek için
    }
  }, [image]);

  // Sayfa görünürlüğünü takip et ve sekme değiştiğinde bölgeleri yeniden yükle
  useEffect(() => {
    // Sayfa görünürlüğü değiştiğinde çalışacak fonksiyon
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible';
      setPageVisibility(isVisible);
      
      // Sayfa tekrar görünür olduğunda ve resim varsa bölgeleri yeniden yükle
      if (isVisible && image && user) {
        console.log("Sekme tekrar aktif oldu, bölgeleri yeniden yüklüyorum");
        loadRegions(true); // Force=true ile çağır, bölgeleri zorla yeniden yüklemek için
      }
    };

    // Event listener'ları ekle
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Component unmount olduğunda event listener'ları temizle
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [image, user]);

  useEffect(() => {
    if (image && containerRef.current && imageRef.current) {
      // Görüntüyü konteyner boyutuna sığdırmak için otomatik zoom ayarı
      const container = containerRef.current;
      const containerWidth = container.clientWidth - 32; // padding için
      const containerHeight = container.clientHeight - 32; // padding için
      
      const imgRatio = resolution.width / resolution.height;
      const containerRatio = containerWidth / containerHeight;
      
      let newZoom = 1;
      
      if (imgRatio > containerRatio) {
        // Görüntü daha geniş, genişliğe göre ölçeklendir
        newZoom = containerWidth / resolution.width;
      } else {
        // Görüntü daha uzun, yüksekliğe göre ölçeklendir
        newZoom = containerHeight / resolution.height;
      }
      
      // Minimum zoom değerini garantile
      const minZoom = 0.5; // Minimum zoom değeri
      const maxZoom = 1.0; // Maximum zoom değeri - çok büyük olursa sürükleme sorunları olabilir

      // Ekran görüntüsünü uygun boyutta göster
      // Ancak çok büyük olmasını önle
      setZoom(Math.max(Math.min(newZoom, maxZoom), minZoom));
      
      console.log("Calculated zoom:", newZoom, "Applied zoom:", Math.max(Math.min(newZoom, maxZoom), minZoom));
    }
  }, [image, resolution.width, resolution.height]);

  return (
    <div className="space-y-6">
      <Card className="glass-card">
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle className="flex items-center gap-2">
              <Layers className="h-5 w-5 text-gaming" />
              Game UI Region Settings
            </CardTitle>
            <Button 
              onClick={saveRegions}
              className="bg-gaming hover:bg-gaming/90"
              disabled={isSaving || isLoading || !image}
            >
              {isSaving ? (
                <>
                  <svg className="h-4 w-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  Save Regions
                </>
              )}
            </Button>
          </div>
        </CardHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <div className="px-6 pt-4 border-b border-border">
            <TabsList className="w-full grid grid-cols-2">
              <TabsTrigger value="editor" className="data-[state=active]:bg-gaming/10 data-[state=active]:text-gaming">
                <Layers className="w-4 h-4 mr-2" />
                Region Editor
              </TabsTrigger>
              <TabsTrigger value="notes" className="data-[state=active]:bg-gaming/10 data-[state=active]:text-gaming">
                <CheckCircle2 className="w-4 h-4 mr-2" />
                Saved Data
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value="editor" className="mt-0 p-0">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-6">
              <div className="md:col-span-1 space-y-4">
                <div className="bg-background/50 rounded-lg p-4 border border-border/50">
                  <h3 className="text-sm font-medium mb-3 flex items-center">
                    <Layers className="w-4 h-4 mr-2 text-gaming" />
                    Regions
                  </h3>
                  <ScrollArea className="h-[400px] pr-4">
                    <div className="space-y-3">
                      {regions.map((region) => (
                        <div
                          key={region.id}
                          className={`
                            p-3 rounded-lg border transition-all duration-200 cursor-pointer
                            ${
                              activeRegion === region.id
                                ? "border-gaming/50 bg-gaming/10 shadow-sm"
                                : "border-border/50 hover:border-gaming/30"
                            }
                            ${!region.visible && "opacity-50"}
                          `}
                          onClick={() => setActiveRegion(region.id)}
                        >
                          <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center">
                              <div className="w-4 h-4 rounded-sm mr-2" style={{ backgroundColor: region.color }}></div>
                              <span className="font-medium text-sm">{region.name}</span>
                            </div>
                            <Switch
                              checked={region.visible}
                              onCheckedChange={() => toggleRegionVisibility(region.id)}
                              className="data-[state=checked]:bg-gaming"
                            />
                          </div>
                          <div className="grid grid-cols-2 gap-1 text-xs text-muted-foreground">
                            <div>X: {region.x}</div>
                            <div>Y: {region.y}</div>
                            <div>W: {region.width}</div>
                            <div>H: {region.height}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </div>

                {/* Manuel Koordinat Düzenleme Formu */}
                {activeRegion && (
                  <div className="bg-background/50 rounded-lg p-4 border border-border/50 mt-4">
                    <h3 className="text-sm font-medium mb-3 flex items-center">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-4 h-4 mr-2 text-gaming" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="m3 16 4 4 4-4" />
                        <path d="M7 20V4" />
                        <path d="M21 8h-8" />
                        <path d="m17 4 4 4-4 4" />
                      </svg>
                      Manuel Ayarlar
                    </h3>
                    <div className="space-y-3">
                      {regions
                        .filter(region => region.id === activeRegion)
                        .map(region => (
                          <div key={region.id} className="space-y-2">
                            <div className="bg-gaming/10 text-xs p-2 rounded-md mb-2 text-muted-foreground">
                              <p className="font-medium text-gaming mb-1">Klavye Kısayolları:</p>
                              <ul className="list-disc list-inside space-y-1">
                                <li><kbd className="bg-background px-1 rounded">Ok tuşları</kbd> - Bölgeyi hareket ettir</li>
                                <li><kbd className="bg-background px-1 rounded">Shift + Ok tuşları</kbd> - Daha hızlı hareket (5px)</li>
                                <li><kbd className="bg-background px-1 rounded">Ctrl + Shift + Ok tuşları</kbd> - Boyutlandırma</li>
                                <li><kbd className="bg-background px-1 rounded">Esc</kbd> - Seçimi temizle</li>
                              </ul>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                              <div className="space-y-1">
                                <label className="text-xs font-medium">X Pozisyonu</label>
                                <div className="flex items-center">
                                  <input
                                    type="number"
                                    value={region.x}
                                    onChange={(e) => {
                                      const newValue = parseInt(e.target.value, 10);
                                      if (!isNaN(newValue)) {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, x: newValue } 
                                            : r
                                        ));
                                      }
                                    }}
                                    className="w-full h-8 px-2 py-1 rounded-md text-xs bg-background border border-border focus:outline-none focus:ring-1 focus:ring-gaming"
                                  />
                                  <div className="flex flex-col ml-1">
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-t hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, x: r.x + 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▲</span>
                                    </button>
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-b hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, x: r.x - 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▼</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="space-y-1">
                                <label className="text-xs font-medium">Y Pozisyonu</label>
                                <div className="flex items-center">
                                  <input
                                    type="number"
                                    value={region.y}
                                    onChange={(e) => {
                                      const newValue = parseInt(e.target.value, 10);
                                      if (!isNaN(newValue)) {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, y: newValue } 
                                            : r
                                        ));
                                      }
                                    }}
                                    className="w-full h-8 px-2 py-1 rounded-md text-xs bg-background border border-border focus:outline-none focus:ring-1 focus:ring-gaming"
                                  />
                                  <div className="flex flex-col ml-1">
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-t hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, y: r.y + 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▲</span>
                                    </button>
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-b hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, y: r.y - 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▼</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                              <div className="space-y-1">
                                <label className="text-xs font-medium">Genişlik</label>
                                <div className="flex items-center">
                                  <input
                                    type="number"
                                    value={region.width}
                                    onChange={(e) => {
                                      const newValue = parseInt(e.target.value, 10);
                                      if (!isNaN(newValue) && newValue > 0) {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, width: newValue } 
                                            : r
                                        ));
                                      }
                                    }}
                                    className="w-full h-8 px-2 py-1 rounded-md text-xs bg-background border border-border focus:outline-none focus:ring-1 focus:ring-gaming"
                                  />
                                  <div className="flex flex-col ml-1">
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-t hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, width: r.width + 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▲</span>
                                    </button>
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-b hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id && r.width > 1
                                            ? { ...r, width: r.width - 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▼</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="space-y-1">
                                <label className="text-xs font-medium">Yükseklik</label>
                                <div className="flex items-center">
                                  <input
                                    type="number"
                                    value={region.height}
                                    onChange={(e) => {
                                      const newValue = parseInt(e.target.value, 10);
                                      if (!isNaN(newValue) && newValue > 0) {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, height: newValue } 
                                            : r
                                        ));
                                      }
                                    }}
                                    className="w-full h-8 px-2 py-1 rounded-md text-xs bg-background border border-border focus:outline-none focus:ring-1 focus:ring-gaming"
                                  />
                                  <div className="flex flex-col ml-1">
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-t hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, height: r.height + 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▲</span>
                                    </button>
                                    <button 
                                      className="h-4 px-1 bg-background border border-border rounded-b hover:bg-gaming/10 text-xs"
                                      onClick={() => {
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id && r.height > 1
                                            ? { ...r, height: r.height - 1 } 
                                            : r
                                        ));
                                      }}
                                    >
                                      <span className="block -mt-0.5">▼</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-end mt-2">
                              <Button 
                                size="sm" 
                                className="text-xs h-7"
                                onClick={() => setActiveRegion(null)}
                              >
                                Bitir
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div className="bg-background/50 rounded-lg p-4 border border-border/50">
                  <h3 className="text-sm font-medium mb-3 flex items-center">
                    <Image className="w-4 h-4 mr-2 text-gaming" />
                    Image Controls
                  </h3>
                  <div className="space-y-3">
                    <div className="flex items-center justify-between">
                      <span className="text-sm">Zoom</span>
                      <div className="flex items-center space-x-2">
                        <Button
                          variant="outline"
                          size="icon"
                          className="h-7 w-7"
                          onClick={zoomOut}
                          disabled={zoom <= 0.5}
                        >
                          <span className="text-lg font-bold">-</span>
                        </Button>
                        <Button variant="outline" size="sm" className="h-7 px-2 text-xs font-mono" onClick={resetZoom}>
                          {Math.round(zoom * 100)}%
                        </Button>
                        <Button variant="outline" size="icon" className="h-7 w-7" onClick={zoomIn} disabled={zoom >= 2}>
                          <span className="text-lg font-bold">+</span>
                        </Button>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="text-sm">Show Grid</span>
                      <Switch
                        checked={showGrid}
                        onCheckedChange={setShowGrid}
                        className="data-[state=checked]:bg-gaming"
                      />
                    </div>

                    {resolution.width > 0 && (
                      <div className="mt-4 pt-4 border-t border-border/50">
                        <div className="flex items-center justify-between mb-2">
                          <span className="text-sm font-medium">Resolution</span>
                          <Badge variant="outline" className="font-mono text-xs">
                            {resolution.width}×{resolution.height}
                          </Badge>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="md:col-span-3">
                <div
                  className={`
                    relative border-2 rounded-lg
                    ${image ? "border-solid border-border/50" : "border-dashed border-border/30"}
                    ${showGrid && image ? "bg-grid-pattern" : "bg-background/50"}
                  `}
                  style={{
                    minHeight: "500px",
                    maxHeight: "700px",
                    height: image ? "700px" : "500px", // Görüntü varsa daha geniş, yoksa sabit yükseklik
                    backgroundSize: "20px 20px",
                    backgroundImage:
                      showGrid && image
                        ? "linear-gradient(to right, rgba(128, 128, 128, 0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 1px, transparent 1px)"
                        : "none",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  ref={containerRef}
                >
                  {isLoading && (
                    <div className="absolute inset-0 bg-background/80 flex items-center justify-center z-50">
                      <div className="flex flex-col items-center gap-3">
                        <svg className="h-8 w-8 animate-spin text-gaming" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <p className="text-sm text-gaming font-medium">Loading saved regions...</p>
                      </div>
                    </div>
                  )}
                  
                  {image ? (
                    <div 
                      className="relative overflow-auto flex-1 p-4 scrollbar-custom"
                      style={{
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent',
                      }}
                    >
                      <div
                        className="relative"
                        style={{
                          transform: `scale(${zoom})`,
                          transformOrigin: "top left",
                          transition: "transform 0.2s ease",
                          width: `${resolution.width}px`,
                          height: `${resolution.height}px`,
                        }}
                      >
                        {/* Resim alt katman olarak */}
                        <img 
                          src={image || "/placeholder.svg"} 
                          alt="Screenshot" 
                          className="max-w-full shadow-md rounded-sm" 
                          ref={imageRef} 
                          style={{
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none",
                          }}
                          draggable="false"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                        
                        {/* Bölgeler üst katman olarak */}
                        <div className="absolute top-0 left-0 w-full h-full" style={{ zIndex: 10 }}>
                          {regions
                            .filter((r) => r.visible)
                            .map((region) => (
                              <Draggable
                                key={region.id}
                                position={{x: region.x || 0, y: region.y || 0}}
                                onStart={() => setActiveRegion(region.id)}
                                onStop={(e, data) => {
                                  setActiveRegion(null);
                                  // Pozisyonu güncelle - doğrudan veriyi kaydet, zoom faktörünü burada hesaba katma
                                  setRegions(prev => prev.map(r => 
                                    r.id === region.id 
                                      ? { ...r, x: data.x, y: data.y } 
                                      : r
                                  ));
                                }}
                                bounds="parent"
                                scale={zoom}
                                positionOffset={{x: 0, y: 0}}
                                defaultClassName="react-draggable"
                                defaultClassNameDragging="react-draggable-dragging"
                              >
                                <div
                                  className={`
                                  cursor-move transition-all duration-200
                                  ${
                                    activeRegion === region.id
                                      ? "border-white shadow-lg ring-2 ring-gaming ring-opacity-50"
                                      : "border-black"
                                  }
                                `}
                                  style={{
                                    width: `${region.width}px`,
                                    height: `${region.height}px`,
                                    backgroundColor: region.color,
                                    boxShadow: "0 0 8px rgba(0,0,0,0.3)",
                                    backdropFilter: "blur(1px)",
                                    position: "absolute",
                                    zIndex: activeRegion === region.id ? 20 : 10,
                                    borderWidth: "3px",
                                  }}
                                >
                                  <div className="absolute top-0 transform -translate-y-full left-0 bg-black text-white text-xs px-1.5 py-0.5 mb-1 pointer-events-none rounded-t flex items-center gap-1">
                                    {region.icon}
                                    {region.name}
                                  </div>
                                  <div
                                    className="absolute bottom-0 right-0 w-6 h-6 bg-white/90 border border-black cursor-se-resize rounded-tl flex items-center justify-center shadow-sm hover:bg-gaming/20 transition-colors duration-200"
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                      
                                      const startX = e.clientX;
                                      const startY = e.clientY;
                                      const startWidth = region.width;
                                      const startHeight = region.height;
                                      
                                      // Etkin bölgeyi ayarla - bu resize işlemi için öncelikli olacak
                                      setActiveRegion(region.id);
                                      
                                      const handleMouseMove = (moveEvent: MouseEvent) => {
                                        // Doğrudan ve hızlı hesaplama - gecikme olmadan
                                        const newWidth = Math.max(20, startWidth + (moveEvent.clientX - startX) / zoom);
                                        
                                        // Minimum yüksekliği region türüne göre ayarlayalım
                                        let minHeight = 10; // Genel minimum yükseklik
                                        
                                        // HP, MP ve diğer statik barlar için daha küçük minimum yükseklik
                                        if (["hp", "mp", "enemy_hp", "enemy_hp_notice"].includes(region.id)) {
                                          minHeight = 5; // Barlar için çok daha düşük minimum yükseklik
                                        }
                                        
                                        const newHeight = Math.max(minHeight, startHeight + (moveEvent.clientY - startY) / zoom);
                                        
                                        // Performans için React state güncellemesini optimize edelim
                                        setRegions(prev => prev.map(r => 
                                          r.id === region.id 
                                            ? { ...r, width: newWidth, height: newHeight } 
                                            : r
                                        ));
                                      };
                                      
                                      const handleMouseUp = () => {
                                        // Tüm işlemleri temizleyelim
                                        document.removeEventListener('mousemove', handleMouseMove);
                                        document.removeEventListener('mouseup', handleMouseUp);
                                        // İşlem tamamlandığında etkin bölgeyi null'a ayarlayalım
                                        setActiveRegion(null);
                                      };
                                      
                                      // Event listener'ları ekleyelim
                                      document.addEventListener('mousemove', handleMouseMove);
                                      document.addEventListener('mouseup', handleMouseUp);
                                    }}
                                  >
                                    <Maximize2 className="w-3.5 h-3.5 text-gaming" />
                                  </div>
                                </div>
                              </Draggable>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center p-8 min-h-[500px]">
                      <div className="w-20 h-20 bg-gaming/5 rounded-full flex items-center justify-center mb-6">
                        <Image className="w-10 h-10 text-gaming/50" />
                      </div>
                      <h3 className="text-xl font-medium mb-2">
                        Drop your Knight Online screenshot here
                      </h3>
                      <p className="text-muted-foreground text-center max-w-md mb-6">
                        Paste from clipboard (<span className="font-bold">Ctrl+V</span>) or drag and drop a screenshot to begin marking regions. 
                        <span className="block mt-2 text-gaming">The screenshot is only used locally and won't be uploaded to the server.</span>
                      </p>

                      <div className="grid grid-cols-3 gap-3 max-w-lg">
                        {regions.map((region) => (
                          <div
                            key={region.id}
                            className="flex items-center gap-2 p-2 border rounded-md bg-background shadow-sm"
                          >
                            <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: region.color }}></div>
                            <span className="text-sm">{region.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex justify-between mt-4">
                  <div className="flex gap-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button variant="outline" onClick={resetRegions} disabled={!image}>
                            <RotateCcw className="w-4 h-4 mr-2" />
                            Reset Layout
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Reset all regions to their default positions</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button 
                            variant="destructive" 
                            onClick={deleteAllRegions} 
                            disabled={isSaving || isLoading}
                            className="hover:bg-destructive/90"
                          >
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              className="w-4 h-4 mr-2" 
                              viewBox="0 0 24 24" 
                              fill="none" 
                              stroke="currentColor" 
                              strokeWidth="2" 
                              strokeLinecap="round" 
                              strokeLinejoin="round"
                            >
                              <path d="M3 6h18"></path>
                              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            Delete Saved Data
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Delete all saved regions from database</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="notes" className="mt-0 p-0">
            <CardContent className="p-6">
              {savedData ? (
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium flex items-center">
                      <CheckCircle2 className="w-5 h-5 mr-2 text-green-500" />
                      Saved Region Data
                    </h3>
                    <Button variant="outline" size="sm" onClick={copyToClipboard} className="gap-1.5">
                      <Copy className="w-4 h-4" />
                      Copy to Clipboard
                    </Button>
                  </div>

                  <div className="bg-background/50 rounded-lg border border-border/50 overflow-hidden">
                    <Textarea
                      ref={textareaRef}
                      value={savedData}
                      readOnly
                      className="font-mono text-sm h-[400px] border-0 bg-transparent focus-visible:ring-0 resize-none p-4"
                    />
                  </div>

                  <Alert className="bg-gaming/5 border-gaming/30">
                    <AlertDescription className="text-sm flex items-start">
                      <div className="bg-gaming/10 p-2 rounded-full mr-3 mt-0.5">
                        <CheckCircle2 className="w-4 h-4 text-gaming" />
                      </div>
                      <div>
                        <p className="font-medium mb-1">Success! Your regions have been saved.</p>
                        <p className="text-muted-foreground">
                          The region coordinates and screen resolution have been saved to the database. The Knight Online
                          client application will use these regions to automatically detect health bars, buff icons, and other UI elements.
                        </p>
                      </div>
                    </AlertDescription>
                  </Alert>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-[500px] text-center">
                  <div className="w-16 h-16 bg-gaming/5 rounded-full flex items-center justify-center mb-4">
                    <Layers className="w-8 h-8 text-gaming/50" />
                  </div>
                  <h3 className="text-xl font-medium mb-2">No saved data yet</h3>
                  <p className="text-muted-foreground mb-6 max-w-md">
                    Go to the Region Editor tab, upload a Knight Online screenshot, and save your regions to see the data here
                  </p>
                  <Button
                    onClick={() => setActiveTab("editor")}
                    className="bg-gaming hover:bg-gaming/90 text-white"
                  >
                    Go to Editor
                  </Button>
                </div>
              )}
            </CardContent>

            <CardFooter className="flex justify-end p-6 pt-0">
              <Button
                onClick={() => {
                  setImage(null);
                  resetRegions();
                  setActiveTab("editor");
                }}
                variant="outline"
                className="gap-1.5"
              >
                <Image className="w-4 h-4" />
                New Screenshot
              </Button>
            </CardFooter>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
} 