import * as React from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { GitBranch, AlertCircle, Plus, Shield, Heart, Clock, Repeat, AlertTriangle, ArrowDown, ArrowUp, Save, Sword, Loader2 } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { assassinClassAbilities } from '@/data/abilities/assassin';
import { SkillNode } from './nodes/SkillNode';
import { ConditionNode } from './nodes/ConditionNode';
import { saveSkillRule, getSkillRules } from '@/lib/services/skill-rules';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';

const conditionTypes = [
  {
    id: "vsMode",
    label: "VS Mode Only",
    icon: <Shield className="mr-2 h-4 w-4" />,
    description: "This skill will only be used in VS mode",
    valueType: "boolean",
  },
  {
    id: "harassMode",
    label: "Harass Mode Only",
    icon: <AlertTriangle className="mr-2 h-4 w-4" />,
    description: "This skill will only be used in Harass mode",
    valueType: "boolean",
  },
  {
    id: "pkMode",
    label: "PK Mode Only",
    icon: <Sword className="mr-2 h-4 w-4" />,
    description: "This skill will only be used in PK mode",
    valueType: "boolean",
  },
  {
    id: "enemyHpLess",
    label: "Use at enemy HP < %",
    icon: <Shield className="mr-2 h-4 w-4" />,
    description: "Trigger when enemy health falls below specified percentage",
    valueType: "percentage",
    arrowIcon: <ArrowDown className="h-3 w-3 text-red-400" />,
  },
  {
    id: "enemyHpMore",
    label: "Use at enemy HP > %",
    icon: <Shield className="mr-2 h-4 w-4" />,
    description: "Trigger when enemy health is above specified percentage",
    valueType: "percentage",
    arrowIcon: <ArrowUp className="h-3 w-3 text-green-400" />,
  },
  {
    id: "myHpLess",
    label: "Use when my HP is at < %",
    icon: <Heart className="mr-2 h-4 w-4" />,
    description: "Trigger when your health falls below specified percentage",
    valueType: "percentage",
    arrowIcon: <ArrowDown className="h-3 w-3 text-red-400" />,
  },
  {
    id: "myHpMore",
    label: "Use when my HP is at > %",
    icon: <Heart className="mr-2 h-4 w-4" />,
    description: "Trigger when your health is above specified percentage",
    valueType: "percentage",
    arrowIcon: <ArrowUp className="h-3 w-3 text-green-400" />,
  },
  {
    id: "skillNotReady",
    label: "Use when skill is not ready",
    icon: <Clock className="mr-2 h-4 w-4" />,
    description: "Trigger when the selected skill is on cooldown",
    valueType: "skill",
  },
  {
    id: "skillReady",
    label: "Use when skill is ready",
    icon: <Clock className="mr-2 h-4 w-4" />,
    description: "Trigger when the selected skill is ready to use",
    valueType: "skill",
  },
  {
    id: "usageLimit",
    label: "Skill can be used X times",
    icon: <Repeat className="mr-2 h-4 w-4" />,
    description: "Limit the number of times this skill can be used",
    valueType: "number",
  },
  {
    id: "criticalMode",
    label: "In critical mode",
    icon: <AlertTriangle className="mr-2 h-4 w-4" />,
    description: "Only trigger when in critical mode",
    valueType: "boolean",
  },
];

// Node types for React Flow
const nodeTypes = {
  skillNode: SkillNode,
  conditionNode: ConditionNode,
};

export const SkillRules = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // Get authenticated user
  const { user } = useAuth();
  
  // Create a ref for the ReactFlow instance
  const reactFlowInstanceRef = useRef(null);
  
  // Create a ref to track the nodes
  const nodeMapRef = React.useRef(new Map());
  
  // Add notification sending helper function
  const sendNotification = async (notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    if (!user) return;

    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: user.id,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Send to realtime channel
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Send to server
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: user.id,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };
  
  // Load existing rules from database
  const loadRules = useCallback(async () => {
    try {
      setIsLoading(true);
      const rules = await getSkillRules();
      
      if (rules && rules.length > 0) {
        // Check if there are multiple rules
        if (rules.length > 1) {
          console.log(`Found ${rules.length} saved rule configurations. Using the most recent one.`);
          console.log(`Most recent rule created at: ${new Date(rules[0].created_at).toLocaleString()}`);
        } else {
          console.log(`Found 1 saved rule configuration created at: ${new Date(rules[0].created_at).toLocaleString()}`);
        }
        
        // Use the most recent rule (first in the array since we sort by created_at desc)
        const latestRule = rules[0];
        return latestRule;
      } else {
        console.log("No saved rules found. Starting with empty configuration.");
      }
    } catch (error) {
      console.error('Error loading rules:', error);
      toast.error("Failed to load saved rules");
    } finally {
      setIsLoading(false);
    }
    return null;
  }, []);
  
  // Reposition condition nodes for a skill in a grid layout
  const repositionConditionNodes = useCallback((skillNodeId: string) => {
    console.log("Repositioning conditions for skill:", skillNodeId);
    
    // Get the skill node
    const skillNode = nodes.find(n => n.id === skillNodeId);
    if (!skillNode) {
      console.error("Skill node not found for repositioning:", skillNodeId);
      return;
    }
    
    // Get all conditions for this skill
    const conditionsForSkill = nodes.filter(n => 
      n.type === 'conditionNode' && 
      edges.some(e => e.source === skillNodeId && e.target === n.id)
    );
    
    if (conditionsForSkill.length === 0) {
      console.log("No conditions to reposition for skill:", skillNodeId);
      return;
    }
    
    console.log(`Repositioning ${conditionsForSkill.length} conditions for skill ${skillNodeId}`);
    
    // Fixed layout properties (same as in addConditionNode)
    const conditionWidth = 300;
    const horizontalGap = 30;
    const verticalGap = 30;
    const conditionsPerRow = 2;
    const rowHeight = 170;
    const initialOffset = 150;
    
    // Calculate total width needed for the conditions in a row
    const totalRowWidth = (conditionsPerRow * conditionWidth) + ((conditionsPerRow - 1) * horizontalGap);
    
    // Center the conditions under the skill node
    const startX = skillNode.position.x - (totalRowWidth / 2);
    
    // Create a copy of the nodes
    const updatedNodes = [...nodes];
    
    // Reposition each condition with its new index
    conditionsForSkill.forEach((condition, index) => {
      const row = Math.floor(index / conditionsPerRow);
      const col = index % conditionsPerRow;
      
      // Calculate position
      const xPos = startX + (col * (conditionWidth + horizontalGap));
      const yPos = skillNode.position.y + initialOffset + (row * (rowHeight + verticalGap));
      
      console.log(`Repositioning condition ${condition.id} to index ${index}, row ${row}, col ${col}: (${xPos}, ${yPos})`);
      
      // Find the node in our copy and update its position
      const nodeIndex = updatedNodes.findIndex(n => n.id === condition.id);
      if (nodeIndex >= 0) {
        updatedNodes[nodeIndex] = {
          ...updatedNodes[nodeIndex],
          position: { x: xPos, y: yPos }
        };
      }
    });
    
    // Now check if we need to update positions of skill nodes in rows below this one
    // Find which row this skill is in
    const skillsPerRow = 5;
    const skillNodes = nodes.filter(n => n.type === 'skillNode');
    const skillNodeIds = skillNodes.map(n => n.id);
    const currentSkillIndex = skillNodeIds.indexOf(skillNodeId);
    
    if (currentSkillIndex >= 0) {
      const currentRow = Math.floor(currentSkillIndex / skillsPerRow);
      const conditionRows = Math.ceil(conditionsForSkill.length / conditionsPerRow);
      
      console.log(`Skill ${skillNodeId} is in row ${currentRow} and has ${conditionRows} rows of conditions`);
      
      // Check if we have any skills in rows below this one
      if (currentRow < Math.floor((skillNodes.length - 1) / skillsPerRow)) {
        // Calculate how much space we need for these conditions
        const baseVerticalSpacing = 550; // Base vertical spacing between rows
        const additionalSpacingPerCondition = 100; // Extra space for each condition row
        
        // Get all skills in the current row
        const skillsInCurrentRow = skillNodes.filter((_, index) => 
          Math.floor(index / skillsPerRow) === currentRow
        );
        
        // Get the maximum number of condition rows in this row of skills
        const maxConditionRowsInCurrentRow = Math.max(
          ...skillsInCurrentRow.map(skillNode => {
            const conditionsForThisSkill = nodes.filter(n => 
              n.type === 'conditionNode' && 
              edges.some(e => e.source === skillNode.id && e.target === n.id)
            );
            return Math.ceil(conditionsForThisSkill.length / conditionsPerRow);
          })
        );
        
        console.log(`Maximum condition rows in row ${currentRow}: ${maxConditionRowsInCurrentRow}`);
        
        // Calculate required vertical spacing
        const requiredVerticalSpacing = baseVerticalSpacing + (maxConditionRowsInCurrentRow * additionalSpacingPerCondition);
        
        // Adjust all skills in rows below this one
        for (let row = currentRow + 1; row <= Math.floor((skillNodes.length - 1) / skillsPerRow); row++) {
          const firstSkillInPrevRow = skillNodes.find((_, index) => 
            Math.floor(index / skillsPerRow) === row - 1
          );
          
          const skillsInThisRow = skillNodes.filter((_, index) => 
            Math.floor(index / skillsPerRow) === row
          );
          
          if (firstSkillInPrevRow && skillsInThisRow.length > 0) {
            const baseY = firstSkillInPrevRow.position.y + requiredVerticalSpacing;
            
            console.log(`Adjusting skills in row ${row} to y position ${baseY}`);
            
            skillsInThisRow.forEach(skill => {
              const skillIndex = updatedNodes.findIndex(n => n.id === skill.id);
              if (skillIndex >= 0) {
                const newPos = {
                  x: updatedNodes[skillIndex].position.x,
                  y: baseY
                };
                
                // Only update if position has changed
                if (Math.abs(updatedNodes[skillIndex].position.y - newPos.y) > 5) {
                  console.log(`Moving skill ${skill.id} from y=${updatedNodes[skillIndex].position.y} to y=${newPos.y}`);
                  updatedNodes[skillIndex] = {
                    ...updatedNodes[skillIndex],
                    position: newPos
                  };
                  
                  // Also reposition any conditions for this skill
                  const conditionsForMovedSkill = nodes.filter(n => 
                    n.type === 'conditionNode' && 
                    edges.some(e => e.source === skill.id && e.target === n.id)
                  );
                  
                  if (conditionsForMovedSkill.length > 0) {
                    console.log(`Also need to reposition ${conditionsForMovedSkill.length} conditions for moved skill ${skill.id}`);
                    
                    const startX = newPos.x - (totalRowWidth / 2);
                    
                    conditionsForMovedSkill.forEach((condition, index) => {
                      const row = Math.floor(index / conditionsPerRow);
                      const col = index % conditionsPerRow;
                      
                      const xPos = startX + (col * (conditionWidth + horizontalGap));
                      const yPos = newPos.y + initialOffset + (row * (rowHeight + verticalGap));
                      
                      const conditionIndex = updatedNodes.findIndex(n => n.id === condition.id);
                      if (conditionIndex >= 0) {
                        updatedNodes[conditionIndex] = {
                          ...updatedNodes[conditionIndex],
                          position: { x: xPos, y: yPos }
                        };
                      }
                    });
                  }
                }
              }
            });
          }
        }
      }
    }
    
    // Update the nodes
    setNodes(updatedNodes);
  }, [nodes, edges, setNodes]);
  
  // Add condition node for a specific skill
  const addConditionNode = useCallback((skillNodeId: string) => {
    console.log("Adding condition to skill: ", skillNodeId);
    
    // Get the skill node from our node map
    const skillNode = nodeMapRef.current.get(skillNodeId);
    if (!skillNode) {
      console.error("Skill node not found in nodeMapRef:", skillNodeId);
      return;
    }
    
    console.log("Found skill node:", skillNode.id);

    // Get all existing conditions for this skill
    const existingConditions = nodes.filter(n => 
      n.type === 'conditionNode' && 
      edges.some(e => e.source === skillNodeId && e.target === n.id)
    );

    console.log("Existing conditions:", existingConditions.length);

    // Generate a unique ID for the new condition node
    const uniqueId = crypto.randomUUID();
    const nodeId = `condition-${uniqueId}`;
    
    console.log("Creating new condition node with ID:", nodeId);

    // Fixed layout properties
    const conditionWidth = 300;              // Width of a condition node
    const horizontalGap = 30;                // Gap between conditions horizontally
    const verticalGap = 30;                  // Gap between rows
    const conditionsPerRow = 2;              // Fixed number of conditions per row
    const rowHeight = 170;                   // Height of each row
    const initialOffset = 150;               // Initial vertical offset from skill

    // Calculate row and column based on the number of existing conditions
    const conditionIndex = existingConditions.length;
    const row = Math.floor(conditionIndex / conditionsPerRow);
    const col = conditionIndex % conditionsPerRow;
    
    // Calculate total width needed for the conditions in a row
    const totalRowWidth = (conditionsPerRow * conditionWidth) + ((conditionsPerRow - 1) * horizontalGap);
    
    // Center the conditions under the skill node
    const startX = skillNode.position.x - (totalRowWidth / 2);
    
    // Calculate x position: start at the left edge of the centered row and add width and gap for each column
    const xPos = startX + (col * (conditionWidth + horizontalGap));
    
    // Calculate y position: start below the skill and add row height for each row
    const yPos = skillNode.position.y + initialOffset + (row * (rowHeight + verticalGap));

    console.log(`Positioning condition at row ${row}, col ${col}: (${xPos}, ${yPos})`);

    // Create the new condition node
    const newNode = {
      id: nodeId,
      type: 'conditionNode',
      position: { x: xPos, y: yPos },
      data: {
        conditionTypes,
        selectedType: null,
        value: '',
        gameModes: {
          vsMode: false,
          harassMode: false,
          pkMode: false
        },
        onChange: (update) => {
          setNodes(prev => 
            prev.map(node => 
              node.id === nodeId 
                ? { ...node, data: { ...node.data, ...update } }
                : node
            )
          );
        },
        onRemove: () => {
          console.log("Removing condition node:", nodeId);
          // Find the connected skill before removing the node
          const connectedSkill = edges.find(edge => edge.target === nodeId)?.source;
          
          // Find the skill name for the notification
          let skillName = "unknown";
          if (connectedSkill) {
            const skillNode = nodes.find(n => n.id === connectedSkill);
            if (skillNode && skillNode.data.skill) {
              skillName = skillNode.data.skill.name;
            }
          }
          
          // Remove all edges connected to this node
          setEdges(prev => prev.filter(edge => edge.target !== nodeId && edge.source !== nodeId));
          // Remove the node itself
          setNodes(prev => prev.filter(node => node.id !== nodeId));
          
          // Send notification about removed condition
          sendNotification({
            title: '❌ Condition Removed',
            message: `A condition was removed from the ${skillName} skill.`,
            type: 'info'
          });
          
          // If we found the connected skill, reposition its remaining conditions
          if (connectedSkill) {
            setTimeout(() => {
              repositionConditionNodes(connectedSkill);
            }, 50);
          }
        }
      },
      dragHandle: '.drag-handle',
      connectable: true,
    };

    // Create edge from skill to condition
    const newEdge = {
      id: `edge-${uniqueId}`,
      source: skillNodeId,
      target: nodeId,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
      animated: true,
    };

    console.log("Adding new node and edge to flow");
    
    // Update the nodes and edges state
    setNodes(prev => [...prev, newNode]);
    setEdges(prev => [...prev, newEdge]);
    
    // Send notification about added condition
    sendNotification({
      title: '➕ Condition Added',
      message: `A new condition was added to the ${skillNode.data.skill.name} skill.`,
      type: 'info'
    });
    
    // Schedule repositioning of all conditions and downstream nodes
    setTimeout(() => {
      repositionConditionNodes(skillNodeId);
      
      // First scroll to the new node
      if (reactFlowInstanceRef.current) {
        reactFlowInstanceRef.current.fitView({
          nodes: [newNode],
          padding: 0.5,
          duration: 800
        });
      }
    }, 100);
  }, [nodes, edges, setNodes, setEdges, repositionConditionNodes, sendNotification]);
  
  // Initialize skill nodes
  const initializeSkillNodes = useCallback(async () => {
    console.log("Initializing skill nodes and loading saved rules");
    const initialNodes: Node[] = [];
    const initialEdges: Edge[] = [];
    
    // Clear the node map ref first
    nodeMapRef.current.clear();

    // Load existing rules if available
    const savedRule = await loadRules();
    
    // First, calculate how many conditions each skill has (if any saved rules exist)
    const conditionsCountBySkill = new Map<string, number>();
    
    if (savedRule && savedRule.conditions && savedRule.conditions.length > 0) {
      savedRule.conditions.forEach(condition => {
        if (condition.connectedToSkill) {
          const skillId = condition.connectedToSkill;
          conditionsCountBySkill.set(skillId, (conditionsCountBySkill.get(skillId) || 0) + 1);
        }
      });
    }
    
    // Create all skill nodes first in a grid layout
    const skillsPerRow = 5; // 5 skills per row
    const horizontalSpacing = 450; // Increased horizontal spacing between skills
    const baseVerticalSpacing = 550; // Base vertical spacing between rows
    const additionalSpacingPerCondition = 100; // Extra space for each condition row
    const conditionsPerRow = 2; // Number of conditions per row
    
    // Track cumulative vertical position by row to handle varying heights
    const rowVerticalPositions = [150]; // Start first row at 150px
    
    assassinClassAbilities.forEach((skill, index) => {
      // Create consistent ID format
      const skillName = skill.name.toLowerCase().replace(/\s+/g, '-');
      const nodeId = `skill-${skillName}`;
      
      // Calculate grid position
      const row = Math.floor(index / skillsPerRow);
      const col = index % skillsPerRow;
      
      // Ensure we have a position for this row
      if (rowVerticalPositions.length <= row) {
        // Calculate additional spacing for the previous row
        const prevRowMaxConditions = Math.max(
          ...Array.from({ length: skillsPerRow }, (_, i) => {
            const prevIndex = (row - 1) * skillsPerRow + i;
            if (prevIndex >= assassinClassAbilities.length) return 0;
            
            const prevSkillName = assassinClassAbilities[prevIndex].name.toLowerCase().replace(/\s+/g, '-');
            const prevSkillId = `skill-${prevSkillName}`;
            return Math.ceil((conditionsCountBySkill.get(prevSkillId) || 0) / conditionsPerRow);
          })
        );
        
        // Add position for the new row based on previous row + spacing
        const additionalSpacing = prevRowMaxConditions * additionalSpacingPerCondition;
        rowVerticalPositions.push(rowVerticalPositions[row - 1] + baseVerticalSpacing + additionalSpacing);
      }
      
      const xPos = 150 + (col * horizontalSpacing);
      const yPos = rowVerticalPositions[row];
      
      console.log(`Adding skill node: ${skill.name}, id: ${nodeId} at position (${xPos}, ${yPos})`);
      
      // Create node definition
      const newNode = {
        id: nodeId,
        type: 'skillNode',
        position: { x: xPos, y: yPos },
        data: { 
          skill,
          onAddCondition: () => {
            console.log(`onAddCondition called from skill: ${skill.name} with ID: ${nodeId}`);
            addConditionNode(nodeId);
          }
        },
        dragHandle: '.drag-handle',
        connectable: true,
      };
      
      // Store in node map for direct access
      nodeMapRef.current.set(nodeId, newNode);
      
      // Add to initialNodes array
      initialNodes.push(newNode);
    });
    
    // If we have saved rules, add the condition nodes and edges
    if (savedRule && savedRule.conditions && savedRule.conditions.length > 0) {
      console.log("Found saved rule:", savedRule.name);
      console.log("Conditions:", savedRule.conditions.length);
      
      // Group conditions by skill
      const conditionsBySkill = new Map();
      
      savedRule.conditions.forEach(condition => {
        if (!condition.type || !condition.connectedToSkill) return;
        
        if (!conditionsBySkill.has(condition.connectedToSkill)) {
          conditionsBySkill.set(condition.connectedToSkill, []);
        }
        
        conditionsBySkill.get(condition.connectedToSkill).push(condition);
      });
      
      // Add condition nodes
      conditionsBySkill.forEach((conditions, skillId) => {
        const skillNode = nodeMapRef.current.get(skillId);
        if (!skillNode) {
          console.warn("Could not find skill node for conditions:", conditions);
          return;
        }
        
        console.log(`Loading ${conditions.length} conditions for skill ${skillId}`);
        
        // Sort conditions by id to ensure consistent loading order
        conditions.sort((a, b) => a.id.localeCompare(b.id));
        
        // Fixed layout properties (same as in addConditionNode)
        const conditionWidth = 300;
        const horizontalGap = 30;
        const verticalGap = 30;
        const conditionsPerRow = 2;
        const rowHeight = 170;
        const initialOffset = 150;
        
        // Calculate total width needed for the conditions in a row
        const totalRowWidth = (conditionsPerRow * conditionWidth) + ((conditionsPerRow - 1) * horizontalGap);
        
        // Center the conditions under the skill node
        const startX = skillNode.position.x - (totalRowWidth / 2);
        
        // Add each condition for this skill
        conditions.forEach((condition, index) => {
          // Create a unique ID for the condition node
          const nodeId = condition.id || `condition-${crypto.randomUUID()}`;
          
          // Calculate row and column based on index
          const row = Math.floor(index / conditionsPerRow);
          const col = index % conditionsPerRow;
          
          // Calculate position
          const xPos = startX + (col * (conditionWidth + horizontalGap));
          const yPos = skillNode.position.y + initialOffset + (row * (rowHeight + verticalGap));
          
          console.log(`Placing condition ${nodeId} at index ${index}, row ${row}, col ${col}: (${xPos}, ${yPos})`);
          
          // Create the condition node
          const conditionNode = {
            id: nodeId,
            type: 'conditionNode',
            position: { x: xPos, y: yPos },
            data: {
              conditionTypes,
              selectedType: condition.type,
              value: condition.value || '',
              gameModes: condition.gameModes || { vsMode: false, harassMode: false, pkMode: false },
              onChange: (update) => {
                setNodes(prev => 
                  prev.map(node => 
                    node.id === nodeId 
                      ? { ...node, data: { ...node.data, ...update } }
                      : node
                  )
                );
              },
              onRemove: () => {
                console.log("Removing condition node:", nodeId);
                // Find the connected skill before removing the node
                const connectedSkill = edges.find(edge => edge.target === nodeId)?.source;
                
                // Find the skill name for the notification
                let skillName = "unknown";
                if (connectedSkill) {
                  const skillNode = nodes.find(n => n.id === connectedSkill);
                  if (skillNode && skillNode.data.skill) {
                    skillName = skillNode.data.skill.name;
                  }
                }
                
                // Remove all edges connected to this node
                setEdges(prev => prev.filter(edge => edge.target !== nodeId && edge.source !== nodeId));
                // Remove the node itself
                setNodes(prev => prev.filter(node => node.id !== nodeId));
                
                // Send notification about removed condition
                sendNotification({
                  title: '❌ Condition Removed',
                  message: `A condition was removed from the ${skillName} skill.`,
                  type: 'info'
                });
                
                // If we found the connected skill, reposition its remaining conditions
                if (connectedSkill) {
                  setTimeout(() => {
                    repositionConditionNodes(connectedSkill);
                  }, 50);
                }
              }
            },
            dragHandle: '.drag-handle',
            connectable: true,
          };
          
          // Add the condition node
          initialNodes.push(conditionNode);
          
          // Create edge from skill to condition
          const edgeId = `edge-${nodeId}`;
          const edge = {
            id: edgeId,
            source: condition.connectedToSkill,
            target: nodeId,
            type: 'smoothstep',
            markerEnd: { type: MarkerType.ArrowClosed },
            animated: true,
          };
          
          // Add the edge
          initialEdges.push(edge);
        });
      });
    }
    
    console.log("Initialized nodes:", initialNodes.map(n => n.id));
    console.log("Initialized edges:", initialEdges.map(e => e.id));
    console.log("NodeMapRef size:", nodeMapRef.current.size);
    
    // Set the nodes and edges state
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [addConditionNode, setNodes, setEdges, loadRules, sendNotification]);
  
  // Listen for nodes changes to update the node map
  useEffect(() => {
    if (nodes.length > 0) {
      nodes.forEach(node => {
        if (node.type === 'skillNode') {
          nodeMapRef.current.set(node.id, node);
        }
      });
    }
  }, [nodes]);
  
  // Use fitView when nodes are loaded or changed
  useEffect(() => {
    if (nodes.length > 0 && !isLoading) {
      // Increased timeout to ensure nodes are fully rendered
      const timer = setTimeout(() => {
        // Find all skill nodes with conditions and reposition their conditions
        const skillNodesWithConditions = new Set();
        
        // Find all conditions and their connected skills
        nodes.forEach(node => {
          if (node.type === 'conditionNode') {
            const connectedSkill = edges.find(edge => edge.target === node.id)?.source;
            if (connectedSkill) {
              skillNodesWithConditions.add(connectedSkill);
            }
          }
        });
        
        // Reposition conditions for each skill
        skillNodesWithConditions.forEach(skillId => {
          repositionConditionNodes(skillId as string);
        });
        
      }, 300); // Increased timeout
      
      return () => clearTimeout(timer);
    }
  }, [nodes, edges, isLoading, repositionConditionNodes]);
  
  useEffect(() => {
    // Initialize skills on first load
    if (!isInitialized) {
      console.log("First load, initializing skill nodes");
      setIsLoading(true);
      initializeSkillNodes().finally(() => {
        setIsInitialized(true);
        setIsLoading(false);
      });
    }
  }, [isInitialized, initializeSkillNodes]);

  // Handle edge connections
  const onConnect = useCallback((params: Connection) => {
    setEdges(prev => addEdge({
      ...params,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
      animated: true,
    }, prev));
  }, [setEdges]);

  // Save rules to database
  const saveRules = async () => {
    setIsSaving(true);
    try {
      const skillNodes = nodes.filter(node => node.type === 'skillNode');
      const conditionNodes = nodes.filter(node => node.type === 'conditionNode');
      
      const ruleData = {
        name: "User Skill Rules", // Fixed name for each user
        conditions: conditionNodes.map(node => ({
          id: node.id,
          type: node.data.selectedType,
          value: node.data.value,
          gameModes: node.data.gameModes || { vsMode: false, harassMode: false, pkMode: false },
          connectedToSkill: edges.find(edge => edge.target === node.id)?.source
        }))
      };

      console.log("Saving skill rules to database...");
      const resultId = await saveSkillRule(ruleData);
      console.log("Successfully saved/updated skill rule with ID:", resultId);

      // Send success notification
      await sendNotification({
        title: '✅ Skill Rules Saved',
        message: 'Your skill rules configuration has been saved successfully.',
        type: 'success'
      });

      toast.success("Skill rules saved successfully. Your existing configuration has been updated.");
    } catch (error) {
      console.error('Error saving rules:', error);
      
      // Send error notification
      await sendNotification({
        title: '❌ Skill Rules Error',
        message: 'There was an error saving your skill rules configuration.',
        type: 'error'
      });
      
      toast.error(typeof error === 'string' ? error : "Failed to save skill rules");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="glass-card">
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle className="flex items-center gap-2">
              <GitBranch className="h-5 w-5 text-gaming" />
              Skill Rules Configuration
            </CardTitle>
            <Button 
              onClick={saveRules}
              className="bg-gaming hover:bg-gaming/90"
              disabled={isSaving || isLoading}
            >
              {isSaving ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  Save Rules
                </>
              )}
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex items-start gap-2 text-sm">
              <AlertCircle className="h-5 w-5 text-gaming flex-shrink-0 mt-0.5" />
              <div className="space-y-2">
                <p className="text-muted-foreground">
                  Configure your skill rules by adding conditions to each skill. Click the + button next to a skill to add conditions.
                </p>
                <ul className="list-disc list-inside text-muted-foreground space-y-1">
                  <li><span className="text-gaming">Conditions:</span> Add conditions for when to use the skill</li>
                  <li><span className="text-gaming">Modes:</span> Specify game modes (VS, Harass, PK) for condition activation</li>
                </ul>
              </div>
            </div>

            <div className="h-[900px] rounded-lg border border-border/50 overflow-hidden relative">
              {isLoading && (
                <div className="absolute inset-0 bg-background/80 flex items-center justify-center z-50">
                  <div className="flex flex-col items-center gap-3">
                    <Loader2 className="h-8 w-8 text-gaming animate-spin" />
                    <p className="text-sm text-gaming font-medium">Loading saved rules...</p>
                  </div>
                </div>
              )}
              
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                onInit={(instance) => {
                  reactFlowInstanceRef.current = instance;
                }}
                fitView
                fitViewOptions={{ 
                  padding: 0.5,
                  includeHiddenNodes: false,
                  maxZoom: 0.9
                }}
                snapToGrid
                snapGrid={[20, 20]}
                defaultViewport={{ x: 0, y: 0, zoom: 0.6 }}
                minZoom={0.3}
                maxZoom={1.5}
                attributionPosition="bottom-right"
                style={{ background: 'var(--background)' }}
                nodesConnectable={false}
                nodesDraggable={true}
                elementsSelectable={true}
                panOnScroll={true}
                zoomOnScroll={true}
                panOnDrag={true}
                selectionOnDrag={false}
                zoomOnDoubleClick={true}
                preventScrolling={false}
              >
                <Background gap={15} />
                <Controls />
              </ReactFlow>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}; 