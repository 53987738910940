import React, { useState } from 'react';
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';
import { cn } from '@/lib/utils';

interface DashboardLayoutProps {
  children: React.ReactNode;
  activePage: string;
  setActivePage: (page: string) => void;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  activePage,
  setActivePage
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <Sidebar 
          isOpen={sidebarOpen} 
          toggleSidebar={toggleSidebar}
          activePage={activePage}
          setActivePage={setActivePage}
        />
        
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="p-4 flex-1 overflow-y-auto">
            <DashboardHeader toggleSidebar={toggleSidebar} />
            <main className={cn(
              "w-full px-1 transition-all duration-300",
              sidebarOpen ? "lg:ml-64" : "ml-0"
            )}>
              {children}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
