import React from "react";
import DashboardOverview from "@/components/dashboard/DashboardOverview";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import { LineChart as LineChartIcon, AlertCircle } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { usePing } from "@/contexts/PingContext";
import { trackError } from "@/lib/errorTracking";
import { ErrorBoundary } from "@/components/ErrorBoundary";

const Dashboard: React.FC = () => {
  const { metricsData, lastUpdate } = usePing();

  const getAverageResponseTime = () => {
    try {
      if (metricsData.length === 0) return 0;
      const sum = metricsData.reduce((acc, point) => {
        if (typeof point.responseTime !== "number") {
          throw new Error("Invalid response time data");
        }
        return acc + point.responseTime;
      }, 0);
      return Math.round(sum / metricsData.length);
    } catch (error) {
      trackError({
        error,
        component: "Dashboard",
        context: {
          function: "getAverageResponseTime",
          metricsDataLength: metricsData.length.toString(),
        },
      });
      return 0;
    }
  };

  const getResponseTimeStatus = (
    avg: number
  ): { text: string; color: string } => {
    try {
      if (avg < 100) return { text: "Excellent", color: "text-green-500" };
      if (avg < 300) return { text: "Good", color: "text-yellow-500" };
      return { text: "Poor", color: "text-red-500" };
    } catch (error) {
      trackError({
        error,
        component: "Dashboard",
        context: {
          function: "getResponseTimeStatus",
          average: avg.toString(),
        },
      });
      return { text: "Unknown", color: "text-gray-500" };
    }
  };

  const avgResponseTime = getAverageResponseTime();
  const status = getResponseTimeStatus(avgResponseTime);

  return (
    <ErrorBoundary>
      <div className="space-y-6">
        <h1 className="text-2xl font-bold text-foreground">Dashboard</h1>

        <DashboardOverview />

        <Tabs defaultValue="metrics" className="w-full mt-6">
          <TabsList className="glass-card mb-4">
            <TabsTrigger
              value="metrics"
              className="data-[state=active]:bg-gaming/20"
            >
              <LineChartIcon className="h-4 w-4 mr-2" />
              Metrics
            </TabsTrigger>
          </TabsList>

          <TabsContent value="metrics" className="space-y-4">
            <Card className="glass-card p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">
                  Response Time Analysis
                </h3>
                <div className="flex items-center gap-2 text-sm">
                  <span className="text-muted-foreground">Average:</span>
                  <span className={status.color}>{avgResponseTime}ms</span>
                  <span className="text-muted-foreground">({status.text})</span>
                </div>
              </div>

              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={metricsData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#334155" />
                    <XAxis
                      dataKey="timestamp"
                      stroke="#94a3b8"
                      tick={{ fill: "#94a3b8" }}
                    />
                    <YAxis
                      stroke="#94a3b8"
                      tick={{ fill: "#94a3b8" }}
                      label={{
                        value: "Response Time (ms)",
                        angle: -90,
                        position: "insideLeft",
                        fill: "#94a3b8",
                      }}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "#1e293b",
                        border: "1px solid #334155",
                        borderRadius: "8px",
                      }}
                      labelStyle={{ color: "#94a3b8" }}
                      formatter={(value: number) => [
                        `${value}ms`,
                        "Response Time",
                      ]}
                    />
                    <Line
                      type="monotone"
                      dataKey="responseTime"
                      stroke="#22c55e"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>

              <div className="mt-6 space-y-4">
                <div className="flex items-start gap-2 text-sm">
                  <AlertCircle className="h-5 w-5 text-gaming flex-shrink-0 mt-0.5" />
                  <div className="space-y-2">
                    <h4 className="font-medium">What is Response Time?</h4>
                    <p className="text-muted-foreground">
                      Response time measures how quickly the system responds to
                      requests. Lower values indicate better performance:
                    </p>
                    <ul className="list-disc list-inside text-muted-foreground space-y-1 ml-4">
                      <li>
                        <span className="text-green-500">Below 100ms:</span>{" "}
                        Excellent - System is highly responsive
                      </li>
                      <li>
                        <span className="text-yellow-500">100-300ms:</span> Good
                        - Acceptable performance
                      </li>
                      <li>
                        <span className="text-red-500">Above 300ms:</span> Poor
                        - May indicate network or system issues
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="flex items-start gap-2 text-sm">
                  <AlertCircle className="h-5 w-5 text-gaming flex-shrink-0 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Monitoring Period</h4>
                    <p className="text-muted-foreground">
                      The graph shows the last 2.5 minutes of response time
                      data, updated every 5 seconds. This helps identify trends
                      and potential issues in real-time.
                    </p>
                  </div>
                </div>
              </div>
            </Card>

            <div className="text-xs text-muted-foreground text-right">
              Last updated: {lastUpdate.toLocaleString()}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </ErrorBoundary>
  );
};

export default Dashboard;
