import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { SkillBar } from './SkillBar';
import { SkillDetailsModal } from './SkillDetailsModal';
import { Ability } from '@/data/abilities/types';
import { UserSkillBars, createEmptySkillBars } from '@/types/skills';
import { assassinClassAbilities, assassinSearchAbilities, assassinMasterAbilities, assassinExtraAbilities } from '@/data/abilities/assassin';
import { Gamepad2, Trash2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import styles from './skills.module.css';
import { toast } from 'sonner';

interface SkillMetadata {
  skillName: string;
  barIndex: number;
  slotKey: string;
}

interface DraggableAbilityProps {
  ability: Ability;
}

const DraggableAbility: React.FC<DraggableAbilityProps> = ({ ability }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ability',
    item: { ability },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: ability.skillType !== 'passive',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        ref={ability.skillType === 'passive' ? null : drag}
        className={cn(
          "relative w-12 h-12 rounded-lg glass-card border border-border/50 transition-all duration-300",
          ability.skillType === 'passive' 
            ? "opacity-80 cursor-not-allowed backdrop-blur-sm border-dashed" 
            : "cursor-grab hover:shadow-[0_0_15px_rgba(123,97,255,0.3)] hover:border-gaming/50",
          isDragging && "opacity-50 scale-110 rotate-3 shadow-[0_0_20px_rgba(123,97,255,0.4)]",
          isDragging && styles.floating,
          "group hover:scale-105 hover:-translate-y-0.5"
        )}
        onClick={() => setIsModalOpen(true)}
      >
        {isDragging && (
          <div 
            style={{ backgroundColor: '#4F46E5', opacity: 1 }}
            className={cn(
              "absolute -top-8 left-1/2 -translate-x-1/2 text-white px-2 py-1 rounded text-xs font-medium whitespace-nowrap shadow-lg",
              styles['dragging-label']
            )}
          >
            Dragging ✨
          </div>
        )}

        <img
          src={`/images/skills/${ability.name.toLowerCase().replace(/\s+/g, '-')}.gif`}
          alt={ability.name}
          className={cn(
            "w-full h-full object-cover rounded-lg transition-all duration-300",
            isDragging && "scale-90 rotate-3",
            ability.skillType === 'passive' && "filter grayscale"
          )}
          onError={(e) => {
            e.currentTarget.src = '/placeholder.svg';
          }}
        />
        
        <div className="absolute inset-0 bg-black/60 rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 backdrop-blur-sm">
          <div className="p-2 text-xs text-white flex items-center justify-center h-full">
            <p className="font-medium text-center transform transition-all duration-300 group-hover:scale-110">{ability.name}</p>
          </div>
        </div>
      </div>

      <SkillDetailsModal
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        ability={ability}
      />
    </>
  );
};

const TrashZone: React.FC<{ onRemove: (slotId: string) => void }> = ({ onRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['assigned-ability'],
    drop: (item: { slotId?: string }) => {
      if (item.slotId) {
        onRemove(item.slotId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={cn(
        "mt-6 h-24 rounded-lg border-2 border-dashed transition-all duration-300 flex items-center justify-center",
        isOver 
          ? "border-red-500 bg-red-500/10 scale-105 shadow-[0_0_20px_rgba(239,68,68,0.2)]" 
          : "border-border hover:border-gaming/50 hover:bg-gaming/5"
      )}
    >
      <div className="flex flex-col items-center gap-2 text-muted-foreground">
        <Trash2 className={cn(
          "w-8 h-8 transition-all duration-300",
          isOver ? "text-red-500 scale-110" : "text-muted-foreground"
        )} />
        <span className="text-sm font-medium">Drop here to remove skill</span>
      </div>
    </div>
  );
};

export const Skills: React.FC = () => {
  const [skillBars, setSkillBars] = useState<UserSkillBars>(() => createEmptySkillBars());
  const [activeSkills, setActiveSkills] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  // Notification gönderme yardımcı fonksiyonu
  const sendNotification = async (notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    if (!user) return;

    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: user.id,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Realtime kanalına manuel olarak bildirim gönder
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Sunucuya gönder
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: user.id,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Load active skills and skill metadata
  const loadData = async () => {
    if (!user) return;

    try {
      setLoading(true);

      // Fetch user_metadata for active skills
      const { data: userData, error: userError } = await supabase
        .from('user_metadata')
        .select('active_skills')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      const activeSkillsList = userData?.active_skills || [];
      setActiveSkills(activeSkillsList);

      // Fetch skill_metadata
      const { data, error } = await supabase
        .from('skill_metadata')
        .select('metadata')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) {
        // No special handling for PGRST116 (no rows found) needed with maybeSingle
        throw error;
      }
      
      // Create new record if none exists
      if (!data) {
        const { error: insertError } = await supabase
          .from('skill_metadata')
          .insert({ user_id: user.id, metadata: [] });
        
        if (insertError) throw insertError;
        return;
      }

      if (data?.metadata) {
        // Clean up skill_metadata by removing inactive skills
        const cleanedMetadata = (data.metadata as SkillMetadata[]).filter(
          meta => activeSkillsList.includes(meta.skillName)
        );

        // If there are skills to remove, update the database
        if (cleanedMetadata.length !== (data.metadata as SkillMetadata[]).length) {
          const { error: updateError } = await supabase
            .from('skill_metadata')
            .update({ metadata: cleanedMetadata })
            .eq('user_id', user.id);

          if (updateError) throw updateError;
        }

        const newSkillBars = createEmptySkillBars();
        const allAbilities = [...assassinClassAbilities, ...assassinSearchAbilities, 
                            ...assassinMasterAbilities, ...assassinExtraAbilities];

        // Apply cleaned metadata to skill bars - barIndex'i 0'dan başlatarak kullan
        cleanedMetadata.forEach(meta => {
          const ability = allAbilities.find(a => a.name === meta.skillName);
          if (ability) {
            const bar = newSkillBars.bars[meta.barIndex - 1]; // barIndex'i 0'a çevir
            if (bar) {
              const slot = bar.slots.find(s => s.key === meta.slotKey);
              if (slot) {
                slot.ability = ability;
              }
            }
          }
        });
        setSkillBars(newSkillBars);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Failed to load skill configuration');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [user]);

  // Get available abilities based on active skills and category
  const getAvailableAbilitiesByCategory = (abilities: Ability[]) => {
    return abilities.filter(ability => activeSkills.includes(ability.name));
  };

  // Hangi slotların kısıtlı olduğunu belirle (Health Potion ve Mana Potion için)
  const getRestrictedSlots = () => {
    const restrictedSlots: {[key: string]: string} = {}; // slot index -> yetenek adı
    
    // Slot kısıtlamalı yetenekler
    const slotRestrictedSkills = ["Health Potion", "Mana Potion", "Light Feet", "Minor Healing"];
    
    skillBars.bars.forEach((bar, barIndex) => {
      bar.slots.forEach((slot, slotIndex) => {
        if (slot.ability && slotRestrictedSkills.includes(slot.ability.name)) {
          // "0-2" formatında (bar index-slot index)
          restrictedSlots[`${slotIndex}`] = slot.ability.name;
        }
      });
    });
    
    return restrictedSlots;
  };

  // Skill metadatayı veritabanına kaydet
  const saveSkillMetadata = async (bars: UserSkillBars) => {
    if (!user) return;

    try {
      // Mevcut skill konfigürasyonunu metadata formatına dönüştür
      const metadata: SkillMetadata[] = [];
      bars.bars.forEach((bar, barIndex) => {
        bar.slots.forEach(slot => {
          if (slot.ability) {
            metadata.push({
              skillName: slot.ability.name,
              barIndex: barIndex + 1, // barIndex'i 1'den başlat
              slotKey: slot.key
            });
          }
        });
      });

      // Önce kaydın var olup olmadığını kontrol et
      const { data: existingData, error: fetchError } = await supabase
        .from('skill_metadata')
        .select('*')
        .eq('user_id', user.id)
        .maybeSingle();
      
      if (fetchError) throw fetchError;
      
      let error;
      
      if (!existingData) {
        // Kayıt yoksa insert işlemi yap
        const { error: insertError } = await supabase
          .from('skill_metadata')
          .insert({ 
            user_id: user.id, 
            metadata: metadata || [] // Boş bir array olmasını sağla
          });
        error = insertError;
      } else {
        // Kayıt varsa update işlemi yap
        const { error: updateError } = await supabase
          .from('skill_metadata')
          .update({ 
            metadata: metadata || [] // Boş bir array olmasını sağla
          })
          .eq('user_id', user.id);
        error = updateError;
      }

      if (error) throw error;
    } catch (error) {
      console.error('Error saving skill metadata:', error);
      toast.error('Failed to save skill configuration');
    }
  };

  const handleSlotUpdate = async (barIndex: number, slotId: string, ability: Ability | null, sourceSlotId?: string) => {
    try {
      setLoading(true);
      const newBars = JSON.parse(JSON.stringify(skillBars));

      // Check if skill is already assigned somewhere else (except source slot)
      if (ability) {
        let isSkillAssigned = false;
        
        // Özel yetenekler için listeler
        const multiUseSkills = ["Minor Healing"];
        const isMultiUseSkill = multiUseSkills.includes(ability.name);
        
        // Slot kısıtlamalı yetenekler - bu yetenekler yerleştirildiğinde diğer barlardaki aynı slot engellenecek
        const slotRestrictedSkills = ["Health Potion", "Mana Potion", "Light Feet", "Minor Healing"];
        const isSlotRestrictedSkill = slotRestrictedSkills.includes(ability.name);
        
        // Multi-use yetenekler için kullanım sayısı kontrolü
        let currentUsageCount = 0;
        if (isMultiUseSkill) {
          // Aynı bar'da kaç kez kullanıldığını say
          newBars.bars[barIndex].slots.forEach(slot => {
            if (slot.id !== slotId && // Hedef slot'u hariç tut
                slot.ability && 
                slot.ability.name === ability.name) {
              currentUsageCount++;
            }
          });
          
          // Eğer sınırı aştıysa engelle
          if (currentUsageCount >= 2) {
            toast.error("Cannot assign skill", {
              description: `${ability.name} can only be used maximum 2 times in the same skill bar`
            });
            setLoading(false);
            return;
          }
        }
        
        // Eğer slot kısıtlamalı bir yetenek ise, diğer barlarda aynı slotun kullanılıp kullanılmadığını kontrol et
        if (isSlotRestrictedSkill) {
          // Hedef slotun numarasını al
          const targetSlotNumber = newBars.bars[barIndex].slots.findIndex(s => s.id === slotId);
          
          // Diğer barlarda aynı slot numarası kullanılıyor mu diye kontrol et
          for (let i = 0; i < newBars.bars.length; i++) {
            if (i !== barIndex && targetSlotNumber >= 0) { // Farklı bar ise ve slot numarası geçerliyse
              const sameSlotInOtherBar = newBars.bars[i].slots[targetSlotNumber];
              if (sameSlotInOtherBar && sameSlotInOtherBar.ability) {
                isSkillAssigned = true;
                toast.error("Cannot assign skill", {
                  description: `${ability.name} cannot be placed here because the same slot in another bar is already in use`
                });
                setLoading(false);
                return;
              }
            }
          }
        }
        
        // Normal yetenekler için standart çakışma kontrolü (aynı yetenek başka bir slotta kullanılıyor mu?)
        newBars.bars.forEach((bar, idx) => {
          bar.slots.forEach(slot => {
            if (slot.id !== sourceSlotId && 
                slot.ability && 
                slot.ability.name === ability.name) {
              // Eğer multi-use bir yetenek ise ve aynı bar içinde ise izin ver
              if (isMultiUseSkill && idx === barIndex) {
                // Kullanım sınırını yukarıda kontrol ettik, burada bir şey yapmaya gerek yok
              } else {
                // Multi-use değilse veya farklı bar'da ise izin verme
                isSkillAssigned = true;
              }
            }
          });
        });

        if (isSkillAssigned) {
          toast.error("Cannot assign skill", {
            description: `${ability.name} is already assigned to another slot`
          });
          setLoading(false);
          return;
        }
      }

      // Önceki yeteneği sakla (notification için)
      let previousAbility = null;
      newBars.bars[barIndex].slots.forEach(slot => {
        if (slot.id === slotId) {
          previousAbility = slot.ability;
        }
      });

      // If this is a transfer between slots, clear source slot first
      if (sourceSlotId) {
        for (const bar of newBars.bars) {
          const sourceSlot = bar.slots.find(s => s.id === sourceSlotId);
          if (sourceSlot) {
            sourceSlot.ability = null;
            break;
          }
        }
      }

      // Update target slot
      const targetSlot = newBars.bars[barIndex].slots.find(s => s.id === slotId);
      if (targetSlot) {
        targetSlot.ability = ability;
      }

      // Save to database
      await saveSkillMetadata(newBars);
      
      // Reload data from database
      await loadData();

      // Notification gönder
      if (sourceSlotId && ability) {
        // Yetenek taşındı
        await sendNotification({
          title: '🔄 Skill Moved',
          message: `${ability.name} has been moved to a new position`,
          type: 'info'
        });
      } else if (ability) {
        // Yeni yetenek eklendi
        await sendNotification({
          title: '🎯 Skill Added',
          message: `${ability.name} has been added to your skill bar`,
          type: 'success'
        });
      } else if (previousAbility) {
        // Yetenek kaldırıldı
        await sendNotification({
          title: '⚔️ Skill Removed',
          message: `${previousAbility.name} has been removed from your skill bar`,
          type: 'info'
        });
      }

      toast.success(sourceSlotId && ability
        ? `${ability.name} has been moved`
        : ability 
          ? `${ability.name} has been added`
          : `${previousAbility?.name || 'Skill'} has been removed`);
    } catch (error) {
      console.error('Error updating skill:', error);
      toast.error('Failed to update skill configuration');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveSkill = async (slotId: string) => {
    // Find which bar contains the slot
    let barIndex = -1;
    skillBars.bars.forEach((bar, idx) => {
      if (bar.slots.some(slot => slot.id === slotId)) {
        barIndex = idx;
      }
    });

    if (barIndex !== -1) {
      await handleSlotUpdate(barIndex, slotId, null);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-background/80 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="relative">
            <div className="h-16 w-16 border-4 border-gaming border-t-transparent rounded-full animate-spin mx-auto" />
            <div className="absolute inset-0 border-4 border-gaming/20 rounded-full animate-pulse" />
          </div>
          <p className="text-muted-foreground animate-pulse">Updating skill configuration...</p>
        </div>
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold">Skill Configuration</h1>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Skill Bars Section */}
          <Card className="lg:col-span-2 glass-card transition-all duration-300 hover:shadow-[0_0_30px_rgba(123,97,255,0.1)]">
            <CardHeader>
              <CardTitle>Skill Bars</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-6 gap-6">
                {skillBars.bars.map((bar, index) => (
                  <SkillBar
                    key={index}
                    bar={bar}
                    index={index}
                    onSlotUpdate={(slotId, ability, sourceSlotId) => 
                      handleSlotUpdate(index, slotId, ability, sourceSlotId)}
                    isLoading={loading}
                    restrictedSlots={getRestrictedSlots()}
                    currentBarIndex={index}
                  />
                ))}
              </div>
              
              <TrashZone onRemove={handleRemoveSkill} />
            </CardContent>
          </Card>

          {/* Available Skills Section */}
          <Card className="glass-card transition-all duration-300 hover:shadow-[0_0_30px_rgba(123,97,255,0.1)]">
            <CardHeader className="pb-2">
              <CardTitle>Available Skills</CardTitle>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[600px]">
                <div className="space-y-6">
                  {/* Class Skills */}
                  <div className="space-y-2 p-3 rounded-lg transition-all duration-300 hover:bg-black/5">
                    <h3 className="text-sm font-semibold text-gaming mb-3 uppercase tracking-wide">Class Skills</h3>
                    <div className="flex flex-wrap gap-1.5">
                      {getAvailableAbilitiesByCategory(assassinClassAbilities).map((ability) => (
                        <DraggableAbility key={ability.name} ability={ability} />
                      ))}
                    </div>
                  </div>

                  {/* Search Skills */}
                  <div className="space-y-2 p-3 rounded-lg transition-all duration-300 hover:bg-black/5">
                    <h3 className="text-sm font-semibold text-gaming mb-3 uppercase tracking-wide">Search Skills</h3>
                    <div className="flex flex-wrap gap-1.5">
                      {getAvailableAbilitiesByCategory(assassinSearchAbilities).map((ability) => (
                        <DraggableAbility key={ability.name} ability={ability} />
                      ))}
                    </div>
                  </div>

                  {/* Master Skills */}
                  <div className="space-y-2 p-3 rounded-lg transition-all duration-300 hover:bg-black/5">
                    <h3 className="text-sm font-semibold text-gaming mb-3 uppercase tracking-wide">Master Skills</h3>
                    <div className="flex flex-wrap gap-1.5">
                      {getAvailableAbilitiesByCategory(assassinMasterAbilities).map((ability) => (
                        <DraggableAbility key={ability.name} ability={ability} />
                      ))}
                    </div>
                  </div>

                  {/* Extra Skills */}
                  <div className="space-y-2 p-3 rounded-lg transition-all duration-300 hover:bg-black/5">
                    <h3 className="text-sm font-semibold text-gaming mb-3 uppercase tracking-wide">Extra Skills</h3>
                    <div className="flex flex-wrap gap-1.5">
                      {getAvailableAbilitiesByCategory(assassinExtraAbilities).map((ability) => (
                        <DraggableAbility key={ability.name} ability={ability} />
                      ))}
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </DndProvider>
  );
}; 