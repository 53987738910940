import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Gauge, Activity, Clock, RotateCw } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { supabase } from "@/lib/supabase";
import { systemStatsService } from "@/lib/services/systemStats";
import CountUp from "react-countup";
import { usePing } from "@/contexts/PingContext";
import { trackError } from "@/lib/errorTracking";
import { ErrorBoundary } from "@/components/ErrorBoundary";

interface SystemStats {
  last_start_time: string;
  total_uptime: number;
  is_online: boolean;
  last_heartbeat: string;
}

const DashboardOverview: React.FC = () => {
  const [stats, setStats] = useState<SystemStats | null>(null);
  const [uptime, setUptime] = useState(0);
  const [runtimeSeconds, setRuntimeSeconds] = useState(0);
  const startTimeRef = useRef<number | null>(null);
  const totalUptimeRef = useRef<number>(0);
  const { pingTime, previousPing, isRefreshing, checkPing } = usePing();

  const fetchStats = async () => {
    try {
      const newStats = await systemStatsService.getStats();
      if (newStats) {
        setStats(newStats);
        setUptime(systemStatsService.calculateUptime(newStats.last_heartbeat));

        // İlk kez yükleniyorsa başlangıç zamanını ayarla
        if (startTimeRef.current === null) {
          startTimeRef.current = new Date(newStats.last_start_time).getTime();
          totalUptimeRef.current = newStats.total_uptime;
        }
      }
    } catch (error) {
      trackError({
        error,
        component: "DashboardOverview",
        context: {
          function: "fetchStats",
          isOnline: stats?.is_online?.toString() || "unknown",
        },
      });
      console.error("Failed to fetch stats:", error);
    }
  };

  // Her saniye runtime'ı güncelle
  useEffect(() => {
    const updateRuntime = () => {
      try {
        if (startTimeRef.current === null) return;

        const now = Date.now();
        const elapsedSeconds = Math.floor((now - startTimeRef.current) / 1000);
        const newRuntimeSeconds = elapsedSeconds + totalUptimeRef.current;

        setRuntimeSeconds(newRuntimeSeconds);
      } catch (error) {
        trackError({
          error,
          component: "DashboardOverview",
          context: {
            function: "updateRuntime",
            startTime: startTimeRef.current?.toString() || "null",
            totalUptime: totalUptimeRef.current.toString(),
          },
        });
      }
    };

    // İlk güncellemeyi hemen yap
    updateRuntime();

    // Her saniye güncelle
    const runtimeInterval = setInterval(updateRuntime, 1000);
    return () => clearInterval(runtimeInterval);
  }, []);

  // Stats'ı her 5 saniyede bir güncelle
  useEffect(() => {
    fetchStats();
    const statsInterval = setInterval(fetchStats, 5000);
    return () => clearInterval(statsInterval);
  }, []);

  const getResponseTimeStatus = (
    time: number | null
  ): { color: string; text: string } => {
    try {
      if (time === null) return { color: "text-destructive", text: "Error" };
      if (time < 100) return { color: "text-green-500", text: "Excellent" };
      if (time < 300) return { color: "text-yellow-500", text: "Good" };
      return { color: "text-orange-500", text: "Poor" };
    } catch (error) {
      trackError({
        error,
        component: "DashboardOverview",
        context: {
          function: "getResponseTimeStatus",
          time: time?.toString() || "null",
        },
      });
      return { color: "text-destructive", text: "Error" };
    }
  };

  return (
    <ErrorBoundary>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card className="glass-card">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-muted-foreground">
              System Status
            </CardTitle>
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 hover:bg-primary/20"
                onClick={fetchStats}
                disabled={isRefreshing}
              >
                <RotateCw
                  className={cn(
                    "h-4 w-4 text-muted-foreground",
                    isRefreshing && "animate-spin"
                  )}
                />
              </Button>
              <div
                className={`h-4 w-4 rounded-full ${
                  stats?.is_online ? "bg-green-500" : "bg-red-500"
                }`}
              />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {stats?.is_online ? "Online" : "Offline"}
            </div>
            <p className="text-xs text-muted-foreground mt-1">
              Current system status
            </p>
          </CardContent>
        </Card>

        <Card className="glass-card">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-muted-foreground">
              Uptime
            </CardTitle>
            <Activity className="h-4 w-4 text-gaming" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{uptime}%</div>
            <p className="text-xs text-muted-foreground mt-1">
              System availability
            </p>
          </CardContent>
        </Card>

        <Card className="glass-card">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-muted-foreground">
              Runtime
            </CardTitle>
            <Clock className="h-4 w-4 text-gaming" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              <CountUp
                key={runtimeSeconds}
                start={runtimeSeconds - 1}
                end={runtimeSeconds}
                duration={1}
                separator=","
                decimals={0}
                decimal="."
                useEasing={true}
                preserveValue={true}
                formattingFn={(value) => {
                  const hours = Math.floor(value / 3600);
                  const minutes = Math.floor((value % 3600) / 60);
                  const seconds = value % 60;
                  return [
                    hours.toString().padStart(2, "0"),
                    minutes.toString().padStart(2, "0"),
                    seconds.toString().padStart(2, "0"),
                  ].join(":");
                }}
              />
            </div>
            <p className="text-xs text-muted-foreground mt-1">
              Total system runtime
            </p>
          </CardContent>
        </Card>

        <Card className="glass-card">
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <CardTitle className="text-sm font-medium text-muted-foreground">
              Response Time
            </CardTitle>
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 hover:bg-primary/20"
                onClick={checkPing}
                disabled={isRefreshing}
              >
                <RotateCw
                  className={cn(
                    "h-4 w-4 text-muted-foreground",
                    isRefreshing && "animate-spin"
                  )}
                />
              </Button>
              <Gauge className="h-4 w-4 text-gaming" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-2">
              <span className={getResponseTimeStatus(pingTime).color}>
                {pingTime === null ? (
                  "Error"
                ) : (
                  <div className="text-2xl font-bold">
                    <CountUp
                      key={pingTime}
                      start={previousPing}
                      end={pingTime}
                      duration={1}
                      separator=","
                      decimals={0}
                      decimal="."
                      useEasing={true}
                      preserveValue={true}
                    />
                    <span>ms</span>
                  </div>
                )}
              </span>
              <span className="text-sm font-normal text-muted-foreground">
                ({getResponseTimeStatus(pingTime).text})
              </span>
            </div>
            <p className="text-xs text-muted-foreground mt-1">
              Connection latency
            </p>
          </CardContent>
        </Card>
      </div>
    </ErrorBoundary>
  );
};

export default DashboardOverview;
