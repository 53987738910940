import { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { AuthError } from '@supabase/supabase-js'
import { Crown, LogIn, Mail, Lock, AlertCircle } from 'lucide-react'
import AnimatedLogo from '@/components/ui/AnimatedLogo'

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { signIn } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')
    try {
      setIsLoading(true)
      await signIn(email, password)
      navigate('/')
    } catch (error) {
      console.error('Error signing in:', error)
      if (error instanceof AuthError) {
        switch (error.message) {
          case 'Email not confirmed':
            setError('Please check your email and click the confirmation link to verify your account.')
            break
          case 'Invalid login credentials':
            setError('Invalid email or password')
            break
          default:
            setError(error.message || 'Failed to sign in. Please try again.')
        }
      } else {
        setError('An unexpected error occurred. Please try again.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="min-h-screen bg-[#0F172A] flex items-center justify-center p-4 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden animate-fade-in">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-gaming/20 rounded-full blur-3xl animate-pulse-glow"></div>
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-gaming/20 rounded-full blur-3xl animate-pulse-glow"></div>
      </div>

      <div className="w-full max-w-md space-y-8 relative animate-slide-up">
        {/* Logo and Title Section */}
        <div className="text-center space-y-4 animate-fade-in">
          <AnimatedLogo size="lg" className="mx-auto" showTagline={false} />
          <p className="text-gray-400">Sign in to continue your journey</p>
        </div>

        {/* Main Card */}
        <div className="glass-card backdrop-blur-sm bg-background/50 p-8 rounded-2xl border border-border/50 shadow-xl space-y-6 animate-fade-in delay-150">
          {error && (
            <div className="p-4 rounded-lg bg-destructive/10 border border-destructive/30 text-destructive flex items-start gap-3 animate-slide-up">
              <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
              <div className="space-y-1">
                <p className="font-medium">Authentication Error</p>
                <p className="text-sm opacity-90">{error}</p>
                {error.includes('confirmation link') && (
                  <button
                    onClick={() => navigate('/register')}
                    className="text-sm text-gaming hover:text-gaming/90 underline underline-offset-4 mt-2"
                  >
                    Try registering again
                  </button>
                )}
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm font-medium text-foreground/90 flex items-center gap-2">
                <Mail className="h-4 w-4 text-muted-foreground" />
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-background/50 border border-border/50 backdrop-blur-sm
                         text-foreground placeholder:text-muted-foreground focus:outline-none focus:ring-2 
                         focus:ring-gaming/50 focus:border-gaming/50 transition-all duration-200"
                required
                placeholder="Enter your email"
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="password" className="text-sm font-medium text-foreground/90 flex items-center gap-2">
                <Lock className="h-4 w-4 text-muted-foreground" />
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-background/50 border border-border/50 backdrop-blur-sm
                         text-foreground placeholder:text-muted-foreground focus:outline-none focus:ring-2 
                         focus:ring-gaming/50 focus:border-gaming/50 transition-all duration-200"
                required
                placeholder="Enter your password"
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="relative w-full px-4 py-3 mt-6 rounded-lg bg-background/80 
                       border border-gaming/50 text-foreground font-medium 
                       flex items-center justify-center gap-2 overflow-hidden 
                       transition-all duration-300 hover:scale-[1.02] group"
            >
              {/* Shimmer effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-gaming/0 via-gaming/20 to-gaming/0
                            translate-x-[-100%] group-hover:translate-x-[100%] 
                            transition-transform duration-1000" />
              
              {/* Subtle glow effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute inset-[-2px] bg-gradient-to-r from-gaming/40 to-gaming/40
                              rounded-lg opacity-20 blur-sm" />
              </div>

              {/* Button content */}
              <div className="relative z-10 flex items-center gap-2">
                {isLoading ? (
                  <>
                    <div className="h-5 w-5 border-2 border-gaming border-t-transparent rounded-full animate-spin" />
                    <span>Signing in...</span>
                  </>
                ) : (
                  <>
                    <LogIn className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
                    <span>Sign In</span>
                  </>
                )}
              </div>
            </button>
          </form>
        </div>

        <p className="text-center text-sm text-muted-foreground animate-fade-in delay-300">
          Don't have an account?{' '}
          <Link 
            to="/register" 
            className="font-medium text-gaming hover:text-gaming/90 transition-colors underline underline-offset-4"
          >
            Create Account
          </Link>
        </p>
      </div>
    </div>
  )
} 