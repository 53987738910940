import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Badge } from '@/components/ui/badge';
import { Ability } from '@/data/abilities/types';
import { Zap, Clock, Dumbbell, Sword, Play } from 'lucide-react';
import { cn } from '@/lib/utils';

interface SkillDetailsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  ability: Ability | null;
}

export function SkillDetailsModal({ open, onOpenChange, ability }: SkillDetailsModalProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  
  if (!ability) return null;

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setIsPlaying(false);
    }
    onOpenChange(newOpen);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[500px] glass-card">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-2xl">
            <Zap className="h-6 w-6 text-gaming" />
            Skill Details
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Skill Header */}
          <div className="flex items-start gap-4 p-4 glass rounded-lg bg-primary/5">
            <div className="h-16 w-16 rounded-lg overflow-hidden">
              <img
                src={`/images/skills/${ability.name.toLowerCase().replace(/\s+/g, '-')}.gif`}
                alt={ability.name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.currentTarget.src = '/placeholder.svg';
                }}
              />
            </div>
            <div className="space-y-1 flex-1">
              <div className="flex items-center justify-between">
                <h3 className="font-medium text-lg">{ability.name}</h3>
                <Badge
                  variant="outline"
                  className={cn(
                    "text-xs",
                    ability.skillType === 'passive' ? "border-yellow-500 text-yellow-500" : "border-gaming text-gaming"
                  )}
                >
                  {ability.skillType}
                </Badge>
              </div>
              <p className="text-sm text-muted-foreground">{ability.description}</p>
            </div>
          </div>

          {/* Skill Animation Preview */}
          <div className="relative aspect-video w-full glass rounded-lg overflow-hidden bg-black/20">
            {ability.name === 'Jab' ? (
              <div className="relative w-full h-full">
                <img
                  src="/images/skills/animations/Jab.gif"
                  alt={`${ability.name} animation`}
                  className={cn(
                    "w-full h-full object-contain transition-all duration-300",
                    !isPlaying && "blur-sm grayscale"
                  )}
                  style={{ 
                    opacity: isPlaying ? 1 : 0.7,
                    animationPlayState: isPlaying ? 'running' : 'paused'
                  }}
                />
                {!isPlaying && (
                  <button
                    onClick={() => setIsPlaying(true)}
                    className="absolute inset-0 flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors"
                  >
                    <Play className="w-12 h-12 text-white" />
                  </button>
                )}
              </div>
            ) : (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-center space-y-2">
                  <div className="w-12 h-12 rounded-full border-2 border-gaming/50 border-dashed animate-[spin_3s_linear_infinite] mx-auto" />
                  <p className="text-sm text-muted-foreground">Animation preview coming soon</p>
                </div>
              </div>
            )}
          </div>

          {/* Skill Stats */}
          <div className="grid grid-cols-2 gap-4">
            <div className="glass p-4 rounded-lg space-y-1">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Clock className="h-4 w-4" />
                <span>Cooldown</span>
              </div>
              <p className="text-lg font-semibold">{ability.cooldown}s</p>
            </div>
            <div className="glass p-4 rounded-lg space-y-1">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Zap className="h-4 w-4" />
                <span>MP Cost</span>
              </div>
              <p className="text-lg font-semibold">{ability.mpCost}</p>
            </div>
          </div>

          {/* Additional Details */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Dumbbell className="h-4 w-4 text-gaming" />
              <span className="text-sm">Required Level: {ability.requiredLevel}</span>
            </div>
            {ability.requiredWeapon && (
              <div className="flex items-center gap-2">
                <Sword className="h-4 w-4 text-gaming" />
                <span className="text-sm">Required Weapon: {ability.requiredWeapon}</span>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 