import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { AlertCircle, Shield, Sword, AlertTriangle, Check, X } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { assassinClassAbilities } from '@/data/abilities/assassin';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';

interface ConditionNodeProps {
  data: {
    conditionTypes: Array<{
      id: string;
      label: string;
      icon: React.ReactNode;
      description: string;
      valueType: string;
      arrowIcon?: React.ReactNode;
    }>;
    selectedType: string | null;
    value: string;
    gameModes?: {
      vsMode: boolean;
      harassMode: boolean;
      pkMode: boolean;
    };
    onChange?: (update: Partial<{
      selectedType: string;
      value: string;
      gameModes: {
        vsMode: boolean;
        harassMode: boolean;
        pkMode: boolean;
      };
    }>) => void;
    onRemove?: () => void;
  };
  isConnectable: boolean;
}

export const ConditionNode: React.FC<ConditionNodeProps> = memo(({ data, isConnectable }) => {
  const selectedCondition = data.selectedType ? data.conditionTypes.find(type => type.id === data.selectedType) : null;
  
  // Initialize game modes with default values if not provided
  const gameModes = data.gameModes || { vsMode: false, harassMode: false, pkMode: false };
  
  // Handle game mode changes
  const handleGameModeChange = (mode: 'vsMode' | 'harassMode' | 'pkMode', checked: boolean) => {
    if (data.onChange) {
      data.onChange({
        gameModes: {
          ...gameModes,
          [mode]: checked
        }
      });
    }
  };

  // Handle remove button click
  const handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (data.onRemove) {
      data.onRemove();
    }
  };

  return (
    <div className="group">
      <Handle
        type="target"
        position={Position.Left}
        className="!bg-gaming !border-gaming/50 w-3 h-3"
        isConnectable={isConnectable}
      />
      <Card className="w-[300px] border-gaming/30 bg-gaming/5 group-hover:border-gaming/50 transition-all duration-200">
        <div className="p-3 drag-handle cursor-move">
          <div className="flex items-center gap-2 mb-3">
            <AlertCircle className="h-5 w-5 text-gaming" />
            <h3 className="font-medium text-sm">Condition</h3>
            <Button 
              variant="ghost" 
              size="icon" 
              className="ml-auto h-6 w-6 text-red-500 hover:bg-red-500/10 hover:text-red-600"
              onClick={handleRemove}
              title="Remove condition"
            >
              <X className="h-4 w-4" />
            </Button>
            {selectedCondition && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <AlertCircle className="h-4 w-4 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{selectedCondition.description}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          
          <div className="space-y-3">
            <Select
              value={data.selectedType || ''}
              onValueChange={(value) => data.onChange?.({ selectedType: value, value: '' })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select condition type">
                  {selectedCondition && (
                    <div className="flex items-center">
                      {selectedCondition.icon}
                      {selectedCondition.label}
                    </div>
                  )}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem disabled value="conditions-header">
                  <span className="text-xs text-muted-foreground">-- Conditions --</span>
                </SelectItem>
                {data.conditionTypes
                  .filter(type => !['vsMode', 'harassMode', 'pkMode'].includes(type.id))
                  .map(type => (
                    <SelectItem key={type.id} value={type.id}>
                      <div className="flex items-center">
                        {type.icon}
                        <span className="mr-auto">{type.label}</span>
                        {type.arrowIcon}
                      </div>
                    </SelectItem>
                  ))
                }
              </SelectContent>
            </Select>

            {selectedCondition && selectedCondition.valueType !== 'boolean' && (
              selectedCondition.valueType === 'skill' ? (
                <Select
                  value={data.value}
                  onValueChange={(value) => data.onChange?.({ value })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select skill" />
                  </SelectTrigger>
                  <SelectContent>
                    {assassinClassAbilities.map(skill => (
                      <SelectItem key={skill.name} value={skill.name}>
                        <div className="flex items-center gap-2">
                          <img
                            src={`/images/skills/${skill.name.toLowerCase().replace(/\s+/g, '-')}.gif`}
                            alt={skill.name}
                            className="w-6 h-6"
                          />
                          {skill.name}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              ) : (
                <Input
                  value={data.value}
                  onChange={(e) => data.onChange?.({ value: e.target.value })}
                  type={selectedCondition.valueType === 'number' ? 'number' : 'text'}
                  placeholder={
                    selectedCondition.valueType === 'percentage' ? 'Enter percentage (0-100)'
                    : selectedCondition.valueType === 'number' ? 'Enter number'
                    : 'Enter value'
                  }
                  min={selectedCondition.valueType === 'percentage' ? 0 : undefined}
                  max={selectedCondition.valueType === 'percentage' ? 100 : undefined}
                />
              )
            )}

            {/* Game Mode Selection */}
            <div className="mt-3 bg-gaming/5 p-2 rounded-md border border-gaming/20">
              <h4 className="text-xs font-medium mb-2 text-gaming">Active in Game Modes:</h4>
              <div className="flex flex-col gap-2">
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="vsMode" 
                    checked={gameModes.vsMode}
                    onCheckedChange={(checked) => handleGameModeChange('vsMode', !!checked)}
                  />
                  <Label htmlFor="vsMode" className="text-xs flex items-center">
                    <Shield className="h-3 w-3 mr-1 text-blue-500" />
                    VS Mode
                  </Label>
                </div>
                
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="harassMode" 
                    checked={gameModes.harassMode}
                    onCheckedChange={(checked) => handleGameModeChange('harassMode', !!checked)}
                  />
                  <Label htmlFor="harassMode" className="text-xs flex items-center">
                    <AlertTriangle className="h-3 w-3 mr-1 text-yellow-500" />
                    Harass Mode
                  </Label>
                </div>
                
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="pkMode" 
                    checked={gameModes.pkMode}
                    onCheckedChange={(checked) => handleGameModeChange('pkMode', !!checked)}
                  />
                  <Label htmlFor="pkMode" className="text-xs flex items-center">
                    <Sword className="h-3 w-3 mr-1 text-red-500" />
                    PK Mode
                  </Label>
                </div>
              </div>
            </div>

            {selectedCondition && (
              <Badge 
                variant="outline" 
                className="bg-gaming/10 text-gaming border-gaming/50"
              >
                <div className="flex items-center gap-1">
                  {selectedCondition.icon}
                  {selectedCondition.label}
                  {selectedCondition.valueType !== 'boolean' && `: ${data.value || '0'}`}
                  {selectedCondition.arrowIcon}
                </div>
              </Badge>
            )}
            
            {/* Mode badges */}
            <div className="flex flex-wrap gap-1 mt-1">
              {gameModes.vsMode && (
                <Badge variant="outline" className="bg-blue-500/10 text-blue-500 border-blue-500/50 text-xs">
                  <Shield className="h-3 w-3 mr-1" />
                  VS
                </Badge>
              )}
              {gameModes.harassMode && (
                <Badge variant="outline" className="bg-yellow-500/10 text-yellow-500 border-yellow-500/50 text-xs">
                  <AlertTriangle className="h-3 w-3 mr-1" />
                  Harass
                </Badge>
              )}
              {gameModes.pkMode && (
                <Badge variant="outline" className="bg-red-500/10 text-red-500 border-red-500/50 text-xs">
                  <Sword className="h-3 w-3 mr-1" />
                  PK
                </Badge>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Handle
        type="source"
        position={Position.Right}
        className="!bg-gaming !border-gaming/50 w-3 h-3"
        isConnectable={isConnectable}
      />
    </div>
  );
});

ConditionNode.displayName = 'ConditionNode';

export default ConditionNode; 