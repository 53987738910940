import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import { Keyboard, RefreshCw, Save, RotateCcw, Play, Pause, Heart, HelpCircle, Zap, Laptop2, Key, Type, Settings2, Layers, Gamepad2 } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Slider } from '@/components/ui/slider';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { toast } from 'sonner';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { HexColorPicker } from 'react-colorful';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { getKeyboardShortcuts, saveKeyboardShortcuts, resetKeyboardShortcuts, KeyboardShortcutsData, defaultKeyboardShortcuts } from '@/lib/services/keyboard-shortcuts';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';

// Available combo types
const comboTypes = [
  { value: 'skill-rr', label: 'Skill-RR' },
  { value: 'skill-r', label: 'Skill-R' },
  { value: 'skill-wr', label: 'Skill-WR' },
  { value: 'skill-sr', label: 'Skill-SR' }
];

export default function KeyboardShortcuts() {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('general');
  const [shortcuts, setShortcuts] = useState<KeyboardShortcutsData>(defaultKeyboardShortcuts);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [listeningFor, setListeningFor] = useState<string | null>(null);
  
  // Load existing shortcuts from the database
  useEffect(() => {
    let isMounted = true;
    const loadShortcuts = async () => {
      try {
        // Pre-populate with default values immediately for faster UI rendering
        setShortcuts(defaultKeyboardShortcuts);
        
        // Only fetch from database if user is logged in
        if (user) {
          const data = await getKeyboardShortcuts();
          if (isMounted) {
            setShortcuts(data);
            // Set loading to false immediately after data is received
            setIsLoading(false);
          }
        } else {
          // If no user, we can use defaults and stop loading
          if (isMounted) {
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error loading keyboard shortcuts:', error);
        toast.error('Failed to load keyboard shortcuts. Using default values.');
        setIsLoading(false);
      }
    };
    
    loadShortcuts();
    
    // Force isLoading to false after a maximum of 1000ms in case of any issues
    const timeout = setTimeout(() => {
      if (isMounted) {
        setIsLoading(false);
      }
    }, 1000);
    
    // Return cleanup function to handle component unmounting
    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [user]);
  
  // Memoize tab change to reduce rerenders
  const handleTabChange = useCallback((value: string) => {
    setActiveTab(value);
  }, []);
  
  // Send notification
  const sendNotification = async (notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    if (!user) return;

    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: user.id,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Send to realtime channel
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Send to server
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: user.id,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };
  
  // Handle key listening for shortcuts
  const startListeningForKey = (shortcutName: string) => {
    setListeningFor(shortcutName);
  };
  
  // Global key listener
  useEffect(() => {
    if (!listeningFor) return;
    
    const handleKeyDown = (e: KeyboardEvent) => {
      e.preventDefault();
      
      // Get the key name
      let keyName: string;
      if (e.key === ' ') {
        keyName = 'Space';
      } else if (e.key === 'Escape') {
        keyName = 'Escape';
      } else if (e.key === 'CapsLock') {
        keyName = 'CapsLock';
      } else {
        keyName = e.key;
      }
      
      // Update the shortcut
      setShortcuts(prev => ({
        ...prev,
        [listeningFor]: keyName
      }));
      
      // Stop listening
      setListeningFor(null);
      
      toast.success(`${listeningFor.replace('_', ' ')} is now set to ${keyName}`);
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [listeningFor]);
  
  // Save all shortcuts to the database
  const saveAllShortcuts = async () => {
    try {
      setIsSaving(true);
      await saveKeyboardShortcuts(shortcuts);
      
      toast.success('Keyboard shortcuts saved successfully');
      
      await sendNotification({
        title: '✨ Keyboard Shortcuts Updated',
        message: 'Your keyboard shortcuts configuration has been saved successfully.',
        type: 'success'
      });
    } catch (error) {
      console.error('Error saving keyboard shortcuts:', error);
      toast.error('Failed to save keyboard shortcuts');
      
      await sendNotification({
        title: '❌ Error Updating Shortcuts',
        message: 'There was an error saving your keyboard shortcuts.',
        type: 'error'
      });
    } finally {
      setIsSaving(false);
    }
  };
  
  // Reset all shortcuts to default values
  const resetToDefaults = async () => {
    try {
      setIsLoading(true);
      const data = await resetKeyboardShortcuts();
      setShortcuts(data);
      
      toast.success('Keyboard shortcuts have been reset to default values');
      
      await sendNotification({
        title: '🔄 Shortcuts Reset',
        message: 'Your keyboard shortcuts have been reset to default values.',
        type: 'info'
      });
    } catch (error) {
      console.error('Error resetting keyboard shortcuts:', error);
      toast.error('Failed to reset keyboard shortcuts');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Update a single shortcut
  const updateShortcut = (name: keyof KeyboardShortcutsData, value: string | number) => {
    setShortcuts(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Render a key shortcut input
  const renderShortcutInput = (name: keyof KeyboardShortcutsData, label: string, icon: React.ReactNode) => {
    const isListening = listeningFor === name;
    const value = typeof shortcuts[name] === 'string' ? shortcuts[name] as string : '';
    
    return (
      <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
        <Label htmlFor={name} className="flex items-center gap-1.5 text-sm font-medium mb-3">
          {icon}
          {label}
        </Label>
        <div className="flex gap-2">
          <Button
            id={name}
            variant={isListening ? "default" : "outline"}
            className={`w-36 text-center relative ${isListening ? 'bg-gaming text-white shadow-md' : 'border-gaming/20 hover:border-gaming/40 hover:bg-gaming/5'}`}
            onClick={() => startListeningForKey(name)}
            disabled={isLoading || isSaving}
          >
            {isListening ? (
              <span className="animate-pulse">Press a key...</span>
            ) : (
              <span className="font-mono font-medium">{value}</span>
            )}
          </Button>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  onClick={() => updateShortcut(name, defaultKeyboardShortcuts[name])}
                  disabled={isLoading || isSaving}
                  className="hover:bg-gaming/5 hover:text-gaming"
                >
                  <RotateCcw className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>Reset to default</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    );
  };
  
  // Render a numeric input with slider
  const renderNumericInput = (name: keyof KeyboardShortcutsData, label: string, icon: React.ReactNode, min: number, max: number, step: number, unit: string) => {
    const value = typeof shortcuts[name] === 'number' ? shortcuts[name] as number : 0;
    
    return (
      <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
        <Label htmlFor={name} className="flex items-center gap-1.5 text-sm font-medium mb-3">
          {icon}
          {label}
        </Label>
        <div className="flex items-center gap-3">
          <Slider
            id={`slider-${name}`}
            min={min}
            max={max}
            step={step}
            value={[value]}
            onValueChange={(values) => updateShortcut(name, values[0])}
            disabled={isLoading || isSaving}
            className="w-full"
          />
          <div className="w-28 flex items-center bg-background rounded-md p-0.5 border border-input">
            <Input
              id={name}
              type="number"
              value={value}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                if (!isNaN(newValue) && newValue >= min && newValue <= max) {
                  updateShortcut(name, newValue);
                }
              }}
              min={min}
              max={max}
              step={step}
              disabled={isLoading || isSaving}
              className="w-full border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-right"
            />
            <span className="text-xs text-muted-foreground whitespace-nowrap pr-2">{unit}</span>
          </div>
        </div>
        <div className="flex justify-between mt-1.5 text-xs text-muted-foreground">
          <span>{min} {unit}</span>
          <span>{max} {unit}</span>
        </div>
      </div>
    );
  };
  
  // Render a color picker
  const renderColorPicker = (name: keyof KeyboardShortcutsData, label: string, icon: React.ReactNode) => {
    const value = typeof shortcuts[name] === 'string' ? shortcuts[name] as string : '#FFFFFF';
    
    return (
      <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
        <Label htmlFor={name} className="flex items-center gap-1.5 text-sm font-medium mb-3">
          {icon}
          {label}
        </Label>
        <div className="flex items-center gap-3">
          <div 
            className="h-12 w-12 rounded-md shadow-sm"
            style={{ backgroundColor: value }}
          />
          <div className="flex flex-col gap-1.5">
            <div className="flex gap-2">
              <Input
                id={name}
                value={value}
                onChange={(e) => updateShortcut(name, e.target.value)}
                disabled={isLoading || isSaving}
                className="w-32 uppercase"
              />
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="icon" disabled={isLoading || isSaving}>
                    <span className="sr-only">Pick a color</span>
                    <div
                      className="h-4 w-4 rounded-sm"
                      style={{ backgroundColor: value }}
                    />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-3">
                  <HexColorPicker
                    color={value}
                    onChange={(color) => updateShortcut(name, color)}
                  />
                </PopoverContent>
              </Popover>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => updateShortcut(name, defaultKeyboardShortcuts[name])}
                      disabled={isLoading || isSaving}
                      className="hover:bg-gaming/5 hover:text-gaming"
                    >
                      <RotateCcw className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <p>Reset to default</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex gap-1">
              {['#FF5555', '#9B59B6', '#3498DB', '#2ECC71', '#F1C40F', '#E67E22'].map((color) => (
                <Button
                  key={color}
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6 p-0 rounded-full"
                  style={{ backgroundColor: color }}
                  onClick={() => updateShortcut(name, color)}
                >
                  <span className="sr-only">Select {color}</span>
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="space-y-6">
      <Card className="glass-card border-gaming/20 overflow-hidden">
        <div className="bg-gaming/10 border-b border-gaming/20 px-6 py-3.5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="h-12 w-12 rounded-xl flex items-center justify-center bg-gaming/20 text-gaming">
                <Keyboard className="h-6 w-6" />
              </div>
              <div>
                <CardTitle className="text-xl font-semibold text-gaming">
                  Keyboard Shortcuts & Game Settings
                </CardTitle>
                <CardDescription className="text-sm mt-0.5">
                  Customize your keyboard shortcuts and preferences for optimal gameplay experience
                </CardDescription>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                onClick={resetToDefaults}
                disabled={isLoading || isSaving}
                className="gap-1 border-gaming/30 hover:bg-gaming/10 hover:text-gaming"
              >
                <RotateCcw className="h-4 w-4" />
                Reset to Defaults
              </Button>
              <Button
                onClick={saveAllShortcuts}
                className="bg-gaming hover:bg-gaming/90"
                disabled={isLoading || isSaving}
              >
                {isSaving ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    Save Settings
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
        <CardContent className="p-6">
          {isLoading ? (
            <div className="flex gap-6 h-[950px]">
              {/* Sidebar Skeleton */}
              <div className="w-60 shrink-0 border-r border-border/50 pr-4">
                <Skeleton className="h-5 w-40 mb-5" />
                <div className="space-y-3">
                  <Skeleton className="h-14 w-full rounded-lg" />
                  <Skeleton className="h-14 w-full rounded-lg" />
                  <Skeleton className="h-14 w-full rounded-lg" />
                  <Skeleton className="h-14 w-full rounded-lg" />
                </div>
              </div>
              
              {/* Content Skeleton */}
              <div className="flex-1">
                <div className="space-y-6">
                  <div className="flex items-center gap-3 mb-6">
                    <Skeleton className="h-10 w-10 rounded-full" />
                    <div className="space-y-2">
                      <Skeleton className="h-6 w-48" />
                      <Skeleton className="h-4 w-60" />
                    </div>
                  </div>
                  
                  <Skeleton className="h-16 w-full rounded-lg mb-8" />
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <Skeleton className="h-28 w-full rounded-xl" />
                    <Skeleton className="h-28 w-full rounded-xl" />
                    <Skeleton className="h-28 w-full rounded-xl" />
                    <Skeleton className="h-28 w-full rounded-xl" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-6 h-[950px]">
              {/* Sidebar Tabs */}
              <div className="w-60 shrink-0 border-r border-border/50 pr-4">
                <div className="text-sm font-medium text-muted-foreground mb-4">Settings Categories</div>
                <div className="flex flex-col gap-2">
                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === 'general' 
                      ? 'bg-gaming text-white shadow-md' 
                      : 'hover:bg-gaming/10 text-foreground hover:text-gaming'
                    }`}
                    onClick={() => handleTabChange('general')}
                  >
                    <div className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                      activeTab === 'general' 
                      ? 'bg-white/20' 
                      : 'bg-gaming/10 group-hover:bg-gaming/20'
                    }`}>
                      <Settings2 className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">Main Controls</div>
                      <div className={`text-xs ${activeTab === 'general' ? 'text-white/70' : 'text-muted-foreground'}`}>
                        Core keyboard shortcuts
                      </div>
                    </div>
                  </button>
                  
                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === 'colors' 
                      ? 'bg-gaming text-white shadow-md' 
                      : 'hover:bg-gaming/10 text-foreground hover:text-gaming'
                    }`}
                    onClick={() => handleTabChange('colors')}
                  >
                    <div className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                      activeTab === 'colors' 
                      ? 'bg-white/20' 
                      : 'bg-gaming/10 group-hover:bg-gaming/20'
                    }`}>
                      <Layers className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">Game Colors</div>
                      <div className={`text-xs ${activeTab === 'colors' ? 'text-white/70' : 'text-muted-foreground'}`}>
                        Detection color settings
                      </div>
                    </div>
                  </button>
                  
                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === 'timing' 
                      ? 'bg-gaming text-white shadow-md' 
                      : 'hover:bg-gaming/10 text-foreground hover:text-gaming'
                    }`}
                    onClick={() => handleTabChange('timing')}
                  >
                    <div className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                      activeTab === 'timing' 
                      ? 'bg-white/20' 
                      : 'bg-gaming/10 group-hover:bg-gaming/20'
                    }`}>
                      <Zap className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">Timings</div>
                      <div className={`text-xs ${activeTab === 'timing' ? 'text-white/70' : 'text-muted-foreground'}`}>
                        Delay and timing values
                      </div>
                    </div>
                  </button>
                  
                  <button
                    className={`group flex items-center gap-3 px-3 py-2.5 rounded-lg transition-all ${
                      activeTab === 'combat' 
                      ? 'bg-gaming text-white shadow-md' 
                      : 'hover:bg-gaming/10 text-foreground hover:text-gaming'
                    }`}
                    onClick={() => handleTabChange('combat')}
                  >
                    <div className={`flex h-8 w-8 shrink-0 items-center justify-center rounded-full ${
                      activeTab === 'combat' 
                      ? 'bg-white/20' 
                      : 'bg-gaming/10 group-hover:bg-gaming/20'
                    }`}>
                      <Gamepad2 className="h-4 w-4" />
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="font-medium">Combat</div>
                      <div className={`text-xs ${activeTab === 'combat' ? 'text-white/70' : 'text-muted-foreground'}`}>
                        Combat abilities and modes
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              
              {/* Content Area */}
              <div className="flex-1">
                <ScrollArea className="h-[950px] pr-4">
                  <div className="pb-8">
                    {activeTab === 'general' && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className={`flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming`}>
                            <Settings2 className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">Main Controls</h3>
                            <p className="text-sm text-muted-foreground">Configure your primary keyboard shortcuts</p>
                          </div>
                        </div>
                        
                        <Alert className="bg-blue-100 border-blue-300 dark:bg-blue-900/40 dark:border-blue-700">
                          <HelpCircle className="h-4 w-4 !text-blue-800 dark:!text-blue-200" />
                          <AlertDescription className="text-sm font-semibold text-blue-800 dark:text-blue-200">
                            Click on any shortcut button and press the key you want to assign. Your changes will be saved when you click the Save button.
                          </AlertDescription>
                        </Alert>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {renderShortcutInput('refetch_settings', 'Refetch Settings', <RefreshCw className="h-4 w-4 text-blue-500" />)}
                          {renderShortcutInput('toggle_minor', 'Toggle Minor', <Play className="h-4 w-4 text-green-500" />)}
                          {renderShortcutInput('toggle_combo', 'Toggle Combo', <Zap className="h-4 w-4 text-yellow-500" />)}
                          {renderShortcutInput('toggle_auto_cure', 'Toggle Auto Cure', <Heart className="h-4 w-4 text-red-500" />)}
                          {renderShortcutInput('toggle_potion_usage', 'Toggle Potion Usage', <Zap className="h-4 w-4 text-purple-500" />)}
                          {renderShortcutInput('pause_all_macro', 'Pause All Macros', <Pause className="h-4 w-4 text-orange-500" />)}
                          {renderShortcutInput('combo_mode_toggle', 'Combo Mode Toggle', <RefreshCw className="h-4 w-4 text-gaming" />)}
                        </div>
                      </div>
                    )}
                    
                    {activeTab === 'colors' && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className={`flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming`}>
                            <Layers className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">Game Colors</h3>
                            <p className="text-sm text-muted-foreground">Customize detection colors for better performance</p>
                          </div>
                        </div>
                        
                        <Alert className="bg-purple-100 border-purple-300 dark:bg-purple-900/40 dark:border-purple-700">
                          <HelpCircle className="h-4 w-4 !text-purple-800 dark:!text-purple-200" />
                          <AlertDescription className="text-sm font-semibold text-purple-800 dark:text-purple-200">
                            Use the color picker to customize the colors used for HP detection and poison detection.
                          </AlertDescription>
                        </Alert>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {renderColorPicker('hp_color', 'HP Bar Color', <Heart className="h-4 w-4 text-red-500" />)}
                          {renderColorPicker('poison_color', 'Poison Color', <Zap className="h-4 w-4 text-purple-500" />)}
                          {renderColorPicker('mana_color', 'Mana Color', <Zap className="h-4 w-4 text-blue-500" />)}
                        </div>
                      </div>
                    )}
                    
                    {activeTab === 'timing' && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className={`flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming`}>
                            <Zap className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">Timing Settings</h3>
                            <p className="text-sm text-muted-foreground">Fine-tune timing for optimal performance</p>
                          </div>
                        </div>
                        
                        <Alert className="bg-yellow-100 border-yellow-300 dark:bg-yellow-900/40 dark:border-yellow-700">
                          <HelpCircle className="h-4 w-4 !text-yellow-800 dark:!text-yellow-200" />
                          <AlertDescription className="text-sm font-semibold text-yellow-800 dark:text-yellow-200">
                            Fine-tune timing settings for optimal gameplay performance. Use the sliders to adjust values quickly.
                          </AlertDescription>
                        </Alert>
                        
                        <div className="grid grid-cols-1 gap-8">
                          {renderNumericInput('minor_min_delay', 'Minor Minimum Delay', <Zap className="h-4 w-4 text-yellow-500" />, 0.001, 1, 0.001, 'sec')}
                          {renderNumericInput('hp_potion_delay', 'HP Potion Delay', <Heart className="h-4 w-4 text-red-500" />, 0.1, 10, 0.1, 'sec')}
                          {renderNumericInput('mp_potion_delay', 'MP Potion Delay', <Zap className="h-4 w-4 text-blue-500" />, 0.1, 10, 0.1, 'sec')}
                          {renderNumericInput('skill_gap', 'Skill Gap Time', <Zap className="h-4 w-4 text-purple-500" />, 100, 2000, 10, 'ms')}
                        </div>
                      </div>
                    )}
                    
                    {activeTab === 'combat' && (
                      <div className="space-y-5">
                        <div className="flex items-center gap-3 mb-6">
                          <div className={`flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-gaming/10 text-gaming`}>
                            <Gamepad2 className="h-5 w-5" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold">Combat Settings</h3>
                            <p className="text-sm text-muted-foreground">Configure your combat abilities and modes</p>
                          </div>
                        </div>
                        
                        <Alert className="bg-red-100 border-red-300 dark:bg-red-900/40 dark:border-red-700">
                          <HelpCircle className="h-4 w-4 !text-red-800 dark:!text-red-200" />
                          <AlertDescription className="text-sm font-semibold text-red-800 dark:text-red-200">
                            Configure combat-specific settings and keyboard shortcuts to maximize your effectiveness in battle.
                          </AlertDescription>
                        </Alert>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
                            <Label htmlFor="dagger_throw_key" className="flex items-center gap-1.5 text-sm font-medium mb-3">
                              <Zap className="h-4 w-4 text-yellow-500" />
                              Dagger Throw Key
                            </Label>
                            <div className="flex gap-2">
                              <Button
                                id="dagger_throw_key"
                                variant={listeningFor === 'dagger_throw_key' ? "default" : "outline"}
                                className={`w-36 text-center relative ${listeningFor === 'dagger_throw_key' ? 'bg-gaming text-white shadow-md' : 'border-gaming/20 hover:border-gaming/40 hover:bg-gaming/5'}`}
                                onClick={() => startListeningForKey('dagger_throw_key')}
                                disabled={isLoading || isSaving}
                              >
                                {listeningFor === 'dagger_throw_key' ? (
                                  <span className="animate-pulse">Press a key...</span>
                                ) : (
                                  <span className="font-mono font-medium">{shortcuts.dagger_throw_key}</span>
                                )}
                              </Button>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button
                                      size="icon"
                                      variant="ghost"
                                      onClick={() => updateShortcut('dagger_throw_key', defaultKeyboardShortcuts.dagger_throw_key)}
                                      disabled={isLoading || isSaving}
                                      className="hover:bg-gaming/5 hover:text-gaming"
                                    >
                                      <RotateCcw className="h-4 w-4" />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent side="right">
                                    <p>Reset to default</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>

                          <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
                            <Label htmlFor="cure_key" className="flex items-center gap-1.5 text-sm font-medium mb-3">
                              <Heart className="h-4 w-4 text-green-500" />
                              Cure Key
                            </Label>
                            <div className="flex gap-2">
                              <Button
                                id="cure_key"
                                variant={listeningFor === 'cure_key' ? "default" : "outline"}
                                className={`w-36 text-center relative ${listeningFor === 'cure_key' ? 'bg-gaming text-white shadow-md' : 'border-gaming/20 hover:border-gaming/40 hover:bg-gaming/5'}`}
                                onClick={() => startListeningForKey('cure_key')}
                                disabled={isLoading || isSaving}
                              >
                                {listeningFor === 'cure_key' ? (
                                  <span className="animate-pulse">Press a key...</span>
                                ) : (
                                  <span className="font-mono font-medium">{shortcuts.cure_key}</span>
                                )}
                              </Button>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button
                                      size="icon"
                                      variant="ghost"
                                      onClick={() => updateShortcut('cure_key', defaultKeyboardShortcuts.cure_key)}
                                      disabled={isLoading || isSaving}
                                      className="hover:bg-gaming/5 hover:text-gaming"
                                    >
                                      <RotateCcw className="h-4 w-4" />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent side="right">
                                    <p>Reset to default</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>
                          
                          <div className="bg-background/40 p-4 rounded-xl border border-border/40 hover:border-gaming/30 transition-colors">
                            <Label htmlFor="combo_type" className="flex items-center gap-1.5 text-sm font-medium mb-3">
                              <Zap className="h-4 w-4 text-blue-500" />
                              Combo Type
                            </Label>
                            <div className="flex gap-2">
                              <Select
                                value={shortcuts.combo_type}
                                onValueChange={(value) => updateShortcut('combo_type', value)}
                                disabled={isLoading || isSaving}
                              >
                                <SelectTrigger className="w-36 border-gaming/20 hover:border-gaming/40 hover:bg-gaming/5">
                                  <SelectValue placeholder="Select type" />
                                </SelectTrigger>
                                <SelectContent>
                                  {comboTypes.map((type) => (
                                    <SelectItem key={type.value} value={type.value} className="cursor-pointer">
                                      <div className="flex items-center gap-2">
                                        <Badge variant="outline" className="h-5 px-1 bg-gaming/5 border-gaming/20 text-gaming">
                                          {type.value}
                                        </Badge>
                                        <span>{type.label}</span>
                                      </div>
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button
                                      size="icon"
                                      variant="ghost"
                                      onClick={() => updateShortcut('combo_type', defaultKeyboardShortcuts.combo_type)}
                                      disabled={isLoading || isSaving}
                                      className="hover:bg-gaming/5 hover:text-gaming"
                                    >
                                      <RotateCcw className="h-4 w-4" />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent side="right">
                                    <p>Reset to default</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollArea>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
} 