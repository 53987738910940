import { Toaster as Sonner } from "sonner"
import React from "react"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="dark"
      position="bottom-right"
      closeButton
      richColors
      expand={false}
      style={{
        position: "fixed",
        zIndex: 999,
        bottom: "20px",
        right: "20px",
      }}
      toastOptions={{
        duration: 4000,
        style: {
          background: "#1e293b",
          color: "#fff",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "0.5rem",
        },
        descriptionClassName: "text-slate-300",
      }}
      {...props}
    />
  )
}

export { Toaster }
