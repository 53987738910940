import { useState } from 'react';
import DashboardLayout from '@/components/layout/DashboardLayout';
import Dashboard from './Dashboard';
import Classes from './Classes';
import { Skills } from '@/components/game/Skills';
import { SkillOrder } from '@/components/game/SkillOrder';
import { SkillRules } from '@/components/game/SkillRules';
import RegionSettings from '@/components/game/RegionSettings';
import KeyboardShortcuts from '@/components/game/KeyboardShortcuts';
import PotionSettings from '@/components/game/PotionSettings';

const Index = () => {
  const [activePage, setActivePage] = useState("dashboard");


  // Render content based on active page
  const renderContent = () => {
    switch (activePage) {
      case "dashboard":
        return <Dashboard />;
      case "classes":
        return <Classes />;
      case "skills":
        return <Skills />;
      case "skill_order":
        return <SkillOrder />;
      case "skill_rules":
        return <SkillRules />;
      case "regions":
        return <RegionSettings />;
      case "keyboard_shortcuts":
        return <KeyboardShortcuts />;
      case "potion_settings":
        return <PotionSettings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <DashboardLayout
      activePage={activePage}
      setActivePage={setActivePage}
    >
      {renderContent()}
    </DashboardLayout>
  );
};


export default Index;
