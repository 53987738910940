import { supabase } from '../supabase';

export interface KeyboardShortcutsData {
  refetch_settings: string;
  toggle_minor: string;
  toggle_combo: string;
  toggle_auto_cure: string;
  hp_color: string;
  poison_color: string;
  mana_color: string;
  toggle_potion_usage: string;
  minor_min_delay: number;
  hp_potion_delay: number;
  mp_potion_delay: number;
  skill_gap: number;
  pause_all_macro: string;
  combo_mode_toggle: string;
  dagger_throw_key: string;
  cure_key: string;
  combo_type: string;
}

// Default values
export const defaultKeyboardShortcuts: KeyboardShortcutsData = {
  refetch_settings: 'F5',
  toggle_minor: 'CapsLock',
  toggle_combo: 'Space',
  toggle_auto_cure: '-',
  hp_color: '#FF5555',
  poison_color: '#9B59B6',
  mana_color: '#3498DB',
  toggle_potion_usage: '*',
  minor_min_delay: 0.01,
  hp_potion_delay: 2.6,
  mp_potion_delay: 2.6,
  skill_gap: 700,
  pause_all_macro: 'Escape',
  combo_mode_toggle: 'Insert',
  dagger_throw_key: 'g',
  cure_key: 'q',
  combo_type: 'skill-rr'
};

/**
 * Save keyboard shortcuts configuration to the database
 */
export async function saveKeyboardShortcuts(shortcutsData: KeyboardShortcutsData) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Save operation timed out')), 5000);
    });

    // First check if the user already has keyboard shortcuts configuration
    const checkExistingPromise = supabase
      .from('keyboard_shortcuts')
      .select('id')
      .eq('user_id', userData.user.id)
      .single();

    // Race between timeout and fetch
    const { data: existingConfig, error: fetchError } = await Promise.race([
      checkExistingPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (fetchError && fetchError.code !== 'PGRST116') {
      // PGRST116 is the error code for "no rows returned", which is expected if the user doesn't have a config yet
      console.error('Error checking existing keyboard shortcuts:', fetchError);
      throw new Error(`Failed to check existing keyboard shortcuts: ${fetchError.message}`);
    }

    let result;

    // If user has existing configuration, update it
    if (existingConfig) {
      console.log('Updating existing keyboard shortcuts with ID:', existingConfig.id);
      
      const updatePromise = supabase
        .from('keyboard_shortcuts')
        .update({
          ...shortcutsData,
          updated_at: new Date().toISOString(),
        })
        .eq('id', existingConfig.id)
        .select('id')
        .single();

      const { data, error } = await Promise.race([
        updatePromise,
        timeoutPromise as Promise<any>
      ]) as any;

      if (error) {
        console.error('Error updating keyboard shortcuts:', error);
        throw new Error(`Failed to update keyboard shortcuts: ${error.message}`);
      }
      
      result = data.id;
    } 
    // If no existing configuration, create a new one
    else {
      console.log('Creating new keyboard shortcuts for user');
      const insertPromise = supabase
        .from('keyboard_shortcuts')
        .insert({
          user_id: userData.user.id,
          ...shortcutsData,
        })
        .select('id')
        .single();

      const { data, error } = await Promise.race([
        insertPromise,
        timeoutPromise as Promise<any>
      ]) as any;

      if (error) {
        console.error('Error saving keyboard shortcuts:', error);
        throw new Error(`Failed to save keyboard shortcuts: ${error.message}`);
      }
      
      result = data.id;
    }

    return result;
  } catch (error) {
    console.error('Save operation failed:', error);
    throw error;
  }
}

/**
 * Get keyboard shortcuts configuration for the current user
 */
export async function getKeyboardShortcuts() {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  try {
    // Use a timeout to prevent hanging requests
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), 2000);
    });
    
    // Actual data fetching
    const fetchPromise = supabase
      .from('keyboard_shortcuts')
      .select('*')
      .eq('user_id', userData.user.id)
      .single();
    
    // Race between timeout and fetch
    const { data, error } = await Promise.race([
      fetchPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (error) {
      // If no configuration found, return the default values
      if (error.code === 'PGRST116') {
        console.log('No keyboard shortcuts configuration found, using defaults');
        return defaultKeyboardShortcuts;
      }
      
      console.error('Error fetching keyboard shortcuts:', error);
      throw new Error(`Failed to fetch keyboard shortcuts: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('Error or timeout fetching keyboard shortcuts:', error);
    // Return defaults on any error or timeout
    return defaultKeyboardShortcuts;
  }
}

/**
 * Reset keyboard shortcuts to default values
 */
export async function resetKeyboardShortcuts() {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Reset operation timed out')), 3000);
    });

    // First check if the user already has keyboard shortcuts configuration
    const checkExistingPromise = supabase
      .from('keyboard_shortcuts')
      .select('id')
      .eq('user_id', userData.user.id)
      .single();
    
    // Race between timeout and fetch
    const { data: existingConfig, error: fetchError } = await Promise.race([
      checkExistingPromise,
      timeoutPromise as Promise<any>
    ]) as any;

    // If no existing config, just return the default values
    if (fetchError && fetchError.code === 'PGRST116') {
      return defaultKeyboardShortcuts;
    }

    if (fetchError) {
      console.error('Error checking existing keyboard shortcuts:', fetchError);
      throw new Error(`Failed to check existing keyboard shortcuts: ${fetchError.message}`);
    }

    // Update with default values
    const updatePromise = supabase
      .from('keyboard_shortcuts')
      .update({
        ...defaultKeyboardShortcuts,
        updated_at: new Date().toISOString(),
      })
      .eq('id', existingConfig.id)
      .select('*')
      .single();
    
    // Race between timeout and update
    const { data, error } = await Promise.race([
      updatePromise,
      timeoutPromise as Promise<any>
    ]) as any;

    if (error) {
      console.error('Error resetting keyboard shortcuts:', error);
      throw new Error(`Failed to reset keyboard shortcuts: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('Reset operation failed:', error);
    // In case of error, still return defaults to prevent UI issues
    return defaultKeyboardShortcuts;
  }
} 