import React from 'react';
import { 
  Sword, Gauge, Zap, ListOrdered,
  ChevronLeft,
  GitBranch,
  MapPin,
  Keyboard,
  HeartCrack
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import AnimatedLogo from '@/components/ui/AnimatedLogo';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  activePage: string;
  setActivePage: (page: string) => void;
}

interface NavItem {
  id: string;
  icon: React.ElementType;
  label: string;
}

const navItems: NavItem[] = [
  { id: 'dashboard', icon: Gauge, label: 'Dashboard' },
  { id: 'classes', icon: Sword, label: 'Classes' },
  { id: 'skills', icon: Zap, label: 'Skills' },
  { id: 'skill_order', icon: ListOrdered, label: 'Skill Order' }, // Monitoring yerine bu geldi
  { id: 'skill_rules', icon: GitBranch, label: 'Skill Rules' },
  { id: 'regions', icon: MapPin, label: 'Regions' },
  { id: 'keyboard_shortcuts', icon: Keyboard, label: 'Keyboard Shortcuts' },
  { id: 'potion_settings', icon: HeartCrack, label: 'Potion Settings' },

];

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  activePage,
  setActivePage
}) => {
  const { toast } = useToast();
  
  const handleNavClick = (id: string) => {
    setActivePage(id);
    if (id !== 'dashboard' && id !== 'classes' && id !== 'performance' && id !== 'skills' && id !== 'skill_order' && id !== 'skill_rules' && id !== 'regions' && id !== 'keyboard_shortcuts' && id !== 'potion_settings') {
      toast({
        title: "Coming Soon",
        description: `The ${id} module will be available in the next update.`,
        variant: "default",
      });
    }
  };

  return (
    <div 
      className={cn(
        "fixed inset-y-0 left-0 z-50 w-72 glass-card border-r border-border/50 transition-all duration-300 ease-in-out flex flex-col",
        isOpen ? "translate-x-0" : "-translate-x-full",
        "lg:translate-x-0 lg:static lg:w-72"
      )}
    >
      <div className="flex items-center justify-between p-4 border-b border-border/50">
        <div className="flex-1 min-w-0">
          <AnimatedLogo size="md" showTagline={false} className="w-full mt-6" />
        </div>
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={toggleSidebar}
          className="lg:hidden hover:bg-primary/20 ml-2 flex-shrink-0"
        >
          <ChevronLeft className="h-5 w-5" />
        </Button>
      </div>

      <nav className="flex-1 py-6 px-3">
        <ul className="space-y-2">
          {navItems.map((item) => (
            <li key={item.id}>
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-3 text-base font-medium transition-all duration-200",
                  activePage === item.id 
                    ? "bg-primary/20 text-primary border-l-2 border-primary pl-3" 
                    : "hover:bg-primary/10 pl-4"
                )}
                onClick={() => handleNavClick(item.id)}
              >
                <item.icon className={cn(
                  "h-5 w-5",
                  activePage === item.id ? "text-primary" : "text-muted-foreground"
                )} />
                {item.label}
              </Button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="p-4 border-t border-border/50">
        <div className="glass rounded-lg p-3 text-center">
          <p className="text-xs text-muted-foreground">v1.0.0</p>
          <p className="text-xs text-muted-foreground">NexusRaid</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
