import { event } from './gtag';

interface ErrorTrackingParams {
  error: Error | string;
  component?: string;
  context?: Record<string, string>;
  severity?: 'error' | 'warning' | 'info';
}

export const trackError = ({ error, component, context, severity = 'error' }: ErrorTrackingParams) => {
  const errorMessage = error instanceof Error ? error.message : error;
  const stackTrace = error instanceof Error ? error.stack : undefined;

  event({
    action: 'error',
    params: {
      event_category: 'Error Tracking',
      event_label: errorMessage,
      error_component: component || 'unknown',
      error_severity: severity,
      error_stack: stackTrace?.substring(0, 1000), // Limit stack trace length
      ...context,
    },
  });

  // Log to console in development
  if (process.env.NODE_ENV === 'development') {
    console.error(`[${severity.toUpperCase()}] ${component ? `[${component}]` : ''} ${errorMessage}`);
    if (stackTrace) {
      console.error(stackTrace);
    }
  }
};

// Global error handler
export const setupGlobalErrorHandling = () => {
  if (typeof window !== 'undefined') {
    window.onerror = (message, source, lineno, colno, error) => {
      trackError({
        error: error || message.toString(),
        component: 'global',
        context: {
          source: source || 'unknown',
          line: lineno?.toString() || 'unknown',
          column: colno?.toString() || 'unknown',
        },
      });
    };

    // Handle unhandled promise rejections
    window.onunhandledrejection = (event) => {
      trackError({
        error: event.reason,
        component: 'promise',
        context: {
          type: 'unhandled_rejection',
        },
      });
    };
  }
}; 