import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useSubscription } from '@/contexts/SubscriptionContext';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Crown, Timer, Key, Lock, CheckCircle, X, History } from 'lucide-react';
import { format, formatDistance } from 'date-fns';

interface SubscriptionModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function SubscriptionModal({ open, onOpenChange }: SubscriptionModalProps) {
  const [keyCode, setKeyCode] = React.useState('');
  const [isActivating, setIsActivating] = React.useState(false);
  const { status, activateKey, history, loadingHistory, fetchHistory, loading } = useSubscription();

  useEffect(() => {
    if (open) {
      fetchHistory();
    }
  }, [open]);

  const handleActivateKey = async () => {
    if (!keyCode.trim()) return;
    
    setIsActivating(true);
    try {
      const success = await activateKey(keyCode.trim());
      if (success) {
        setKeyCode('');
        await fetchHistory();
      }
    } catch (error) {
      console.error('Error in handleActivateKey:', error);
    } finally {
      setIsActivating(false);
    }
  };

  if (loading || isActivating) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[500px] glass-card">
          <div className="flex justify-center items-center py-8">
            <div className="h-8 w-8 border-4 border-current border-t-transparent rounded-full animate-spin"></div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px] glass-card">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-2xl">
            <Crown className={status.isSubscribed ? "text-gaming" : "text-muted-foreground"} />
            Premium Membership
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Subscription Status */}
          <div className="flex items-center justify-between p-4 glass rounded-lg bg-primary/5">
            <div className="space-y-1">
              <h3 className="font-medium">Status</h3>
              <p className="text-sm text-muted-foreground">
                {status.isSubscribed ? 'Premium Active' : 'No Active Subscription'}
              </p>
            </div>
            {status.isSubscribed ? (
              <div className="flex items-center gap-2 text-gaming">
                <CheckCircle className="h-5 w-5" />
                <span className="text-sm font-medium">Active</span>
              </div>
            ) : (
              <div className="flex items-center gap-2 text-destructive">
                <X className="h-5 w-5" />
                <span className="text-sm font-medium">Inactive</span>
              </div>
            )}
          </div>

          {/* Subscription Details or Activation */}
          {status.isSubscribed ? (
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-sm">
                <Timer className="h-4 w-4 text-gaming" />
                <span>Expires on {format(new Date(status.expiryDate!), 'MMMM d, yyyy')}</span>
              </div>
              
              <div className="glass p-4 rounded-lg bg-gaming/5 border border-gaming/20 space-y-4">
                <h3 className="font-medium">Active Premium Features</h3>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2 text-sm">
                    <CheckCircle className="h-4 w-4 text-gaming" />
                    Macro system access
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <CheckCircle className="h-4 w-4 text-gaming" />
                    Instant update access
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <CheckCircle className="h-4 w-4 text-gaming" />
                    Remote desktop support
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <CheckCircle className="h-4 w-4 text-gaming" />
                    Early access to new features
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="glass p-4 rounded-lg bg-destructive/5 border border-destructive/20">
                <div className="flex items-center gap-2 mb-4">
                  <Lock className="h-5 w-5 text-destructive" />
                  <h3 className="font-medium">Premium Features Locked</h3>
                </div>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2 text-sm text-muted-foreground">
                    <X className="h-4 w-4" />
                    Macro system access
                  </li>
                  <li className="flex items-center gap-2 text-sm text-muted-foreground">
                    <X className="h-4 w-4" />
                    Instant update access
                  </li>
                  <li className="flex items-center gap-2 text-sm text-muted-foreground">
                    <X className="h-4 w-4" />
                    Remote desktop support
                  </li>
                  <li className="flex items-center gap-2 text-sm text-muted-foreground">
                    <X className="h-4 w-4" />
                    Early access to new features
                  </li>
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="font-medium">Activate Premium</h3>
                <div className="flex gap-2">
                  <Input
                    value={keyCode}
                    onChange={(e) => setKeyCode(e.target.value)}
                    placeholder="Enter your subscription key"
                    className="glass-card"
                  />
                  <Button
                    onClick={handleActivateKey}
                    disabled={!keyCode.trim() || isActivating}
                    className="bg-gaming hover:bg-gaming/90 whitespace-nowrap"
                  >
                    {isActivating ? (
                      <>
                        <div className="h-4 w-4 border-2 border-current border-t-transparent rounded-full animate-spin mr-2"></div>
                        Activating...
                      </>
                    ) : (
                      <>
                        <Key className="h-4 w-4 mr-2" />
                        Activate Key
                      </>
                    )}
                  </Button>
                </div>
                <p className="text-xs text-muted-foreground">
                  Don't have a key? Contact support to purchase a subscription key.
                </p>
              </div>
            </div>
          )}

          {/* Subscription History */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <History className="h-4 w-4" />
              <h3 className="font-medium">Subscription History</h3>
            </div>
            
            {loadingHistory ? (
              <div className="flex justify-center py-4">
                <div className="h-5 w-5 border-2 border-current border-t-transparent rounded-full animate-spin"></div>
              </div>
            ) : history.length > 0 ? (
              <div className="space-y-2 max-h-[200px] overflow-y-auto">
                {history.map((item) => (
                  <div 
                    key={item.subscription_id} 
                    className="glass p-3 rounded-lg text-sm space-y-1"
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-medium">
                        {item.duration_days} Days Premium
                      </span>
                      <span className={item.is_active ? "text-gaming" : "text-muted-foreground"}>
                        {item.is_active ? "Active" : "Expired"}
                      </span>
                    </div>
                    <div className="text-muted-foreground space-y-1">
                      <p>Key: {item.key_code}</p>
                      <p>Started: {format(new Date(item.start_date), 'MMM d, yyyy')}</p>
                      <p>
                        {item.is_active ? "Expires" : "Expired"}: {format(new Date(item.end_date), 'MMM d, yyyy')}
                      </p>
                      <p className="text-xs">
                        Activated {formatDistance(new Date(item.created_at), new Date(), { addSuffix: true })}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-sm text-muted-foreground text-center py-4">
                No subscription history found
              </p>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 