interface GtagEventParams {
  event_category?: string;
  event_label?: string;
  value?: number;
  [key: string]: string | number | undefined;
}

// Log the pageview with their URL
export const pageview = (url: string) => {
  window.gtag('config', process.env.NEXT_PUBLIC_GA_ID as string, {
    page_path: url,
  });
};

// Log specific events happening.
export const event = ({ action, params }: { action: string; params: GtagEventParams }) => {
  window.gtag('event', action, params);
}; 