import { supabase } from '../supabase';

/**
 * NOTE: The 'actions' column in the skill_rules table is no longer used in the application.
 * We only need to store conditions, as the connections between skills and conditions
 * can be inferred from the 'connectedToSkill' property of each condition.
 * 
 * If you're modifying the database schema, you can safely remove the 'actions' column.
 */

export interface SkillRuleData {
  name: string;
  conditions: Array<{
    id: string;
    type: string;
    value: string;
    gameModes?: {
      vsMode: boolean;
      harassMode: boolean;
      pkMode: boolean;
    };
    connectedToSkill?: string;
  }>;
}

/**
 * Save a skill rule configuration to the database
 */
export async function saveSkillRule(ruleData: SkillRuleData) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  // First check if the user already has skill rules
  const { data: existingRules, error: fetchError } = await supabase
    .from('skill_rules')
    .select('id')
    .eq('user_id', userData.user.id)
    .order('created_at', { ascending: false })
    .limit(1);

  if (fetchError) {
    console.error('Error checking existing rules:', fetchError);
    throw new Error(`Failed to check existing rules: ${fetchError.message}`);
  }

  let result;

  // If user has existing rules, update the most recent one
  if (existingRules && existingRules.length > 0) {
    const existingRuleId = existingRules[0].id;
    console.log('Updating existing rule with ID:', existingRuleId);
    
    const { data, error } = await supabase
      .from('skill_rules')
      .update({
        name: ruleData.name,
        conditions: ruleData.conditions,
        updated_at: new Date().toISOString(),
      })
      .eq('id', existingRuleId)
      .select('id')
      .single();

    if (error) {
      console.error('Error updating skill rule:', error);
      throw new Error(`Failed to update skill rule: ${error.message}`);
    }
    
    result = data.id;
  } 
  // If no existing rules, create a new one
  else {
    console.log('Creating new skill rule for user');
    const { data, error } = await supabase
      .from('skill_rules')
      .insert({
        user_id: userData.user.id,
        name: ruleData.name,
        conditions: ruleData.conditions,
      })
      .select('id')
      .single();

    if (error) {
      console.error('Error saving skill rule:', error);
      throw new Error(`Failed to save skill rule: ${error.message}`);
    }
    
    result = data.id;
  }

  return result;
}

/**
 * Get all skill rules for the current user
 */
export async function getSkillRules() {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  const { data, error } = await supabase
    .from('skill_rules')
    .select('*')
    .eq('user_id', userData.user.id)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching skill rules:', error);
    throw new Error(`Failed to fetch skill rules: ${error.message}`);
  }

  return data;
}

/**
 * Get a specific skill rule by ID
 */
export async function getSkillRuleById(id: string) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  const { data, error } = await supabase
    .from('skill_rules')
    .select('*')
    .eq('id', id)
    .eq('user_id', userData.user.id)
    .single();

  if (error) {
    console.error('Error fetching skill rule:', error);
    throw new Error(`Failed to fetch skill rule: ${error.message}`);
  }

  return data;
}

/**
 * Update an existing skill rule
 */
export async function updateSkillRule(id: string, ruleData: SkillRuleData) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  const { data, error } = await supabase
    .from('skill_rules')
    .update({
      name: ruleData.name,
      conditions: ruleData.conditions,
      updated_at: new Date().toISOString(),
    })
    .eq('id', id)
    .eq('user_id', userData.user.id)
    .select('id')
    .single();

  if (error) {
    console.error('Error updating skill rule:', error);
    throw new Error(`Failed to update skill rule: ${error.message}`);
  }

  return data.id;
}

/**
 * Delete a skill rule
 */
export async function deleteSkillRule(id: string) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  const { error } = await supabase
    .from('skill_rules')
    .delete()
    .eq('id', id)
    .eq('user_id', userData.user.id);

  if (error) {
    console.error('Error deleting skill rule:', error);
    throw new Error(`Failed to delete skill rule: ${error.message}`);
  }

  return true;
}

/**
 * Toggle a skill rule's enabled status
 */
export async function toggleSkillRuleStatus(id: string, isEnabled: boolean) {
  const { data: userData, error: userError } = await supabase.auth.getUser();
  
  if (userError || !userData.user) {
    throw new Error('User not authenticated');
  }

  const { data, error } = await supabase
    .from('skill_rules')
    .update({
      is_enabled: isEnabled,
      updated_at: new Date().toISOString(),
    })
    .eq('id', id)
    .eq('user_id', userData.user.id)
    .select('id')
    .single();

  if (error) {
    console.error('Error toggling skill rule status:', error);
    throw new Error(`Failed to toggle skill rule status: ${error.message}`);
  }

  return data.id;
} 