import { createContext, useContext, useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import { supabase } from '../lib/supabase'
import { Notification } from '../types/notification'

type AuthContextType = {
  user: User | null
  loading: boolean
  signIn: (email: string, password: string) => Promise<void>
  signUp: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

const sendNotification = async (userId: string, notification: Omit<Notification, 'id' | 'user_id' | 'is_read' | 'created_at'>) => {
  try {
    const { error } = await supabase
      .from('notifications')
      .insert({
        user_id: userId,
        ...notification
      })

    if (error) throw error
  } catch (error) {
    console.error('Error sending notification:', error)
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null)
      setLoading(false)
    })

    // Listen for changes on auth state
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null)
    })

    return () => subscription.unsubscribe()
  }, [])

  const signIn = async (email: string, password: string) => {
    const { error, data } = await supabase.auth.signInWithPassword({ email, password })
    if (error) throw error

    // Send welcome back notification
    if (data.user) {
      await sendNotification(data.user.id, {
        title: 'Welcome back! 👋',
        message: `You've successfully signed in to your account. Great to see you again!`,
        type: 'success'
      })
    }
  }

  const signUp = async (email: string, password: string) => {
    const { error } = await supabase.auth.signUp({ email, password })
    if (error) throw error
  }

  const signOut = async () => {
    // Send goodbye notification before signing out
    if (user) {
      await sendNotification(user.id, {
        title: 'See you soon! 👋',
        message: 'You have been successfully signed out. Have a great day!',
        type: 'info'
      })
    }

    const { error } = await supabase.auth.signOut()
    if (error) throw error
  }

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
} 