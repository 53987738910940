import React from "react";
import { Settings, Menu, Crown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { ProfileMenu } from "../ProfileMenu";
import { NotificationMenu } from "../NotificationMenu";
import { useSubscription } from "@/contexts/SubscriptionContext";
import { SubscriptionModal } from "@/components/subscription/SubscriptionModal";
import AnimatedLogo from "@/components/ui/AnimatedLogo";
import { cn } from "@/lib/utils";

interface DashboardHeaderProps {
  toggleSidebar: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ toggleSidebar }) => {
  const { toast } = useToast();
  const { status } = useSubscription();
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);

  return (
    <header className="w-full px-4 py-4 flex items-center justify-between glass-card rounded-lg mb-6 animate-in">
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          onClick={toggleSidebar}
          className="mr-2 lg:hidden hover:bg-primary/20"
        >
          <Menu className="h-5 w-5" />
        </Button>
        <AnimatedLogo className="lg:hidden w-full " showTagline={false} />
      </div>

      <div className="flex items-center gap-4">
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            "lg:hidden",
            status.isSubscribed
              ? "text-gaming hover:bg-gaming/20"
              : "text-muted-foreground hover:bg-primary/20"
          )}
          onClick={() => setShowSubscriptionModal(true)}
        >
          <Crown className="h-5 w-5" />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          className={cn(
            "hidden lg:flex",
            status.isSubscribed
              ? "text-gaming hover:bg-gaming/20"
              : "text-muted-foreground hover:bg-primary/20"
          )}
          onClick={() => setShowSubscriptionModal(true)}
        >
          <Crown className="h-4 w-4 mr-2" />
          {status.isSubscribed
            ? `${status.daysRemaining} days left`
            : "Upgrade Now"}
        </Button>
        <NotificationMenu />
        <Button
          variant="ghost"
          size="icon"
          className="hover:bg-primary/20"
          onClick={() =>
            toast({
              title: "Settings",
              description: "Settings panel will be available soon",
            })
          }
        >
          <Settings className="h-5 w-5" />
        </Button>
        <ProfileMenu />
      </div>

      <SubscriptionModal
        open={showSubscriptionModal}
        onOpenChange={setShowSubscriptionModal}
      />
    </header>
  );
};

export default DashboardHeader;
