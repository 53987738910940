import { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { LogOut, User, Settings, Shield, History } from 'lucide-react'
import { format } from 'date-fns'

export function ProfileMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const { user, signOut } = useAuth()
  const navigate = useNavigate()

  const handleSignOut = async () => {
    try {
      await signOut()
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const joinDate = user?.created_at ? format(new Date(user.created_at), 'MMMM d, yyyy') : 'Unknown'

  return (
    <>
      <Avatar 
        onClick={() => setIsOpen(true)} 
        className="h-9 w-9 hover-glow cursor-pointer"
      >
        <AvatarImage src="" alt={user?.email || 'User'} />
        <AvatarFallback className="bg-gaming text-gaming-foreground">
          {user?.email?.[0].toUpperCase() || 'U'}
        </AvatarFallback>
      </Avatar>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[425px] glass-card border-gaming/30">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl text-gaming">
              <User className="h-6 w-6" />
              Profile
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-6 py-4">
            {/* Profile Overview */}
            <div className="flex items-start gap-4 p-4 rounded-lg bg-gaming/5 backdrop-blur-sm border border-gaming/20">
              <Avatar className="h-16 w-16 ring-2 ring-gaming/30">
                <AvatarImage src="" alt={user?.email || 'User'} />
                <AvatarFallback className="bg-gaming text-gaming-foreground text-xl">
                  {user?.email?.[0].toUpperCase() || 'U'}
                </AvatarFallback>
              </Avatar>
              <div className="space-y-1 flex-1">
                <h3 className="font-medium text-gaming">{user?.email}</h3>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <History className="h-4 w-4" />
                  <span>Member since {joinDate}</span>
                </div>
              </div>
            </div>

            {/* Account Actions */}
            <div className="space-y-2">
              <h3 className="font-medium mb-3 text-gaming/80">Account Settings</h3>
              <div className="space-y-2">
                <Button
                  variant="ghost"
                  className="w-full justify-start hover:bg-gaming/10 hover:text-gaming transition-colors"
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  <Settings className="h-4 w-4 mr-2" />
                  Settings
                </Button>
                <Button
                  variant="ghost"
                  className="w-full justify-start hover:bg-gaming/10 hover:text-gaming transition-colors"
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  <Shield className="h-4 w-4 mr-2" />
                  Security
                </Button>
                <Button
                  variant="ghost"
                  className="w-full justify-start text-red-500 hover:bg-red-500/10 hover:text-red-500 transition-colors"
                  onClick={handleSignOut}
                >
                  <LogOut className="h-4 w-4 mr-2" />
                  Sign Out
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
} 