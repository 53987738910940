import { useState, useEffect } from 'react'
import { Bell, Trash2, Check, History } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { useAuth } from '../contexts/AuthContext'
import { supabase } from '../lib/supabase'
import { Notification } from '../types/notification'
import { format } from 'date-fns'
import { useToast } from '@/hooks/use-toast'
import { cn } from '@/lib/utils'

export function NotificationMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unreadCount, setUnreadCount] = useState(0)
  const { user } = useAuth()
  const { toast } = useToast()

  useEffect(() => {
    if (!user) return

    // Initial fetch
    fetchNotifications()

    // Real-time subscription with immediate updates
    const channel = supabase
      .channel('notifications')
      .on(
        'postgres_changes',
        {
          event: '*', // Listen to all changes (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`,
        },
        (payload) => {
          if (payload.eventType === 'INSERT') {
            // Yeni bildirim eklendiğinde
            const newNotification = payload.new as Notification
            setNotifications(prev => {
              // Eğer bu ID'ye sahip bildirim zaten varsa ekleme
              if (prev.some(n => n.id === newNotification.id)) {
                return prev;
              }
              return [newNotification, ...prev];
            });
            if (!newNotification.is_read) {
              setUnreadCount(prev => prev + 1)
            }
          } else if (payload.eventType === 'UPDATE') {
            // Bildirim güncellendiğinde
            const updatedNotification = payload.new as Notification
            setNotifications(prev => prev.map(n => 
              n.id === updatedNotification.id ? updatedNotification : n
            ))
            // Okunma durumu değiştiyse unreadCount'u güncelle
            if (payload.old.is_read !== updatedNotification.is_read) {
              setUnreadCount(prev => updatedNotification.is_read ? prev - 1 : prev + 1)
            }
          } else if (payload.eventType === 'DELETE') {
            // Bildirim silindiğinde
            const deletedNotification = payload.old as Notification
            setNotifications(prev => prev.filter(n => n.id !== deletedNotification.id))
            if (!deletedNotification.is_read) {
              setUnreadCount(prev => Math.max(0, prev - 1))
            }
          }
        }
      )
      // Manuel bildirimler için özel event dinle
      .on(
        'broadcast',
        { event: 'notification' },
        ({ payload }) => {
          const notification = payload as Notification;
          if (notification.user_id === user.id) {
            setNotifications(prev => {
              // Eğer bu ID'ye sahip bildirim zaten varsa ekleme
              if (prev.some(n => n.id === notification.id)) {
                return prev;
              }
              return [notification, ...prev];
            });
            if (!notification.is_read) {
              setUnreadCount(prev => prev + 1);
            }
          }
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(channel)
    }
  }, [user])

  const fetchNotifications = async () => {
    if (!user) return

    try {
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(10)

      if (error) throw error

      setNotifications(data)
      setUnreadCount(data.filter((n: Notification) => !n.is_read).length)
    } catch (error) {
      console.error('Error fetching notifications:', error)
      toast({
        title: 'Error',
        description: 'Failed to fetch notifications',
        variant: 'destructive',
      })
    }
  }

  const markAsRead = async (id: string) => {
    try {
      const { error } = await supabase
        .from('notifications')
        .update({ is_read: true })
        .eq('id', id)

      if (error) throw error

      setNotifications(notifications.map(n => 
        n.id === id ? { ...n, is_read: true } : n
      ))
      setUnreadCount(prev => Math.max(0, prev - 1))
    } catch (error) {
      console.error('Error marking notification as read:', error)
      toast({
        title: 'Error',
        description: 'Failed to mark notification as read',
        variant: 'destructive',
      })
    }
  }

  const markAllAsRead = async () => {
    if (!user) return

    try {
      const { error } = await supabase
        .from('notifications')
        .update({ is_read: true })
        .eq('user_id', user.id)
        .eq('is_read', false)

      if (error) throw error

      setNotifications(notifications.map(n => ({ ...n, is_read: true })))
      setUnreadCount(0)
      
      toast({
        title: 'Success',
        description: 'All notifications marked as read',
      })
    } catch (error) {
      console.error('Error marking all notifications as read:', error)
      toast({
        title: 'Error',
        description: 'Failed to mark all notifications as read',
        variant: 'destructive',
      })
    }
  }

  const deleteNotification = async (id: string) => {
    try {
      const { error } = await supabase
        .from('notifications')
        .delete()
        .eq('id', id)

      if (error) throw error

      const deletedNotification = notifications.find(n => n.id === id)
      setNotifications(notifications.filter(n => n.id !== id))
      if (deletedNotification && !deletedNotification.is_read) {
        setUnreadCount(prev => Math.max(0, prev - 1))
      }

      toast({
        description: 'Notification deleted',
      })
    } catch (error) {
      console.error('Error deleting notification:', error)
      toast({
        title: 'Error',
        description: 'Failed to delete notification',
        variant: 'destructive',
      })
    }
  }

  const deleteAllNotifications = async () => {
    if (!user || notifications.length === 0) return

    try {
      const { error } = await supabase
        .from('notifications')
        .delete()
        .eq('user_id', user.id)

      if (error) throw error

      setNotifications([])
      setUnreadCount(0)
      
      toast({
        description: 'All notifications deleted',
      })
    } catch (error) {
      console.error('Error deleting all notifications:', error)
      toast({
        title: 'Error',
        description: 'Failed to delete all notifications',
        variant: 'destructive',
      })
    }
  }

  const getNotificationIcon = (type: Notification['type']) => {
    switch (type) {
      case 'success':
        return '🎉'
      case 'warning':
        return '⚠️'
      case 'error':
        return '❌'
      default:
        return 'ℹ️'
    }
  }

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="hover:bg-primary/20 relative"
        onClick={() => setIsOpen(true)}
      >
        <Bell className="h-5 w-5" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 h-5 w-5 rounded-full bg-gaming text-[10px] font-medium flex items-center justify-center animate-pulse">
            {unreadCount}
          </span>
        )}
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[500px] glass-card">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-2xl">
              <Bell className="h-6 w-6" />
              Notifications
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-6 py-4">
            {/* Actions */}
            <div className="flex items-center justify-between p-4 glass rounded-lg bg-primary/5">
              <div className="space-y-1">
                <h3 className="font-medium">Notification Center</h3>
                <p className="text-sm text-muted-foreground">
                  {unreadCount > 0 ? `You have ${unreadCount} unread notifications` : 'No new notifications'}
                </p>
              </div>
              <div className="flex items-center gap-2">
                {unreadCount > 0 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    className="hover:bg-primary/20 flex items-center gap-1"
                    onClick={markAllAsRead}
                  >
                    <Check className="h-4 w-4" />
                    Mark all read
                  </Button>
                )}
                {notifications.length > 0 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-red-500 hover:bg-red-500/10 flex items-center gap-1"
                    onClick={deleteAllNotifications}
                  >
                    <Trash2 className="h-4 w-4" />
                    Delete all
                  </Button>
                )}
              </div>
            </div>

            {/* Notifications List */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <History className="h-4 w-4" />
                <h3 className="font-medium">Recent Notifications</h3>
              </div>
              
              <div className="space-y-2 max-h-[400px] overflow-y-auto pr-2">
                {notifications.length === 0 ? (
                  <div className="text-center py-8 glass rounded-lg">
                    <p className="text-muted-foreground">No notifications yet</p>
                  </div>
                ) : (
                  notifications.map(notification => (
                    <div
                      key={notification.id}
                      className={cn(
                        "glass p-4 rounded-lg transition-colors group",
                        !notification.is_read ? 'bg-primary/5 border-gaming/20' : ''
                      )}
                    >
                      <div className="flex items-start gap-3">
                        <span className="text-xl">{getNotificationIcon(notification.type)}</span>
                        <div 
                          className="flex-1 min-w-0 cursor-pointer space-y-1" 
                          onClick={() => markAsRead(notification.id)}
                        >
                          <p className="font-medium text-sm text-foreground">{notification.title}</p>
                          <p className="text-sm text-muted-foreground">{notification.message}</p>
                          <p className="text-xs text-muted-foreground">
                            {format(new Date(notification.created_at), 'MMM d, h:mm a')}
                          </p>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-500/10 hover:text-red-500"
                          onClick={() => deleteNotification(notification.id)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                        {!notification.is_read && (
                          <div className="h-2 w-2 rounded-full bg-gaming mt-2" />
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
} 