import React, { useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { cn } from '@/lib/utils';
import { SkillBar as SkillBarType, SkillSlot as SkillSlotType } from '@/types/skills';
import { Ability } from '@/data/abilities/types';
import { SkillDetailsModal } from './SkillDetailsModal';
import { LockIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface SkillSlotProps {
  slot: SkillSlotType;
  onSlotUpdate: (slotId: string, ability: Ability | null, sourceSlotId?: string) => Promise<void>;
  isLoading?: boolean;
  isRestricted?: boolean;
  restrictedBySkill?: string;
  slotIndex: number;
}

const SkillSlot: React.FC<SkillSlotProps> = ({ 
  slot, 
  onSlotUpdate, 
  isLoading, 
  isRestricted, 
  restrictedBySkill,
  slotIndex
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'assigned-ability',
    item: { 
      slotId: slot.id,
      ability: slot.ability 
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isLoading && !!slot.ability && !isRestricted,
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['ability', 'assigned-ability'],
    drop: async (item: { ability: Ability, slotId?: string }) => {
      if (item.slotId === slot.id) return; // Prevent dropping on self
      await onSlotUpdate(slot.id, item.ability, item.slotId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: () => !isLoading && !isRestricted,
  }));

  const handleClick = () => {
    if (slot.ability && !isLoading && !isRestricted) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={200}>
          <TooltipTrigger asChild>
            <div
              ref={(node) => drag(drop(node))}
              className={cn(
                'w-12 h-12 rounded-lg glass flex items-center justify-center relative cursor-pointer',
                isOver && 'ring-2 ring-gaming',
                !slot.ability && 'border-2 border-dashed border-border',
                isDragging && 'opacity-50',
                (isLoading || isRestricted) && 'opacity-70 cursor-not-allowed',
                isRestricted && 'bg-gray-800/70 border-red-500/30'
              )}
              onClick={handleClick}
            >
              {slot.ability ? (
                <img
                  src={`/images/skills/${slot.ability.name.toLowerCase().replace(/\s+/g, '-')}.gif`}
                  alt={slot.ability.name}
                  className={cn(
                    "w-full h-full object-cover rounded-lg",
                    isRestricted && "filter grayscale opacity-50"
                  )}
                  onError={(e) => {
                    e.currentTarget.src = '/placeholder.svg';
                  }}
                />
              ) : (
                <span className={cn(
                  "text-lg font-bold",
                  isRestricted ? "text-red-500/50" : "text-muted-foreground"
                )}>
                  {slot.key}
                </span>
              )}
              
              {isRestricted && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/40 rounded-lg">
                  <LockIcon className="w-5 h-5 text-red-400/90" />
                </div>
              )}
            </div>
          </TooltipTrigger>
          {isRestricted && restrictedBySkill && (
            <TooltipContent 
              side="top" 
              align="center"
              className="bg-black/90 border border-red-500/30 text-white px-3 py-2 rounded-md shadow-lg"
              sideOffset={5}
              avoidCollisions={true}
            >
              <div className="flex items-center gap-2">
                <LockIcon className="w-3.5 h-3.5 text-red-400" />
                <p className="text-xs font-medium">Locked by <span className="text-red-400">{restrictedBySkill}</span></p>
              </div>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <SkillDetailsModal
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        ability={slot.ability}
      />
    </>
  );
};

interface SkillBarProps {
  bar: SkillBarType;
  index: number;
  onSlotUpdate: (slotId: string, ability: Ability | null, sourceSlotId?: string) => Promise<void>;
  isLoading?: boolean;
  restrictedSlots?: {[key: string]: string}; // slot index -> skill name
  currentBarIndex: number;
}

export const SkillBar: React.FC<SkillBarProps> = ({ 
  bar, 
  index, 
  onSlotUpdate, 
  isLoading, 
  restrictedSlots = {},
  currentBarIndex
}) => {
  return (
    <div className="glass-card p-4 rounded-lg">
      <div className="text-sm font-medium mb-2 text-center">
        F{index + 1}
      </div>
      <div className="flex flex-col items-center gap-2">
        {bar.slots.map((slot, slotIndex) => {
          // Aynı slotun başka bir barda özel bir yetenek tarafından kısıtlanmış olup olmadığını kontrol et
          const isSlotRestricted = restrictedSlots[`${slotIndex}`] && 
                                 (!slot.ability || 
                                  (slot.ability && slot.ability.name !== restrictedSlots[`${slotIndex}`]));
          
          return (
            <SkillSlot
              key={slot.id}
              slot={slot}
              slotIndex={slotIndex}
              onSlotUpdate={onSlotUpdate}
              isLoading={isLoading}
              isRestricted={isSlotRestricted}
              restrictedBySkill={isSlotRestricted ? restrictedSlots[`${slotIndex}`] : undefined}
            />
          );
        })}
      </div>
    </div>
  );
}; 