import React from 'react';
import { cn } from '@/lib/utils';

interface AnimatedLogoProps {
  className?: string;
  showTagline?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const AnimatedLogo: React.FC<AnimatedLogoProps> = ({
  className,
  showTagline = true,
  size = 'md'
}) => {
  const sizeClasses = {
    sm: 'h-8',
    md: 'h-12',
    lg: 'h-16'
  };

  return (
    <div className={cn("flex flex-col items-center", className)}>
      <div 
        className={cn(
          "relative group animate-fade-in",
          "hover:scale-105 transition-transform duration-300"
        )}
      >
        {/* Glow effect container */}
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <img 
            src="/images/nexusraid-logo.png" 
            alt=""
            className={cn(
              "w-auto filter blur-[8px] opacity-50",
              sizeClasses[size]
            )}
            style={{ filter: 'blur(8px) brightness(1.5)' }}
          />
        </div>

        {/* Main logo */}
        <img 
          src="/images/nexusraid-logo.png" 
          alt="Nexus Raid Logo" 
          className={cn(
            "w-auto transition-all duration-300 relative",
            sizeClasses[size]
          )}
        />
      </div>
      
      {showTagline && (
        <p className={cn(
          "text-muted-foreground animate-fade-in delay-150 hover:text-gaming transition-colors",
          size === 'sm' ? 'text-xs' : 'text-sm',
          "mt-1 md:mt-2 whitespace-nowrap"
        )}>
          one step ahead
        </p>
      )}
    </div>
  );
};

export default AnimatedLogo; 