import React, { memo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Sword, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface SkillNodeProps {
  data: {
    skill: {
      name: string;
    };
    onAddCondition: () => void;
  };
  isConnectable: boolean;
}

export const SkillNode: React.FC<SkillNodeProps> = memo(({ data, isConnectable }) => {
  // Create a memoized handler for the button click
  const handleAddCondition = useCallback((e: React.MouseEvent) => {
    // Prevent event propagation to avoid any reactflow handling
    e.preventDefault();
    e.stopPropagation();
    
    // Log that we're calling the handler
    console.log("Add condition button clicked for skill:", data.skill.name);
    
    // Call the provided callback
    if (typeof data.onAddCondition === 'function') {
      data.onAddCondition();
    } else {
      console.error("onAddCondition is not a function", data);
    }
  }, [data]);

  return (
    <div className="group">
      <Handle
        type="target"
        position={Position.Left}
        className="!bg-gaming !border-gaming/50 w-3 h-3"
        isConnectable={isConnectable}
      />
      <Card className="w-[200px] border-gaming/30 bg-gaming/5 group-hover:border-gaming/50 transition-all duration-200">
        <div className="p-3 drag-handle cursor-move">
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 rounded-lg bg-gaming/10 border border-gaming/20 flex items-center justify-center">
              <img
                src={`/images/skills/${data.skill.name.toLowerCase().replace(/\s+/g, '-')}.gif`}
                alt={data.skill.name}
                className="w-8 h-8"
              />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-sm truncate">{data.skill.name}</h3>
              <div className="flex items-center gap-1 mt-1">
                <Badge variant="outline" className="bg-gaming/10 text-gaming border-gaming/50 text-xs py-0 h-5">
                  <Sword className="h-3 w-3 mr-1" />
                  Combat
                </Badge>
              </div>
            </div>
          </div>
          <div className="mt-2 text-center">
            <Button
              variant="secondary"
              className="h-6 w-full bg-gaming/20 hover:bg-gaming/40 text-gaming border border-gaming/30 flex items-center justify-center"
              onClick={handleAddCondition}
              type="button"
            >
              <Plus className="h-4 w-4 mr-1" />
              <span className="text-xs font-medium">Add Condition</span>
            </Button>
          </div>
        </div>
      </Card>
      <Handle
        type="source"
        position={Position.Right}
        className="!bg-gaming !border-gaming/50 w-3 h-3"
        isConnectable={isConnectable}
      />
    </div>
  );
});

SkillNode.displayName = 'SkillNode';

export default SkillNode; 