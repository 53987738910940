import { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from './AuthContext';
import { useToast } from '@/hooks/use-toast';
import { SubscriptionKey, UserSubscription, SubscriptionStatus, SubscriptionHistory } from '../types/subscription';
import { differenceInDays, parseISO } from 'date-fns';

type SubscriptionContextType = {
  subscription: UserSubscription | null;
  status: SubscriptionStatus;
  loading: boolean;
  history: SubscriptionHistory[];
  loadingHistory: boolean;
  activateKey: (keyCode: string) => Promise<boolean>;
  checkSubscription: () => Promise<void>;
  fetchHistory: () => Promise<void>;
};

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

// Notification gönderme fonksiyonu
const sendNotification = async (userId: string, notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
  try {
    // Önce yerel state'i güncelle
    const tempNotification = {
      id: crypto.randomUUID(),
      user_id: userId,
      is_read: false,
      created_at: new Date().toISOString(),
      ...notification
    };

    // Realtime kanalına manuel olarak bildirim gönder
    supabase.channel('notifications').send({
      type: 'broadcast',
      event: 'notification',
      payload: tempNotification
    });

    // Sunucuya gönder
    const { data, error } = await supabase
      .from('notifications')
      .insert({
        user_id: userId,
        ...notification
      })
      .select('*')
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error sending notification:', error);
    return null;
  }
};

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);
  const [status, setStatus] = useState<SubscriptionStatus>({
    isSubscribed: false,
    daysRemaining: null,
    expiryDate: null,
  });
  const [history, setHistory] = useState<SubscriptionHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    if (!user) {
      setSubscription(null);
      setStatus({
        isSubscribed: false,
        daysRemaining: null,
        expiryDate: null,
      });
      setHistory([]);
      setLoading(false);
      setLoadingHistory(false);
      return;
    }

    const initializeData = async () => {
      setLoading(true);
      try {
        await Promise.all([checkSubscription(), fetchHistory()]);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [user]);

  const fetchHistory = async () => {
    if (!user) return;

    try {
      setLoadingHistory(true);
      const { data, error } = await supabase
        .from('subscription_history')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('History error:', error);
        throw error;
      }

      setHistory(data || []);
    } catch (error) {
      console.error('Error fetching subscription history:', error);
      toast({
        title: 'Error',
        description: 'Failed to load subscription history',
        variant: 'destructive',
      });
    } finally {
      setLoadingHistory(false);
    }
  };

  const checkSubscription = async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('user_subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', true)
        .order('end_date', { ascending: false })
        .limit(1)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      setSubscription(data);

      if (data) {
        const now = new Date();
        const endDate = parseISO(data.end_date);
        const daysRemaining = differenceInDays(endDate, now);
        
        setStatus({
          isSubscribed: daysRemaining > 0,
          daysRemaining: Math.max(0, daysRemaining),
          expiryDate: data.end_date,
        });
      } else {
        setStatus({
          isSubscribed: false,
          daysRemaining: null,
          expiryDate: null,
        });
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      toast({
        title: 'Error',
        description: 'Failed to check subscription status',
        variant: 'destructive',
      });
    }
  };

  const activateKey = async (keyCode: string) => {
    if (!user) {
      toast({
        title: 'Error',
        description: 'You must be logged in to activate a key',
        variant: 'destructive',
      });
      return false;
    }

    try {
      setLoading(true);

      // Key'i kontrol et
      const { data: keyData, error: keyError } = await supabase
        .from('subscription_keys')
        .select('*')
        .eq('key_code', keyCode)
        .single();

      if (keyError || !keyData) {
        const notification = await sendNotification(user.id, {
          title: 'Invalid Subscription Key',
          message: 'The subscription key you entered does not exist.',
          type: 'error'
        });

        toast({
          title: 'Invalid Key',
          description: 'This key does not exist',
          variant: 'destructive',
        });
        return false;
      }

      if (keyData.is_used) {
        const notification = await sendNotification(user.id, {
          title: 'Key Already Used',
          message: 'This subscription key has already been used.',
          type: 'error'
        });

        toast({
          title: 'Invalid Key',
          description: 'This key has already been used',
          variant: 'destructive',
        });
        return false;
      }

      // Subscription tarihleri
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + keyData.duration_days);

      // Key'i güncelle ve subscription oluştur - transaction içinde
      const { data: result, error: transactionError } = await supabase.rpc('activate_subscription_key', {
        p_key_id: keyData.id,
        p_user_id: user.id,
        p_start_date: startDate.toISOString(),
        p_end_date: endDate.toISOString()
      });

      if (transactionError) {
        const notification = await sendNotification(user.id, {
          title: 'Activation Failed',
          message: 'Failed to activate your subscription. Please try again.',
          type: 'error'
        });

        toast({
          title: 'Error',
          description: 'Failed to activate subscription. Please try again.',
          variant: 'destructive',
        });
        return false;
      }

      // State'leri hemen güncelle
      const daysRemaining = differenceInDays(endDate, startDate);
      
      setSubscription({
        id: result.id,
        user_id: user.id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        is_active: true,
        key_used: keyData.id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

      setStatus({
        isSubscribed: true,
        daysRemaining: Math.max(0, daysRemaining),
        expiryDate: endDate.toISOString(),
      });

      // Başarılı aktivasyon bildirimi gönder ve hemen state'e ekle
      const notification = await sendNotification(user.id, {
        title: '🎉 Premium Activated!',
        message: `Your premium subscription has been activated for ${keyData.duration_days} days! Enjoy your premium features.`,
        type: 'success'
      });

      // Toast mesajını hemen göster
      toast({
        title: 'Success',
        description: `Premium activated for ${keyData.duration_days} days!`,
      });

      return true;
    } catch (error) {
      console.error('Error activating subscription:', error);
      const notification = await sendNotification(user.id, {
        title: 'Activation Error',
        message: 'An unexpected error occurred while activating your subscription.',
        type: 'error'
      });

      toast({
        title: 'Error',
        description: 'Failed to activate subscription. Please try again.',
        variant: 'destructive',
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <SubscriptionContext.Provider value={{ 
      subscription, 
      status, 
      loading, 
      history,
      loadingHistory,
      activateKey, 
      checkSubscription,
      fetchHistory
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
} 