// src/components/game/SkillOrder.tsx
import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { GripVertical, Sword, AlertCircle, Swords, Search, Star, Plus, ArrowRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { Badge } from '@/components/ui/badge';
import { assassinClassAbilities, assassinSearchAbilities, assassinMasterAbilities, assassinExtraAbilities } from '@/data/abilities/assassin';
import { SkillDetailsModal } from './SkillDetailsModal';
import { Ability } from '@/data/abilities/types';

interface Skill {
  slotKey: string;
  barIndex: number;
  skillName: string;
  orderIndex: number;
}

interface DraggableSkillItemProps {
  skill: Skill;
  index: number;
  moveSkill: (dragIndex: number, hoverIndex: number, updateDatabase: boolean) => void;
  combatSkillRange: { min: number; max: number };
}

const DraggableSkillItem: React.FC<DraggableSkillItemProps> = ({ skill, index, moveSkill, combatSkillRange }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Find skill type and check if it's a combat skill
  const getSkillInfo = () => {
    // Check if it's a combat skill (from assassinClassAbilities)
    const combatSkill = assassinClassAbilities.find(a => a.name === skill.skillName);
    if (combatSkill) {
      return { 
        isCombat: true,
        skillType: 'combat',
        type: combatSkill.skillType,
        ability: combatSkill,
        orderIndex: skill.orderIndex
      };
    }

    // Check search abilities
    const searchSkill = assassinSearchAbilities.find(a => a.name === skill.skillName);
    if (searchSkill) {
      return {
        isCombat: false,
        skillType: 'search',
        type: searchSkill.skillType || 'active',
        ability: searchSkill
      };
    }

    // Check master abilities
    const masterSkill = assassinMasterAbilities.find(a => a.name === skill.skillName);
    if (masterSkill) {
      return {
        isCombat: false,
        skillType: 'master',
        type: masterSkill.skillType || 'active',
        ability: masterSkill
      };
    }

    // Check extra abilities
    const extraSkill = assassinExtraAbilities.find(a => a.name === skill.skillName);
    if (extraSkill) {
      return {
        isCombat: false,
        skillType: 'extra',
        type: extraSkill.skillType || 'active',
        ability: extraSkill
      };
    }

    return { 
      isCombat: false,
      skillType: 'unknown',
      type: 'active',
      ability: null
    };
  };

  const { isCombat, skillType, type, ability, orderIndex } = getSkillInfo();

  // Calculate gradient based on relative position
  const getGradientStyle = () => {
    if (!isCombat) return {};

    // Combat kartları için renk paleti
    const colors = {
      green: { r: 34, g: 197, b: 94 },   // Yeşil
      yellow: { r: 234, g: 179, b: 8 },  // Sarı
      orange: { r: 249, g: 115, b: 22 }, // Turuncu
      red: { r: 239, g: 68, b: 68 }      // Kırmızı
    };

    // Kartın pozisyonuna göre renk seç
    const position = (orderIndex - combatSkillRange.min) / (combatSkillRange.max - combatSkillRange.min);
    
    let color;
    if (position <= 0.33) {
      // Yeşilden sarıya
      const t = position * 3;
      color = {
        r: colors.green.r + (colors.yellow.r - colors.green.r) * t,
        g: colors.green.g + (colors.yellow.g - colors.green.g) * t,
        b: colors.green.b + (colors.yellow.b - colors.green.b) * t
      };
    } else if (position <= 0.66) {
      // Sarıdan turuncuya
      const t = (position - 0.33) * 3;
      color = {
        r: colors.yellow.r + (colors.orange.r - colors.yellow.r) * t,
        g: colors.yellow.g + (colors.orange.g - colors.yellow.g) * t,
        b: colors.yellow.b + (colors.orange.b - colors.yellow.b) * t
      };
    } else {
      // Turuncudan kırmızıya
      const t = (position - 0.66) * 3;
      color = {
        r: colors.orange.r + (colors.red.r - colors.orange.r) * t,
        g: colors.orange.g + (colors.red.g - colors.orange.g) * t,
        b: colors.orange.b + (colors.red.b - colors.orange.b) * t
      };
    }

    return {
      background: `rgba(${Math.round(color.r)}, ${Math.round(color.g)}, ${Math.round(color.b)}, 0.2)`
    };
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'skill',
    item: { index },
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        return;
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'skill',
    hover: (item: { index: number }, monitor) => {
      if (!ref.current) return;
      
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveSkill(dragIndex, hoverIndex, false);
      item.index = hoverIndex;
    },
    drop: (item: { index: number }) => {
      moveSkill(item.index, index, true);
    },
  });

  drag(drop(ref));

  return (
    <>
      <div
        ref={ref}
        onClick={() => ability && setIsModalOpen(true)}
        className={cn(
          "relative p-2 md:p-2.5 rounded-lg border transition-all duration-200",
          "backdrop-blur-sm",
          isDragging
            ? "border-gaming/50 bg-gaming/10 shadow-xl scale-[1.02] opacity-90 cursor-grabbing z-50"
            : isCombat 
              ? "border-gaming/30 hover:border-gaming/50 hover:shadow-md cursor-pointer"
              : "border-border/50 bg-background/50 hover:bg-gaming/5 hover:border-gaming/30 hover:shadow-md cursor-pointer"
        )}
        style={{
          opacity: isDragging ? 0.9 : 1,
          transform: isDragging ? 'scale(1.02)' : 'scale(1)',
          transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
          willChange: 'transform, opacity, border-color, box-shadow',
          touchAction: 'none',
          ...getGradientStyle()
        }}
      >
        <div className="flex items-center gap-2 md:gap-3">
          <div className="flex items-center gap-1 md:gap-1.5 text-muted-foreground hover:text-gaming transition-colors cursor-grab">
            <GripVertical className="h-4 md:h-5 w-4 md:w-5" />
          </div>

          <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-lg bg-gaming/10 border border-gaming/20">
            <img 
              src={`/images/skills/${skill.skillName.toLowerCase().replace(/\s+/g, '-')}.gif`}
              alt={skill.skillName}
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>
          
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-1.5 md:gap-2">
              <span className="font-mono text-xs md:text-sm text-gaming font-semibold">#{index + 1}</span>
              <h3 className="font-medium text-sm md:text-base text-foreground truncate">
                {skill.skillName}
              </h3>
              {isCombat && <Sword className="h-3 w-3 md:h-4 md:w-4 text-gaming flex-shrink-0" />}
            </div>
            
            <div className="flex items-center gap-1 md:gap-1.5 mt-1 md:mt-1.5">
              {isCombat ? (
                <Badge variant="outline" className="bg-gaming/10 text-gaming border-gaming/50 text-xs py-0 h-5">
                  <Swords className="h-3 w-3 mr-1" />
                  Combat
                </Badge>
              ) : skillType === 'search' ? (
                <Badge variant="outline" className="bg-blue-500/10 text-blue-500 border-blue-500/50 text-xs py-0 h-5">
                  <Search className="h-3 w-3 mr-1" />
                  Search
                </Badge>
              ) : skillType === 'master' ? (
                <Badge variant="outline" className="bg-yellow-500/10 text-yellow-500 border-yellow-500/50 text-xs py-0 h-5">
                  <Star className="h-3 w-3 mr-1" />
                  Master
                </Badge>
              ) : skillType === 'extra' ? (
                <Badge variant="outline" className="bg-purple-500/10 text-purple-500 border-purple-500/50 text-xs py-0 h-5">
                  <Plus className="h-3 w-3 mr-1" />
                  Extra
                </Badge>
              ) : null}
              
              {type === 'passive' && (
                <Badge variant="outline" className="bg-muted/50 text-muted-foreground border-muted text-xs py-0 h-5">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  Passive
                </Badge>
              )}
              
              <div className="text-[10px] md:text-xs text-muted-foreground">
                {skill.slotKey} • F{skill.barIndex}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SkillDetailsModal
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        ability={ability as Ability}
      />
    </>
  );
};

export const SkillOrder = () => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [combatSkillRange, setCombatSkillRange] = useState<{min: number, max: number}>({ min: 0, max: 0 });
  const { user } = useAuth();

  // Calculate combat skill range
  useEffect(() => {
    const combatSkills = skills.filter(skill => 
      assassinClassAbilities.some(a => a.name === skill.skillName)
    );
    
    if (combatSkills.length > 0) {
      const orderIndices = combatSkills.map(s => s.orderIndex);
      setCombatSkillRange({
        min: Math.min(...orderIndices),
        max: Math.max(...orderIndices)
      });
    }
  }, [skills]);

  // Add notification sending helper function
  const sendNotification = async (notification: { title: string; message: string; type: 'info' | 'success' | 'warning' | 'error' }) => {
    if (!user) return;

    try {
      const tempNotification = {
        id: crypto.randomUUID(),
        user_id: user.id,
        is_read: false,
        created_at: new Date().toISOString(),
        ...notification
      };

      // Send to realtime channel
      supabase.channel('notifications').send({
        type: 'broadcast',
        event: 'notification',
        payload: tempNotification
      });

      // Send to server
      const { error } = await supabase
        .from('notifications')
        .insert({
          user_id: user.id,
          ...notification
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Skill verilerini yükle
  const loadSkills = async () => {
    try {
      console.log('=== LOAD SKILLS DEBUG ===');
      
      const { data, error } = await supabase
        .from('skill_metadata')
        .select('id, metadata')
        .single();

      console.log('Load response:', { data, error });

      if (error) {
        console.error('Load error:', error);
        throw error;
      }

      if (data?.metadata) {
        console.log('Raw metadata:', data.metadata);
        
        const skillsWithOrder = (data.metadata as Skill[]).map((skill, index) => {
          const skillWithOrder = {
            ...skill,
            orderIndex: skill.orderIndex || index + 1
          };
          console.log(`Processing skill ${skill.skillName}:`, skillWithOrder);
          return skillWithOrder;
        });

        console.log('Skills with order:', skillsWithOrder);

        const sortedSkills = skillsWithOrder.sort((a, b) => a.orderIndex - b.orderIndex);
        console.log('Sorted skills:', sortedSkills);

        setSkills(sortedSkills);

        if (skillsWithOrder.some(skill => !skill.orderIndex)) {
          console.log('Some skills missing orderIndex, updating...');
          await updateSkillOrder(sortedSkills);
        }
      } else {
        console.log('No metadata found in response');
      }
    } catch (error) {
      console.error('=== LOAD ERROR DETAILS ===');
      console.error('Error loading skills:', error);
      console.error('Error type:', typeof error);
      console.error('Error stack:', error instanceof Error ? error.stack : 'No stack trace');
      console.error('Full error object:', JSON.stringify(error, null, 2));
      
      toast.error("Failed to load skills");
    } finally {
      setIsLoading(false);
    }
  };

  // Sıralamayı güncelle
  const updateSkillOrder = async (newSkills: Skill[]) => {
    try {
      console.log('=== UPDATE SKILL ORDER DEBUG ===');
      console.log('New skills to update:', newSkills);

      // Önce mevcut veriyi ve ID'yi alalım
      const { data: currentData, error: fetchError } = await supabase
        .from('skill_metadata')
        .select('id, metadata')
        .single();

      console.log('Current data from DB:', currentData);
      
      if (fetchError) {
        console.error('Fetch error:', fetchError);
        throw fetchError;
      }

      if (!currentData?.id) {
        throw new Error('Could not find skill metadata record ID');
      }

      // Yeni sıralamaya göre orderIndex'leri güncelle
      const updatedSkills = newSkills.map((skill, index) => {
        const updatedSkill = {
          slotKey: skill.slotKey,
          barIndex: skill.barIndex,
          skillName: skill.skillName,
          orderIndex: index + 1
        };
        console.log(`Updating skill ${skill.skillName} with order ${index + 1}:`, updatedSkill);
        return updatedSkill;
      });

      console.log('Final updated skills array:', updatedSkills);

      // Güncelleme işlemi - UUID ile
      const { data: updateData, error: updateError } = await supabase
        .from('skill_metadata')
        .update({
          metadata: updatedSkills
        })
        .eq('id', currentData.id)
        .select();

      console.log('Update response:', { data: updateData, error: updateError });

      if (updateError) {
        console.error('Update error details:', {
          message: updateError.message,
          details: updateError.details,
          hint: updateError.hint
        });
        throw updateError;
      }

      setSkills(updatedSkills);
      console.log('Skills state updated successfully');
      
      // Send notification about skill order update
      await sendNotification({
        title: '🔄 Skill Order Updated',
        message: 'Your skill execution order has been updated successfully',
        type: 'success'
      });
      
      toast.success("Skill order updated successfully");
    } catch (error) {
      console.error('=== ERROR DETAILS ===');
      console.error('Error updating skill order:', error);
      console.error('Error type:', typeof error);
      console.error('Error stack:', error instanceof Error ? error.stack : 'No stack trace');
      console.error('Full error object:', JSON.stringify(error, null, 2));
      
      // Send error notification
      await sendNotification({
        title: '❌ Skill Order Update Failed',
        message: 'There was an error updating your skill execution order',
        type: 'error'
      });

      toast.error(`Failed to update skill order: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  useEffect(() => {
    loadSkills();
  }, []);

  const moveSkill = (dragIndex: number, hoverIndex: number, updateDatabase: boolean = false) => {
    setSkills(prevSkills => {
      const newSkills = [...prevSkills];
      const draggedSkill = newSkills[dragIndex];
      newSkills.splice(dragIndex, 1);
      newSkills.splice(hoverIndex, 0, draggedSkill);
      
      // Sadece bırakıldığında DB'yi güncelle
      if (updateDatabase) {
        updateSkillOrder(newSkills);
      }
      
      return newSkills;
    });
  };

  if (isLoading) {
    return (
      <Card className="glass-card">
        <CardContent className="pt-6">
          <div className="flex items-center justify-center h-[200px]">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gaming"></div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="glass-card">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Sword className="h-5 w-5 text-gaming" />
            Combat Skill Priority
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex items-start gap-2 text-sm">
              <AlertCircle className="h-5 w-5 text-gaming flex-shrink-0 mt-0.5" />
              <div className="space-y-2">
                <p className="text-muted-foreground">
                  Active skills will be executed in the sequence you define here. Drag and drop skills to change their execution order.
                </p>
                <ul className="list-disc list-inside text-muted-foreground space-y-1">
                  <li><span className="text-gaming">Combat Flow:</span> Skills are executed from top to bottom</li>
                  <li><span className="text-gaming">Optimization:</span> Arrange skills based on your combat strategy</li>
                </ul>
              </div>
            </div>
          
            <DndProvider backend={HTML5Backend}>
              <div className="h-[600px] overflow-y-auto pr-4 md:pr-12 pl-2 pt-2 scrollbar-thin scrollbar-thumb-gaming scrollbar-track-gaming/10">
                <div className="relative space-y-4 md:space-y-6">
                  {Array.from({ length: Math.ceil(skills.length / getSkillsPerRow()) }).map((_, rowIndex) => {
                    const rowSkills = skills.slice(rowIndex * getSkillsPerRow(), (rowIndex + 1) * getSkillsPerRow());
                    const startIndex = rowIndex * getSkillsPerRow();
                    
                    return (
                      <div key={rowIndex} className="flex flex-wrap md:flex-nowrap items-center gap-2 md:gap-3">
                        {rowSkills.map((skill, index) => (
                          <React.Fragment key={skill.skillName}>
                            <div className="w-full sm:w-[280px] md:w-[310px]">
                              <DraggableSkillItem
                                skill={skill}
                                index={startIndex + index}
                                moveSkill={moveSkill}
                                combatSkillRange={combatSkillRange}
                              />
                            </div>
                            {index < rowSkills.length - 1 && (
                              <ArrowRight className="hidden md:block h-6 w-6 text-gaming/80 flex-shrink-0" />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    );
                  })}

                  {/* Sağ taraftaki dikey oklar - Sadece tablet ve üstünde göster */}
                  <div className="hidden md:flex absolute right-0 top-0 h-full flex-col items-center justify-center gap-2">
                    <div className="flex flex-col gap-2">
                      {Array.from({ length: Math.ceil(skills.length / getSkillsPerRow()) - 1 }).map((_, index) => (
                        <ArrowRight key={index} className="h-6 w-6 text-gaming/80 rotate-90" />
                      ))}
                    </div>
                  </div>

                  {/* Mobil için yatay oklar */}
                  <div className="flex md:hidden justify-center mt-2">
                    <ArrowRight className="h-6 w-6 text-gaming/80 rotate-90" />
                  </div>
                </div>
              </div>
            </DndProvider>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

// Ekran boyutuna göre satır başına düşen kart sayısını belirle
const getSkillsPerRow = () => {
  // Varsayılan olarak mobilde 1, tablet ve üstünde 6 kart
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 768) return 1; // Mobil
    if (window.innerWidth < 1024) return 3; // Tablet
    if (window.innerWidth < 1440) return 4; // Laptop
    return 6; // 1440p ve üstü
  }
  return 6;
};

export default SkillOrder;